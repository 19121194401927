import { Pagination } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../helpers/useAuthHeader";

export default function WizardLocationPagination(props) {
	let authHeader = useAuthHeader();

	const handlePaginationChange = (e, page) => {
		e.preventDefault();
		let queryObj = { page: page };
		if (props.currentSearchInput) {
			queryObj["name"] = props.currentSearchInput;
		}
		axios.get(apiRoutes.getRuleLocationsForWizard, {
			headers: authHeader,
			params: queryObj, paramsSerializer: { indexes: null }
		}).then(res => {
			props.currentPageSetter(parseInt(page));
			props.totalPagesSetter(res.data.pager.totalPages);
			props.ruleLocationsSetter(res.data.pageOfItems);
			props.ruleLocationsLoadedSetter(true);
			if (props.isTriggerLocations) {
				props.initTriggerCount(res.data.pageOfItems);
			}
		}).catch(err => {
			console.error(err);
		});
	};

	return (
		<div style={{ alignItems: "center", display: "flex", justifyContent: "center", position: "absolute", bottom: 85, flexDirection: "row", width: "100%" }}>
			{
				props.totalPages && props.totalPages > 1 ?
					<Pagination
						count={props.totalPages}
						onChange={handlePaginationChange}
						page={parseInt(props.currentPage)}
					/>
					:
					null
			}
		</div>
	);
}