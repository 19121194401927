import { useTreeItem } from "@mui/x-tree-view";
import clsx from "clsx";

import { CoAppNavItem } from "./styled/coapp-nav-tree.styled";

export default function CustomContent(props, ref) {

	const {
		label,
		nodeId,
		classes,
		className,
		onClick
	} = props;

	const {
		disabled,
		expanded,
		selected,
		handleSelection,
		preventSelection
	} = useTreeItem(nodeId);

	const handleItemClick = (event) => {
		onClick(event, handleSelection);
		handleSelection(event);
	};

	return (
		<div
			className={clsx(className, classes.root, {
				[classes.expanded]: expanded,
				[classes.selected]: selected,
				[classes.disabled]: disabled,
			})}
			onMouseDown={preventSelection}
			onClick={handleItemClick}
			ref={ref}
		>
			<CoAppNavItem>{label}</CoAppNavItem>
		</div>
	);
}