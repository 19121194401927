import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectNavState, setNavState } from "../../../../redux/navigationSlice";
import { selectOrganizationIdentityProvider, selectUser } from "../../../../redux/userSlice";
import CoAppDataGridDateTimeCell from "../../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridNameCell from "../../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/coapp-standard-datagrid";
import CoAppAddItemsToListModal from "../../../global/components/modals/coapp-add-items-to-list-modal";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../global/components/modals/coapp-edit-name-modal";
import { CoAppDataGridActionsContainer } from "../../../global/styled/global.styled";

export default function GroupSSO() {
	const authHeader = useAuthHeader();
	const currentUser = useSelector(selectUser);
	const identityProviderName = useSelector(selectOrganizationIdentityProvider);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const navState = useSelector(selectNavState);
	const { groupId } = useParams();
	const { state } = useLocation();

	const [addIdPGroupsIsToggled, setAddIdPGroupsIsToggled] = useState(false);
	const [addIdpGroupsSearchInput, setAddIdpGroupsSearchInput] = useState("");
	const [idpGroups, setIdpGroups] = useState([]);
	const [availableIdpGroups, setAvailableIdpGroups] = useState([]);
	const [groupIdpGroups, setGroupIdpGroups] = useState([]);
	const [selectedIdPGroups, setSelectedIdpGroups] = useState([]);
	const [editGroupIsToggled, setEditGroupIsToggled] = useState(false);
	const [deleteGroupIsToggled, setDeleteGroupIsToggled] = useState(false);
	const [groupNameInput, setGroupNameInput] = useState("");
	const [groupNameError, setGroupNameError] = useState("");

	const columns = [
		{
			field: "name",
			headerName: `${identityProviderName} Group`,
			disableExport: true,
			editable: true,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridNameCell item={params.row} />
			),
		},
		{
			field: "createdAt",
			headerName: "Date Mapped",
			disableExport: true,
			editable: true,
			flex: 1,
			valueGetter: (params) => new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.value} />
			),
		},
		{
			field: "actions",
			disableExport: true,
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<CoAppDataGridActionsContainer>
					<Delete
						onClick={() => {
							removeIdPGroupFromGroup(params.row.id);
						}}
					/>
				</CoAppDataGridActionsContainer>
			),
		},
	];

	const addIdPGroupsToGroup = () => {
		axios.put(apiRoutes.updateGroupIdpGroups(groupId), { idpgroups: selectedIdPGroups }, { headers: authHeader })
			.then(() => {
				init();
				toggleAddNewDialog();
				dispatch(setMessage(messages.IDP_GROUPS_ADDITION_SUCCESS_MSG(identityProviderName)));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.IDP_GROUPS_ADDITION_ERROR_MSG(identityProviderName)));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));

			});
	};

	const addIdPGroupsToSelectedIdpGroups = (value) => {
		//value is an array of idpGroup names, we need to convert them to idpGroup objects to display the group name properly
		value = value.map((idpGroup) => {
			let tempArr = [...groupIdpGroups, ...availableIdpGroups];
			let foundIdpGroup = tempArr.find((row) => row.name === idpGroup);
			return foundIdpGroup;
		});
		setSelectedIdpGroups(value);
	};

	const init = () => {
		axios.get(apiRoutes.getGroupIdpGroups(groupId), { headers: authHeader })
			.then((response) => {
				setGroupIdpGroups(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const initAvailableIdpGroups = () => {
		let available = [];
		if (idpGroups && idpGroups.length > 0) {
			idpGroups.forEach((idpGroup) => {
				if (!groupIdpGroups.some((groupIdpGroup) => groupIdpGroup.name === idpGroup.externalGroupName)) {
					available.push({ externalGroupId: idpGroup.externalGroupId, name: idpGroup.externalGroupName });
				}
			});
		}
		setAvailableIdpGroups(available);
	};

	const initIdpGroups = () => {
		axios.get(apiRoutes.getExternalGroups(currentUser.organizationId), { headers: authHeader })
			.then((response) => {
				setIdpGroups(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const removeIdPGroupFromGroup = (idpGroupId) => {
		axios.delete(apiRoutes.removeIdpGroupFromGroup(groupId, idpGroupId), { headers: authHeader })
			.then(() => {
				init();
				dispatch(setMessage(messages.GROUP_REMOVAL_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.GROUP_REMOVAL_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const toggleAddNewDialog = () => {
		setAddIdPGroupsIsToggled(!addIdPGroupsIsToggled);

		if (!addIdPGroupsIsToggled) {
			initAvailableIdpGroups();
			setAddIdPGroupsIsToggled(true);
		} else {
			setAddIdPGroupsIsToggled(false);
			setAvailableIdpGroups([]);
			setSelectedIdpGroups([]);
		}
	};

	const handleToggleGroupEditDialog = () => {
		setEditGroupIsToggled(!editGroupIsToggled);
		setGroupNameError("");
		setGroupNameInput("");
	};

	const handleGroupNameChange = (e) => {
		setGroupNameInput(e.target.value);
		setGroupNameError("");
	};

	const handleGroupNameChangeSubmit = () => {
		if (!groupNameInput) return;

		if (groupNameInput === state.name) {
			handleToggleGroupEditDialog();
			return;
		}

		axios.put(apiRoutes.updateGroup(groupId), { name: groupNameInput }, { headers: authHeader })
			.then(() => {
				setEditGroupIsToggled(false);
				updateNavigation();
				dispatch(setMessage(messages.GROUP_NAME_UPDATED_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setGroupNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Group"));
					return;
				}
				handleToggleGroupEditDialog();
				dispatch(setMessage(messages.GROUP_NAME_UPDATE_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const updateNavigation = (isDeleteEvent = false) => {
		const oldNavState = [...navState];
		if (!isDeleteEvent) {
			let copyGroupChildren = [...oldNavState[7].children].slice(1); //update nav state to reflect group name update
			copyGroupChildren.unshift({
				id: Math.random(),
				name: groupNameInput,
				children: [],
				isGroupingLabel: true
			});

			oldNavState[7] = {
				...oldNavState[7],
				children: copyGroupChildren,
				isChangeEvent: true,
				isStillSelected: true,
				selectedResource: "Add/Remove IdP Groups",
				isNameChangeEvent: true
			};
		} else {
			oldNavState[7] = {
				...oldNavState[7],
				children: [],
				isStillSelected: true,
				selectedResource: ""
			};
		}
		dispatch(setNavState(oldNavState));
	};

	const handleToggleDeleteGroupDialog = () => {
		setDeleteGroupIsToggled(!deleteGroupIsToggled);
	};

	const confirmGroupDeletion = () => {
		axios.delete(apiRoutes.deleteGroup + "/" + groupId, {
			headers: authHeader
		}).then((res) => {
			if (res.status === 200) {
				dispatch(setMessage(messages.GROUP_DELETED_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				updateNavigation(true);
				navigate(pages.groupManagement);
			} else {
				console.log(res);
				dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			}
			handleToggleDeleteGroupDialog();
		}).catch((err) => {
			console.log(err);
			dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
			handleToggleDeleteGroupDialog();
		});
	};

	useEffect(() => {
		init();
		initIdpGroups();
	}, [state.name]);

	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} ${identityProviderName} Group Management`}
				subResourceType={`${identityProviderName} Group`}
				resourceType="Group"
				addOnClickHandler={toggleAddNewDialog}
				addIsPresent={true}
				editIsPresent={true}
				editOnClickHandler={handleToggleGroupEditDialog}
				deleteIsPresent={true}
				deleteOnClickHandler={handleToggleDeleteGroupDialog}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={groupIdpGroups}
				allowSelection={false}
				pinnedColumns={["actions", "__check__"]}
				targetResource={`${identityProviderName} groups`}
				parentResource="group"
				parentReourceName={state.name}
				bulkDestructiveText="Remove"
			/>
			<CoAppAddItemsToListModal
				addButtonTitle="Save"
				inputLabel={`${identityProviderName} Group(s)`}
				addItemsIsToggled={addIdPGroupsIsToggled}
				addItemsToCollection={addIdPGroupsToGroup}
				addItemsToNewArray={addIdPGroupsToSelectedIdpGroups}
				dialogSearchPlaceholder={`Search ${identityProviderName} Groups`}
				dialogTitle={`Add ${identityProviderName} Group(s) to ${state.name} group`}
				items={availableIdpGroups}
				itemsToAdd={selectedIdPGroups}
				searchInput={addIdpGroupsSearchInput}
				setSearchInput={setAddIdpGroupsSearchInput}
				toggleDialog={toggleAddNewDialog}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteGroupIsToggled}
				dialogTitle={`Delete ${state.name} group?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "group")}
				confirmClickHandler={confirmGroupDeletion}
				cancelClickHandler={handleToggleDeleteGroupDialog}
				actionText="Delete"
			/>
			<CoAppEditNameModal
				dialogOpen={editGroupIsToggled}
				dialogTitle="Edit group name"
				changeHandler={handleGroupNameChange}
				placeholderText={state.name}
				confirmClickHandler={handleGroupNameChangeSubmit}
				cancelClickHandler={handleToggleGroupEditDialog}
				actionText="Save"
				editNameError={groupNameError}
				setEditNameError={setGroupNameError}
			/>
		</>
	);
}