import useAuthorization from "../../helpers/useAuthorization";

export default function PrivateSettingsItem({ permissions, child, isInclusive = true }) {
	return (
		<div style={{ marginTop: "-6px" }}>
			{useAuthorization(permissions, isInclusive) ? (
				<div>
					{child}
				</div>
			) : null}
		</div>
	);
}