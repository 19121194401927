import { Card, styled, Typography } from "@mui/material";

export const RequestCard = styled(Card)(({ theme }) => ({
	...theme.typography.body2,
	textAlign: "center",
	color: theme.palette.text.primary,
	border: 0,
	fontSize: "1.5rem",
	width: "500px",
	margin: "auto",
	marginTop: "50px",
}));

export const RequestMetadataHeader = styled(Typography)(({ theme }) => ({
	...theme.typography.body2,
	textAlign: "center",
	color: theme.palette.text.primary,
	border: 0,
	fontSize: "1.1rem",
	display: "inline",
	fontWeight: "600"
}));

export const RequestMetadata = styled(Typography)(({ theme }) => ({
	...theme.typography.body2,
	textAlign: "center",
	color: theme.palette.text.primary,
	border: 0,
	fontSize: "1rem",
	marginLeft: "10px",
}));