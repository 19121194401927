import { useSelector } from "react-redux";
import Label from "@mui/icons-material/Label";
import { Typography } from "@mui/material";
import { Box, Grid } from "@mui/material";

import { selectDescription, selectName, selectRuleLocation, selectTags } from "../../redux/ruleWizardSlice";

import {
	WizardHeaderTag
} from "./styled/rule-wizard.styled";

export default function RuleWizardHeader() {
	const name = useSelector(selectName);
	const description = useSelector(selectDescription);
	const tags = useSelector(selectTags);
	const ruleLocation = useSelector(selectRuleLocation);

	return (
		<Grid container spacing={1}>
			<Grid item>
				<Typography variant="body1" sx={{ fontWeight: "600" }}>{name}</Typography>
				<Typography variant="body1">{description}</Typography>
			</Grid>
			<Grid item xxl={5} lg={3} sm={5}>
				{tags.map((tag, index) => <WizardHeaderTag key={index} ><Label fontSize="small" sx={{ paddingRight: 1 }} /> {tag.toUpperCase()}</WizardHeaderTag>)}
			</Grid>
			{
				ruleLocation !== "" && ruleLocation !== null
					?
					<Grid item>
						<Box sx={{ alignItems: "center", display: "flex", flexDirection: "row", pt: 1 }}>
							<Typography sx={{ fontWeight: "600", padding: "0 5px 0 0" }} variant="body1">Location:</Typography>
							<Typography sx={{ marginRight: "25px" }} variant="body1"> {ruleLocation.name}</Typography>
							<Typography sx={{ fontWeight: "600", padding: "0 5px 0 0" }} variant="body1">Location Description:</Typography>
							<Typography variant="body1">{ruleLocation.description}</Typography>
						</Box>
					</Grid>
					:
					null
			}
		</Grid>
	);
}