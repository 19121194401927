import { getGridStringOperators } from "@mui/x-data-grid-premium";

import prettifyDate from "./prettifyDate";

/**
 * Start quick filtering functions for the CoApp standard datagrid.
 */
export const handleArrayFiltering = (value) => {
	return (cellValue) => {
		let shouldInclude;
		if (cellValue.value.length === 0) {
			shouldInclude = false;
		}
		const simpleArray = cellValue.value.map((item) => {
			if ("firstname" in item && "lastname" in item) {
				return item.firstname && item.lastname ? item.firstname + " " + item.lastname : item.email;
			} else {
				return item.name;
			}
		});

		for (let item of simpleArray) {
			if (item.toLowerCase().includes(value.toLowerCase())) {
				shouldInclude = true;
			}
		}
		return shouldInclude;
	};
};

export const handleDateFiltering = (value) => {
	return (cellValue) => {
		let shouldInclude;
		if (cellValue.value === null) {
			shouldInclude = false;
		}
		let cellDate = prettifyDate(cellValue.value);
		let filterDate = prettifyDate(value);

		if (cellValue.field === "lastExtensionHeartbeat" && cellValue.row.workstation) {
			cellDate = prettifyDate(cellValue.row.workstation.lastPhoneHome);
		}
		if (cellDate !== "Invalid Date" && filterDate !== "Invalid Date") {
			if (cellDate === filterDate || cellDate.toLowerCase().includes(value.toLowerCase())) {
				shouldInclude = true;
			}
		} else {
			if (cellDate.toLowerCase().includes(value.toLowerCase())) {
				shouldInclude = true;
			}
		}
		return shouldInclude;
	};
};

export const handleUserFiltering = (value) => {
	return (cellValue) => {
		let shouldInclude;
		if (cellValue.value === null) {
			shouldInclude = false;
		}
		const firstName = cellValue.row.firstname.toLowerCase();
		const lastName = cellValue.row.lastname.toLowerCase();
		const email = cellValue.row.email.toLowerCase();
		const filterValue = value.toLowerCase();
		shouldInclude = firstName.includes(filterValue) || lastName.includes(filterValue) || email.includes(filterValue);
		return shouldInclude;
	};
};

/**
 * End quick filtering functions for the CoApp standard datagrid.
 */

/**
 * Start custom filtering operators for the CoApp standard datagrid.
 */
export const statusOperators = [
	{
		label: "is active",
		value: "is active",
		getApplyFilterFn: () => {
			return (value) => {
				return value.row.active;
			};
		}
	},
	{
		label: "is inactive",
		value: "is inactive",
		getApplyFilterFn: () => {
			return (value) => {
				return !value.row.active;
			};
		}
	}
];

export const arrayOperators = [
	{
		label: "is empty",
		value: "is empty",
		getApplyFilterFn: () => {
			return (value) => {
				return value.value.length === 0;
			};
		}
	},
	{
		label: "is not empty",
		value: "is not empty",
		getApplyFilterFn: () => {
			return (value) => {
				return value.value.length > 0;
			};
		}
	}
];

/**
 * We override the default string operators here to add custom filtering functions
 * for our user columns that contain both the users name and email.
 */
export const identifierColumnOperators = getGridStringOperators()
	.map((operator) => {
		if (operator.value === "contains") {
			return {
				...operator,
				getApplyFilterFn: (filterItem) => {
					if (!filterItem.value) return null;
					return (value) => {
						const filterItemLowered = filterItem.value.toLowerCase();
						const name = (value.row.firstname + " " + value.row.lastname).toLowerCase();
						const email = value.row.email.toLowerCase();
						return name.includes(filterItemLowered) || email.includes(filterItemLowered);
					};
				}
			};
		} else if (operator.value === "equals") {
			return {
				...operator,
				getApplyFilterFn: (filterItem) => {
					if (!filterItem.value) return null;
					return (value) => {
						const filterItemLowered = filterItem.value.toLowerCase();
						const name = (value.row.firstname + " " + value.row.lastname).toLowerCase();
						const email = value.row.email.toLowerCase();
						return name === filterItemLowered || email === filterItemLowered;
					};
				}
			};
		} else if (operator.value === "startsWith") {
			return {
				...operator,
				getApplyFilterFn: (filterItem) => {
					if (!filterItem.value) return null;
					return (value) => {
						const filterItemLowered = filterItem.value.toLowerCase();
						const name = (value.row.firstname + " " + value.row.lastname).toLowerCase();
						const email = value.row.email.toLowerCase();
						return name.startsWith(filterItemLowered) || email.startsWith(filterItemLowered);
					};
				}
			};
		} else if (operator.value === "endsWith") {
			return {
				...operator,
				getApplyFilterFn: (filterItem) => {
					if (!filterItem.value) return null;
					return (value) => {
						const filterItemLowered = filterItem.value.toLowerCase();
						const name = (value.row.firstname + " " + value.row.lastname).toLowerCase();
						const email = value.row.email.toLowerCase();
						return name.endsWith(filterItemLowered) || email.endsWith(filterItemLowered);
					};
				}
			};
		} else if (operator.value === "isEmpty") {
			return {
				...operator,
				getApplyFilterFn: () => {
					return (value) => {
						const name = value.row.firstname + " " + value.row.lastname;
						return name === "" || value.row.email === "";
					};
				}
			};
		} else if (operator.value === "isNotEmpty") {
			return {
				...operator,
				getApplyFilterFn: () => {
					return (value) => {
						const name = value.row.firstname + " " + value.row.lastname;
						return name !== "" || value.row.email !== "";
					};
				}
			};
		} else if (operator.value === "isAnyOf") {
			return {
				...operator,
				getApplyFilterFn: (filterItem) => {
					if (!filterItem.value) return null;
					const filterItems = filterItem.value.join(",").toLowerCase();
					return (value) => {
						const name = (value.row.firstname + " " + value.row.lastname).toLowerCase();
						const email = value.row.email.toLowerCase();
						return filterItems.includes(name) || filterItems.includes(email);
					};
				}
			};
		} else {
			return operator;
		}
	});

/** 
 * End custom filtering operators for the CoApp standard datagrid.
 */
