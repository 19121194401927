import { Card, styled } from "@mui/material";

export const FormulaContainer = styled(Card)(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	border: "1px solid #A9A9A9",
	borderRadius: theme.spacing(2),
	display: "inline-block",
	height: "550px",
	padding: "5px",
	[theme.breakpoints.up("md")]: {
		width: "761px",
		height: "450px"
	},
	[theme.breakpoints.up("xl")]: {
		width: "1000px",
		height: "550px"
	}
}));

export const FormulaInput = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	fontSize: "0.75rem",
	fontWeight: "500",
	color: "#7482A0",
	height: "200px",
	border: "solid 1px #A9A9A9",
	borderRadius: theme.spacing(2),
	padding: "10px",
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	[theme.breakpoints.up("md")]: {
		width: "750px",
		height: "175px"
	},
	[theme.breakpoints.up("xl")]: {
		width: "1105",
		height: "200px"
	}
}));

export const FormulaItem = styled("div")(({ theme, borderColor }) => ({
	backgroundColor: "#FFFFFF",
	border: `solid 2px ${borderColor}`,
	borderRadius: "5px",
	color: "#111111",
	flexGrow: "1",
	fontSize: "1rem",
	fontWeight: "400",
	margin: "5px",
	padding: "5px",
	textTransform: "none",
	position: "relative",
	overflow: "hidden",

	"& svg": {
		color: "#FF0000",
	},

	"&:hover": {
		backgroundColor: "#F2F2F2",
		"& svg": {
			transition: "visibility 0.2s ease-in-out",
			visibility: "visible",

			"&:hover": {
				color: "#8B0000",
			}
		}
	}
}));

export const TrashInput = styled("div")(({ theme }) => ({
	borderRadius: "13px",
	height: "50px",
	padding: "25px",
}));