import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import AlertSnackbar from "./components/global/components/alert-snackbar";
import AppRoutes from "./routes";
import { lightModeTheme } from "./theme";

export default function App() {
	return (
		<ThemeProvider theme={lightModeTheme}>
			<div>
				<CssBaseline />
				<AppRoutes />
				<AlertSnackbar />
			</div>
		</ThemeProvider>
	);
}