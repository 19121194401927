import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	CircularProgress, Container, Stack,
	Switch
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import { selectNavState, setNavState } from "../../../redux/navigationSlice";
import { selectOrganizationSsoAndScim } from "../../../redux/userSlice";
import CoAppDataGridDateTimeCell from "../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../../global/components/datagrid/coapp-datagrid-list-cell";
import CoAppDataGridNameCell from "../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppStandardDataGrid from "../../global/components/datagrid/coapp-standard-datagrid";
import CoAppCreateUserModal from "../../global/components/modals/coapp-create-user-modal";
import CoAppDestructiveConfirmationModal from "../../global/components/modals/coapp-destructive-confirmation-modal";
import ThreeDotMenu from "../../global/components/three-dot-menu";

const userCreateInitialState = {
	email: "",
	firstName: "",
	lastName: "",
	roles: [],
	emailError: "",
	firstNameError: "",
	lastNameError: "",
	roleError: "",
};

export default function UserManagement() {
	const authHeader = useAuthHeader();
	const navState = useSelector(selectNavState);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const organizationSsoStatus = useSelector(selectOrganizationSsoAndScim);
	const [organizationUsersLoaded, setOrganizationUsersLoaded] = useState(false);
	const [organizationUsers, setOrganizationUsers] = useState([]);
	const [userBeingDeleted, setUserBeingDeleted] = useState({});
	const [userCreationDialogOpen, setUserCreationDialogOpen] = useState(false);
	const [userCreateData, setUserCreateData] = useState(userCreateInitialState);
	const [availableRoles, setAvailableRoles] = useState([]);
	const [userDeletionDialogOpen, setUserDeletionDialogOpen] = useState(false);
	const [userSeed, setUserSeed] = useState(1);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);

	const columns = [
		{
			field: "user",
			headerName: "Name",
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridNameCell item={params.row} isUser={true} />
			),
		},
		{
			field: "roles",
			headerName: "Roles",
			editable: true,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value} resource="Roles" />
			),
		},
		{
			field: "groups",
			headerName: "Groups",
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value} resource="Groups" />
			),
		},
		{
			field: "lastExtensionHeartbeat",
			headerName: "Last extension heartbeat",
			editable: false,
			flex: 1,
			valueGetter: (params) => new Date(params.row.workstation?.lastPhoneHome),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.row.workstation?.lastPhoneHome} />
			),
		},
		{
			field: "createdAt",
			headerName: "Created",
			editable: false,
			flex: 1,
			valueGetter: (params) => new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.value} />
			),
		},
		{
			field: "status",
			headerName: "Status",
			editable: false,
			flex: 0.5,
			renderCell: (params) => (
				<Switch
					checked={params.row.active}
					disabled={organizationSsoStatus}
					onChange={() => handleUserStatusToggle(params.row.id, params.row.active === true ? "false" : "true")}
				/>
			),
		},
		{
			field: "actions",
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<div>
					{
						organizationSsoStatus ?
							<ThreeDotMenu
								options={[
									{
										name: "View User",
										optionClickHandler: () => {
											handleOpenUserEditPage({ params });
										},
									},
								]}
							/>
							:
							<ThreeDotMenu
								options={
									[
										{
											name: "Edit",
											optionClickHandler: () => {
												handleOpenUserEditPage({ params });
											},
										},
										{
											name: "Delete",
											optionClickHandler: () => {
												handleOpenUserDeletionDialog({ params });
											},
										},
									]
								}
							/>
					}
				</div>
			)
		}
	];

	const handleOpenUserEditPage = (rowObject) => {
		let oldNavState = [...navState];
		oldNavState[6] = { // Users is the 6th item in the nav array
			...oldNavState[6],
			children: [
				{
					id: Math.random(),
					name: rowObject.params.row.firstname && rowObject.params.row.lastname ? `${rowObject.params.row.firstname} ${rowObject.params.row.lastname}` : rowObject.params.row.email,
					children: [],
					keepOpen: true,
					isClickable: true
				}
			],
			isParentChangeEvent: true
		};
		dispatch(setNavState(oldNavState));
		navigate(pages.profile + rowObject.params.id);
	};

	const handleCloseUserDeletionDialog = () => {
		setUserDeletionDialogOpen(false);
		setUserBeingDeleted({});
	};

	const handleOpenUserDeletionDialog = (rowObject) => {
		let userBeingDeleted = rowObject.params.row;
		setUserBeingDeleted(userBeingDeleted);
		setUserDeletionDialogOpen(true);
	};

	const handleUserStatusToggle = (id, state) => {
		let userJSON = {
			active: state
		};
		axios.put(apiRoutes.setUserActive + "/" + id, userJSON, { headers: authHeader })
			.then(() => {
				initUserManagement();
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(messages.USER_TOGGLE_SUCCESS_MSG));
			})
			.catch((error) => {
				console.error(error);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.USER_TOGGLE_ERROR_MSG));
			});
	};

	const handleUserCreateDataChange = (key, value) => {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		switch (key) {
			case "email":
				if (!emailRegex.test(value)) {
					setUserCreateData({ ...userCreateData, email: value, emailError: value.length === 0 ? "" : "Invalid email" });
					return;
				} else {
					setUserCreateData({ ...userCreateData, email: value, emailError: "" });
				}
				break;
			case "firstName":
				setUserCreateData({ ...userCreateData, firstName: value, firstNameError: "" });
				break;
			case "lastName":
				setUserCreateData({ ...userCreateData, lastName: value, lastNameError: "" });
				break;
			case "roles":
				value = value.map((role) => {
					let foundRole = availableRoles.find((availableRole) => availableRole.name === role);
					return foundRole;
				});
				setUserCreateData({ ...userCreateData, roles: value, roleError: "" });
				break;
			default:
				break;
		}
	};

	const validateUserCreateData = () => {
		let isValid = true;
		let emailError = "";
		let firstNameError = "";
		let lastNameError = "";
		let roleError = "";

		if (!userCreateData.email) {
			emailError = "Required *";
			isValid = false;
		}

		if (!userCreateData.firstName) {
			firstNameError = "Required *";
			isValid = false;
		}

		if (!userCreateData.lastName) {
			lastNameError = "Required *";
			isValid = false;
		}

		if (userCreateData.roles.length === 0) {
			roleError = "Required *";
			isValid = false;
		}

		setUserCreateData({
			...userCreateData,
			emailError: emailError,
			firstNameError: firstNameError,
			lastNameError: lastNameError,
			roleError: roleError,
		});

		return isValid;
	};

	const createUser = () => {
		if (validateUserCreateData()) {
			let userCreationObject = {
				email: userCreateData.email,
				firstName: userCreateData.firstName,
				lastName: userCreateData.lastName,
				roles: userCreateData.roles.map((role) => role.name)
			};
			axios.post(apiRoutes.createUser, userCreationObject, {
				headers: authHeader,
			}).then(() => {
				handleCloseUserCreateModal();
				setUserSeed(Math.random());
				dispatch(setMessage(messages.USER_CREATION_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			}).catch((err) => {
				console.error(err);
				if (err.response.data.message.includes("already exists")) {
					setUserCreateData({ ...userCreateData, emailError: messages.EMAIL_ALREADY_EXISTS_ERROR_MSG });
					return;
				}
				dispatch(setMessage(err.response.data.message || messages.USER_CREATION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
		}
	};

	const deleteUser = () => {
		axios.delete(apiRoutes.deleteUser + "/" + userBeingDeleted.id, {
			headers: authHeader
		}).then(() => {
			handleCloseUserDeletionDialog();
			setUserSeed(Math.random());
			dispatch(setMessage(messages.USER_DELETION_SUCCESS_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.SUCCESS));
		}).catch(err => {
			console.error(err);
			dispatch(setMessage(messages.USER_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
		});
	};

	const initUserManagement = () => {
		axios.get(apiRoutes.getUsers, { headers: authHeader, })
			.then((res) => {
				setOrganizationUsers(res.data);
				setOrganizationUsersLoaded(true);
			}).catch((err) => {
				console.log(err);
			});
	};

	const handleCloseUserCreateModal = () => {
		setUserCreationDialogOpen(false);
		setAvailableRoles([]);
		setUserCreateData(userCreateInitialState);
	};

	const toggleAddNewDialog = () => {
		axios.get(apiRoutes.getRoles, { headers: authHeader })
			.then(response => {
				setAvailableRoles(response.data);
			})
			.catch(error => {
				console.error("Error fetching roles:", error);
			});
		setUserCreationDialogOpen(!userCreationDialogOpen);
	};

	useEffect(() => {
		initUserManagement();
	}, [userSeed]);

	if (organizationUsersLoaded) {
		return (
			<>
				<CoAppDatagridHeader
					title="Users"
					resourceType="User"
					addOnClickHandler={toggleAddNewDialog}
					addIsPresent={organizationSsoStatus ? false : true}
					editIsPresent={false}
					deleteIsPresent={false}
				/>
				<CoAppStandardDataGrid
					columns={columns}
					rows={organizationUsers}
					pinnedColumns={
						organizationSsoStatus ?
							["actions", "name", "status"]
							:
							["actions", "__check__", "name", "status"]
					}
					allowSelection={organizationSsoStatus ? false : true}
					targetResource="users"
					selectionModel={selectedUsers}
					setSelectionModel={setSelectedUsers}
					handleModalToggle={() => setBulkDeleteDialogOpen(true)}
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={userDeletionDialogOpen}
					dialogTitle={`Delete ${userBeingDeleted.email}?`}
					dialogMessage={messages.DELETION_CONFIRMATION_MSG(userBeingDeleted.email, "user")}
					confirmClickHandler={deleteUser}
					cancelClickHandler={handleCloseUserDeletionDialog}
					actionText="Delete"
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={bulkDeleteDialogOpen}
					dialogTitle={`Delete selected ${selectedUsers.length > 1 ? "users" : "user"}?`}
					dialogMessage={"Are you sure you want to bulk delete all the selected users? This action cannot be undone."}
					confirmClickHandler={() => console.log("Bulk deletion not implemented yet")}
					cancelClickHandler={() => setBulkDeleteDialogOpen(false)}
					actionText="Delete"
				/>
				<CoAppCreateUserModal
					dialogOpen={userCreationDialogOpen}
					availableRoles={availableRoles}
					cancelClickHandler={handleCloseUserCreateModal}
					confirmClickhandler={createUser}
					userDataChangeHandler={handleUserCreateDataChange}
					userData={userCreateData}
				/>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}