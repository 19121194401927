import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	CircularProgress, Container, Stack,
	Switch
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectNavState, setNavState } from "../../../../redux/navigationSlice";
import { selectOrganizationSsoAndScim, selectUserId, setPermissions } from "../../../../redux/userSlice";
import CoAppDataGridDateTimeCell from "../../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../../../global/components/datagrid/coapp-datagrid-list-cell";
import CoAppDataGridNameCell from "../../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/coapp-standard-datagrid";
import CoAppAddItemsToListModal from "../../../global/components/modals/coapp-add-items-to-list-modal";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../global/components/modals/coapp-edit-name-modal";
import ThreeDotMenu from "../../../global/components/three-dot-menu";

export default function RoleUsers() {
	const authHeader = useAuthHeader();
	const navState = useSelector(selectNavState);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { roleId } = useParams();
	const { state } = useLocation();
	const organizationSsoStatus = useSelector(selectOrganizationSsoAndScim);
	const currentUserId = useSelector(selectUserId);

	const [availableUsers, setAvailableUsers] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [roleUsers, setRoleUsers] = useState([]);
	const [userSeed, setUserSeed] = useState(1);
	const [usersToAdd, setUsersToAdd] = useState([]);
	const [addUsersToggled, setAddUsersToggled] = useState(false);
	const [deleteRoleDialogOpen, setDeleteRoleDialogOpen] = useState(false);
	const [editRoleDialogOpen, setEditRoleDialogOpen] = useState(false);
	const [removeUsersIsToggled, setRemoveUsersIsToggled] = useState(false);
	const [roleNameInput, setRoleNameInput] = useState("");
	const [roleNameError, setRoleNameError] = useState("");

	const columns = [
		{
			editable: false,
			field: "user",
			flex: 1,
			headerName: "User",
			pinnable: false,
			renderCell: (params) => (
				<CoAppDataGridNameCell item={params.row} isUser={true} />
			),
		},
		{
			editable: false,
			field: "roles",
			flex: 1,
			headerName: "Roles",
			pinnable: false,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value} resource="Roles" />
			),
		},
		{
			disableColumnMenu: true,
			editable: false,
			field: "groups",
			filterable: false,
			flex: 1,
			headerName: "Groups",
			pinnable: false,
			sortable: false,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value} resource="Groups" />
			),
		},
		{
			editable: false,
			field: "lastExtensionHeartbeat",
			flex: 1,
			headerName: "Last extension heartbeat",
			pinnable: false,
			valueGetter: (params) => new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.row.workstation?.lastPhoneHome} />
			),
		},
		{
			editable: false,
			field: "createdAt",
			flex: 1,
			headerName: "Created",
			pinnable: false,
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.value} />
			),
		},
		{
			editable: false,
			field: "status",
			flex: 0.5,
			headerName: "Status",
			pinnable: false,
			renderCell: (params) => (
				<Switch
					checked={params.row.active}
					disabled={organizationSsoStatus}
					onChange={() => handleUserStatusToggle(params.row.id, params.row.active === true ? "false" : "true")}
				/>
			),
		},
		{
			field: "actions",
			flex: .1,
			resizable: false,
			type: "actions",
			renderCell: (params) => (
				<div>
					{
						organizationSsoStatus ?
							<ThreeDotMenu
								options={[
									{
										name: "View User",
										optionClickHandler: () => {
											handleViewUser(params);
										},
									},
								]}
							/>
							:
							<ThreeDotMenu
								options={[
									{
										name: "Remove From Role",
										optionClickHandler: () => {
											removeUserFromRole(params.row.id);
										},
									},
									{
										name: "View User",
										optionClickHandler: () => {
											handleViewUser(params);
										},
									},
								]}
							/>
					}
				</div>
			),
		}
	];

	const handleViewUser = (params) => {
		let oldNavState = [...navState];
		oldNavState[8] = {
			...oldNavState[8],
			isDeepestChildChangeEvent: true, //handles deepest nesting for user when viewing from roles
			isParentChangeEvent: false
		};
		oldNavState[8].children = oldNavState[8].children.map(resource => {
			if (resource.name === "Users") {
				let temp = { ...resource };
				temp.children = [{
					id: Math.random(),
					name: params.row.firstname && params.row.lastname ? `${params.row.firstname} ${params.row.lastname}` : params.row.email,
					children: [],
					keepOpen: true,
					isClickable: true,
					isUserNavigationItem: true
				}];
				return temp;
			}
			return resource;
		});
		dispatch(setNavState(oldNavState));
		navigate(pages.profile + params.row.id);
	};

	const handleCurrentUserPermissionUpdate = () => {
		axios.get(apiRoutes.getUser(1, currentUserId, "", null), { headers: authHeader })
			.then(res => {
				dispatch(setPermissions(res.data.permissions));
			})
			.catch(err => console.log(err));
	};

	const addUsersToRole = () => {
		const userIds = usersToAdd.map(u => u.id);
		axios.post(apiRoutes.addUsersToRole(roleId), { userIds: userIds }, {
			headers: authHeader
		}).then(() => {
			setUserSeed(userSeed + 1);
			toggleAddNewDialog();
			setUsersToAdd([]);
			dispatch(setMessage(messages.USERS_ADDED_SUCCESS_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.SUCCESS));
			const currentUserIsInCurrentRolesUsers = roleUsers.filter(user => user.id === currentUserId).length > 0;
			if (currentUserIsInCurrentRolesUsers) {
				handleCurrentUserPermissionUpdate();
			}
		}).catch(err => {
			console.error(err);
			dispatch(setMessage(messages.USERS_ADDED_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
		});
	};

	const addUserToUsersToAdd = (value) => {
		//value is an array of user names, we need to convert them to user objects to display name + email in tooltip and add/remove based on email OR name
		value = value.map((user) => {
			let tempArr = [...roleUsers, ...availableUsers];
			let foundUser = tempArr.find((row) => row.name === user);
			//set the name to the email when the user has no firstname and lastname
			foundUser.name = foundUser.name === foundUser.email ? foundUser.email : `${foundUser.firstname} ${foundUser.lastname}`;
			return foundUser;
		});
		setUsersToAdd(value);
	};

	const init = () => {
		let userQueryObject = {
			roleId: roleId,
		};
		axios.get(apiRoutes.getUsers, {
			headers: authHeader,
			params: userQueryObject,
		})
			.then((res) => {
				setRoleUsers(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const initUsersForAdd = () => {
		axios
			.get(apiRoutes.getUsers, {
				headers: authHeader,
			})
			.then((res) => {
				let users = res.data.filter((user) => {
					return !roleUsers.some((roleUser) => roleUser.id === user.id);
				});
				users = users.map((user) => {
					return {
						id: user.id,
						name: user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : user.email,
						email: user.email,
						firstname: user.firstname,
						lastname: user.lastname
					};
				});
				setAvailableUsers(users);
			})
			.catch((err) => {
				console.log(err);
			});
	};



	const removeUserFromRole = (userId) => {
		axios
			.post(apiRoutes.removeUsersFromRole(roleId), { userIds: [userId] }, {
				headers: authHeader,
			})
			.then((response) => {
				if (response.status === 200) {
					init();
					dispatch(setMessage(messages.USER_REMOVED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
					const currentUserIsInCurrentRolesUsers = roleUsers.filter(user => user.id === currentUserId).length > 0;
					if (currentUserIsInCurrentRolesUsers) {
						handleCurrentUserPermissionUpdate();
					}
				} else {
					dispatch(setMessage(messages.USER_REMOVED_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.USER_REMOVED_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR
				));
			});
	};

	const handleUserStatusToggle = (id, state) => {
		let userJSON = {
			active: state
		};
		axios.put(apiRoutes.setUserActive + "/" + id, userJSON, { headers: authHeader })
			.then(() => {
				init();
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(messages.USER_TOGGLE_SUCCESS_MSG));
			})
			.catch((error) => {
				console.error(error);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.USER_TOGGLE_ERROR_MSG));
			});
	};

	const toggleDeleteRoleDialog = () => {
		setDeleteRoleDialogOpen(!deleteRoleDialogOpen);
	};

	const deleteRole = () => {
		axios.delete(apiRoutes.deleteRole + "/" + roleId, {
			headers: authHeader
		}).then((res) => {
			if (res.status === 200) {
				updateNavigation(true);
				dispatch(setMessage(messages.ROLE_DELETION_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				navigate(pages.roleManagement);
				const currentUserIsInCurrentRolesUsers = roleUsers.filter(user => user.id === currentUserId).length > 0;
				if (currentUserIsInCurrentRolesUsers) {
					handleCurrentUserPermissionUpdate();
				}
			} else {
				console.log(res);
				dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			}
			toggleDeleteRoleDialog();
		}).catch((err) => {
			console.log(err);
			dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
			toggleDeleteRoleDialog();
		});
	};

	const toggleEditRoleDialog = () => {
		setEditRoleDialogOpen(!editRoleDialogOpen);
		setRoleNameInput("");
		setRoleNameError("");
	};

	const handleRoleNameChange = (e) => {
		setRoleNameInput(e.target.value);
		setRoleNameError("");
	};

	const handleRoleNameChangeSubmit = (e) => {
		if (!roleNameInput) return;

		if (roleNameInput === state.name) {
			toggleEditRoleDialog();
			return;
		}

		axios.put(apiRoutes.updateRole(roleId), { name: roleNameInput }, { headers: authHeader })
			.then(() => {
				toggleEditRoleDialog();
				updateNavigation();
				dispatch(setMessage(messages.ROLE_NAME_UPDATED_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setRoleNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Role"));
					return;
				}
				toggleEditRoleDialog();
				dispatch(setMessage(messages.ROLE_NAME_UPDATE_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const updateNavigation = (isDeleteEvent = false) => {
		const oldNavState = [...navState];
		if (!isDeleteEvent) {
			let copyGroupChildren = [...oldNavState[8].children].slice(1); //update nav state to reflect group name update
			copyGroupChildren.unshift({
				id: Math.random(),
				name: roleNameInput,
				children: [],
				isGroupingLabel: true
			});

			oldNavState[8] = {
				...oldNavState[8],
				children: copyGroupChildren,
				isChangeEvent: true,
				isStillSelected: true,
				selectedResource: "Users",
				isNameChangeEvent: true
			};
		} else {
			oldNavState[8] = {
				...oldNavState[8],
				children: [],
				isStillSelected: true,
				selectedResource: ""
			};
		}
		dispatch(setNavState(oldNavState));
	};

	const removeUsersFromRole = () => {
		axios
			.post(apiRoutes.removeUsersFromRole(roleId), { userIds: selectedUsers }, {
				headers: authHeader,
			})
			.then((response) => {
				if (response.status === 200) {
					init();
					setRemoveUsersIsToggled(false);
					setSelectedUsers([]);
					dispatch(setMessage(messages.USER_REMOVED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
				} else {
					dispatch(setMessage(messages.USER_REMOVED_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.USER_REMOVED_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR
				));
			});
	};

	const toggleAddNewDialog = () => {
		if (!addUsersToggled) {
			initUsersForAdd();
			setAddUsersToggled(true);
		} else {
			setAddUsersToggled(false);
			setAvailableUsers([]);
			setUsersToAdd([]);
		}
	};

	useEffect(() => {
		init();
	}, [userSeed, state.name]);

	if (roleId) {
		return (
			<>
				<CoAppDatagridHeader
					title={`${state.name} User Management`}
					resourceType="Role"
					subResourceType="User"
					addIsPresent={organizationSsoStatus ? false : true}
					addOnClickHandler={toggleAddNewDialog}
					editIsPresent={true}
					editOnClickHandler={toggleEditRoleDialog}
					deleteIsPresent={true}
					deleteOnClickHandler={toggleDeleteRoleDialog}
				/>
				<CoAppStandardDataGrid
					columns={columns}
					rows={roleUsers}
					pinnedColumns={
						organizationSsoStatus ?
							["actions", "name", "status"]
							:
							["actions", "__check__", "name", "status"]
					}
					allowSelection={organizationSsoStatus ? false : true}
					targetResource="users"
					parentResource="role"
					parentResourceName={state.name}
					bulkActionText="Remove"
					selectionModel={selectedUsers}
					setSelectionModel={setSelectedUsers}
					handleModalToggle={() => setRemoveUsersIsToggled(prev => !prev)}
				/>
				<CoAppAddItemsToListModal
					addButtonTitle="Save"
					inputLabel="User(s)"
					addItemsIsToggled={addUsersToggled}
					addItemsToCollection={addUsersToRole}
					addItemsToNewArray={addUserToUsersToAdd}
					dialogTitle={`Add user(s) to ${state.name} role`}
					items={availableUsers}
					itemsToAdd={usersToAdd}
					toggleDialog={toggleAddNewDialog}
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={deleteRoleDialogOpen}
					dialogTitle={`Delete ${state.name} role?`}
					dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "role")}
					confirmClickHandler={deleteRole}
					cancelClickHandler={toggleDeleteRoleDialog}
					actionText="Delete"
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={removeUsersIsToggled}
					dialogTitle={`Remove ${selectedUsers.length > 1 ? "users" : "user"} from ${state.name} group?`}
					dialogMessage={`Are you sure that you want to remove the selected ${selectedUsers.length > 1 ? "users" : "user"} from the ${state.name} group?`}
					confirmClickHandler={removeUsersFromRole}
					cancelClickHandler={() => setRemoveUsersIsToggled(false)}
					actionText="Remove"
				/>
				<CoAppEditNameModal
					dialogOpen={editRoleDialogOpen}
					dialogTitle="Edit role name"
					changeHandler={handleRoleNameChange}
					placeholderText={state.name}
					confirmClickHandler={handleRoleNameChangeSubmit}
					cancelClickHandler={toggleEditRoleDialog}
					actionText="Save"
					editNameError={roleNameError}
					setEditNameError={setRoleNameError}
				/>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}
