import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Add, Delete, InfoOutlined } from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Select, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
	selectEditedVersionOfRule,
	selectFormula, selectItemsInFormula, selectTriggers, setFormula, setItemsInFormula
} from "../../../redux/ruleWizardSlice";
import CoAppTextField from "../../global/components/inputs/coapp-textfield";
import { CoAppActionButton, CoAppTextButton } from "../../global/styled/global.styled";
import { FormulaItemWrapper } from "../formula/formula-item-wrapper";
import {
	FormulaContainer, FormulaInput,
	TrashInput
} from "../styled/formula-card.styled";
import {
	SidebarContainer, SidebarRow, SidebarRowAddButton
} from "../styled/formula-sidebar.styled";
import { RuleSidebarContents } from "../styled/rule-sidebars.styled";
import { FormulaHelpText, WizardOperatorGrid, WizardOperatorItem } from "../styled/rule-wizard.styled";

/**
   * Step 4 in Rule Creation Wizard
   * User builds a formula based on location, reactions, and triggers
   * previously selected
*/
export default function WizardStep4(props) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const triggers = useSelector(selectTriggers);

	const editedVersionOfRule = useSelector(selectEditedVersionOfRule);

	/**
	 * Formula contains items and formula json
	 */
	const itemsInFormula = useSelector(selectItemsInFormula);
	const [itemsInFormulaLocal, setItemsInFormulaLocal] = useState([]);
	const formula = useSelector(selectFormula);

	/**
	 * Custom Triggers
	 */
	const [customTriggerIsToggled, setCustomTriggerIsToggled] = useState(false);
	const [newCustomTrigger, setNewCustomTrigger] = useState("");
	const [customTriggers, setCustomTriggers] = useState([]);

	/**
	 * Snap-Ins
	 */
	const [insertSnapinIsToggled, setInsertSnapinIsToggled] = useState(false);
	const [newSnapin, setNewSnapin] = useState("");

	/**
	 * Operators
	 */
	const [operators, setOperators] = useState([]);
	const [operatorsLoaded, setOperatorsLoaded] = useState(false);

	/**
	 * Custom Trigger is input by user in textfield.
	 * Value could be anything. 
	 * 1. Add value as obj in customTriggers array.
	 * 2. Push object into formula array
	 * 3. Set Formula JSON
	 */
	const addCustomTriggerToFormula = (trigger) => {
		let newTrigger = {
			triggerName: trigger,
			index: 99 + customTriggers.length,
			type: "custom"
		};
		let customTriggersArr = customTriggers;
		customTriggersArr = [...customTriggers, newTrigger];

		let itemArr = Array.from(itemsInFormulaLocal);
		itemArr.push(newTrigger);
		setItemsInFormulaLocal(itemArr);
		dispatch(setItemsInFormula(itemArr));

		setFormulaJSON(itemArr);
		setCustomTriggers(customTriggersArr);
		handleCustomTriggerToggle();
		setNewCustomTrigger("");
	};

	/**
	 * Toggle Custom Trigger Dialog
	 */
	const handleCustomTriggerToggle = () => {
		setNewCustomTrigger("");
		setCustomTriggerIsToggled(customTriggerIsToggled ? false : true);
	};

	const handleSnapinToggle = () => {
		setNewSnapin("");
		setInsertSnapinIsToggled(insertSnapinIsToggled ? false : true);
	};

	/**
	 * Custom Trigger Textfield has changed.
	 * Store in newCustomTrigger.
	 * Reset when user presses Add or Cancel
	 */
	const updateNewCustomTrigger = (e) => {
		setNewCustomTrigger(e.target.value);
	};

	/**
	 * User adds constant to formula via custom contanst dialog
	 * 1. Filter for trigger that was selected by user
	 * 2. Add trigger object to itemsInFormulaLocal
	 * 3. Add trigger object to itemsInFormula (redux)
	 * 4. Set Formula JSON
	 */
	const addTriggerToFormula = (ruleTriggerId) => {
		let filtered = triggers.filter(function (el) { return el.id === ruleTriggerId; });
		if (filtered && filtered.length > 0) {
			let trigger = filtered[0];
			let triggerWithType = { ...trigger, type: "trigger" };
			let itemArr = Array.from(itemsInFormulaLocal);
			itemArr.push(triggerWithType);
			setItemsInFormulaLocal(itemArr);
			dispatch(setItemsInFormula(itemArr));
			setFormulaJSON(itemArr);
		}
	};

	/**
	 * User adds trigger to formula via drag & drop
	 * 1. Add trigger object to itemsInFormulaLocal
	 * 2. Add trigger object to itemsInFormula (redux)
	 * 3. Set Formula JSON
	 */
	const copyTriggerToFormula = (triggerId, destinationIndex) => {
		let trigger = triggers[triggerId];
		let triggerWithType = { ...trigger, type: "trigger" };
		let itemArr = Array.from(itemsInFormulaLocal);
		itemArr.splice(destinationIndex, 0, triggerWithType);
		setItemsInFormulaLocal(itemArr);
		dispatch(setItemsInFormula(itemArr));
		setFormulaJSON(itemArr);
	};

	/**
	 * User adds operator to formula via operator dialog
	 * 1. Filter for operator that was selected by user
	 * 2. Splice in operator object to itemArr at destinationIndex
	 * 2. Add operator object to itemsInFormulaLocal 
	 * 3. Add operator object to itemsInFormula (redux)
	 * 4. Set Formula JSON
	 */
	const addOperatorToFormula = (operatorIndex, destinationIndex) => {
		let operator = operators[parseInt(operatorIndex)];
		let operatorWithType = { ...operator, type: "operator" };
		let itemArr = Array.from(itemsInFormulaLocal);
		if (destinationIndex === -1) {
			itemArr.push(operatorWithType);
		} else {
			itemArr.splice(destinationIndex, 0, operatorWithType);
		}
		setItemsInFormulaLocal(itemArr);
		dispatch(setItemsInFormula(itemArr));
		setFormulaJSON(itemArr);
	};

	/**
	 * User is reordering items from inside the formula
	 */
	const reorder = (startIndex, endIndex) => {
		const result = Array.from(itemsInFormulaLocal);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		setItemsInFormulaLocal(result);
		dispatch(setItemsInFormula(result));
		setFormulaJSON(result);
	};

	/**
	 * User is removing an item from the formula
	 * by dropping it over the trashcan
	 */
	const removeItemFromFormula = (itemId) => {
		let itemArr = Array.from(itemsInFormulaLocal);
		itemArr.splice(itemId, 1);
		setItemsInFormulaLocal(itemArr);
		dispatch(setItemsInFormula(itemArr));
		setFormulaJSON(itemArr);
	};

	/**
	 * Update formula JSON with items in formula
	 */
	const setFormulaJSON = (itemArr) => {
		let formulaObj = { formula: itemArr };
		dispatch(setFormula(formulaObj));
		if (!props.editedId) {
			//props.handleSaveProgress({ formula: formulaObj });
		}
	};

	/**
	 * Item Styling
	 */
	const getFormulaItemStyle = (isDragging, draggableStyle) => ({
		userSelect: "none",
		...draggableStyle
	});

	const getItemStyle = (isDragging, draggableStyle) => ({
		userSelect: "none",
		margin: "0 0 2px 0",
		background: isDragging ? "#F8F9FA" : "",
		...draggableStyle
	});

	const getListStyle = isDraggingOver => ({
		display: "inline-block",
		background: isDraggingOver ? "#F8F9FA" : "",
	});

	const getTrashStyle = (isDraggingOver, isDragging) => ({
		background: isDragging ? "#FFBFBF" : isDraggingOver ? "#FF8080" : "",
		opacity: isDraggingOver ? "0.3" : ""
	});

	/**
	 * Drag & Drop Behavior
	 */
	const onDragEnd = (result) => {
		const { source, destination } = result;

		if (!destination) {
			return;
		}

		switch (source.droppableId) {
			case "triggers":
				if (destination.droppableId === "formula")
					copyTriggerToFormula(source.index, destination.index);
				break;
			case destination.droppableId:
				reorder(
					source.index,
					destination.index
				);
				break;
			case "operators":
				addOperatorToFormula(source.index, destination.index);
				break;
			case "formula":
				if (destination.droppableId === "trash")
					removeItemFromFormula(source.index);
				break;
			default:
				break;
		}
	};

	const handleFormulaItemBorderColor = (type) => {
		switch (type) {
			case "trigger":
				return theme.palette.supporting.green;
			case "operator":
				return theme.palette.supporting.red;
			case "custom":
				return theme.palette.black_states.main;
			default:
				return "";
		}
	};

	const initOperators = () => {
		if (operators.length === 0) {
			let operatorArr = [
				"AND", "OR", "(", ")", "=", "DOES NOT =", "<=", ">=", "<", ">", "+", "-", "x", "÷", "CONTAINS", "DOES NOT CONTAIN", "CONTAINS NUMBER",
				"DOES NOT CONTAIN NUMBER", "CONTAINS SYMBOL", "DOES NOT CONTAIN SYMBOL", "CONTAINS LETTER", "DOES NOT CONTAIN LETTER", "IS EMPTY", "IS NOT EMPTY"
			];
			let startingIndex = triggers.length;
			operatorArr.forEach((operator) => {
				let operatorObj = {
					triggerName: operator,
					index: startingIndex
				};
				operators.push(operatorObj);
				startingIndex += 1;
			});
			setOperators(operators);
		}
		setOperatorsLoaded(true);
	};

	/**
	 * Detects changes in the formula.
	 */
	const detectFormulaChanges = () => {
		let formulaChanged = false;

		let stringifiedFormulaItems = JSON.stringify(itemsInFormula);
		let stringifiedEditVersionOfFormulaItems = JSON.stringify(editedVersionOfRule.formula.formula);
		if (stringifiedFormulaItems !== stringifiedEditVersionOfFormulaItems) {
			formulaChanged = true;
		}
		return formulaChanged;
	};

	useEffect(() => {
		initOperators();
		setItemsInFormulaLocal(itemsInFormula);
		if (props.editedId) {
			setItemsInFormulaLocal(itemsInFormula);
			if (props.editedId && Object.keys(editedVersionOfRule).length > 0) {
				if (detectFormulaChanges()) {
					props.handleStepDataChange(true);
				}
			}
		}
		props.validateFormula();
	}, [formula, triggers]);

	if (operatorsLoaded) {
		return (
			<>
				<DragDropContext onDragEnd={onDragEnd}>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: "5px" }}>
							<Typography variant="h6">Build the formula</Typography>
							<CoAppActionButton onClick={handleCustomTriggerToggle} >Insert Constant</CoAppActionButton>
							<CoAppActionButton disabled="true" onClick={handleSnapinToggle} >Insert Snap-In</CoAppActionButton>
						</Grid>
						<Grid item xs={9}>
							<FormulaContainer>
								{
									<Droppable droppableId="operators" direction="horizontal" isDropDisabled={true}>
										{(provided, snapshot) => (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
												style={getListStyle(snapshot.isDraggingOver)}
											>
												<Typography variant="body1">Operators</Typography>
												<WizardOperatorGrid container >
													{operators.map((op, index) =>
														<Draggable key={index} draggableId={index.toString() + "-op"} index={index} >
															{(provided, snapshot) =>
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getFormulaItemStyle(
																		snapshot.isDragging,
																		provided.draggableProps.style
																	)}
																>
																	<WizardOperatorItem
																		item
																		key={op.index}
																		value={op.triggerName}
																		onClick={() => addOperatorToFormula(index, -1)}
																	>
																		{op.triggerName}
																	</WizardOperatorItem>
																</div>
															}
														</Draggable>

													)}
												</WizardOperatorGrid>
											</div>
										)}
									</Droppable>
								}
								<Typography>
									Trigger If:
								</Typography>
								{
									props.formulaError !== "" ?
										<Typography sx={{ color: "#d32f2f" }} variant="subtitle2">
											<InfoOutlined fontSize="small" sx={{ verticalAlign: "middle", paddingRight: "2px", paddingBottom: "3px" }} />
											{props.formulaError}
										</Typography>
										:
										null
								}
								<Droppable droppableId="formula" direction="horizontal">
									{(provided, snapshot) => (
										<div
											{...provided.droppableProps}
											ref={provided.innerRef}
											style={getListStyle(snapshot.isDraggingOver)}
										>
											<FormulaInput sx={{ zIndex: "999" }}>
												{
													itemsInFormulaLocal.length > 0 ?
														itemsInFormulaLocal.map((item, index) =>
															<Draggable key={index} draggableId={index.toString() + "-formula-item"} index={index}>
																{(provided, snapshot) => (
																	<div
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		style={getFormulaItemStyle(
																			snapshot.isDragging,
																			provided.draggableProps.style
																		)}
																	>
																		<FormulaItemWrapper borderColor={handleFormulaItemBorderColor(item.type)} item={item} triggerName={item.triggerName} />
																	</div>
																)}
															</Draggable>
														)
														:
														<FormulaHelpText>
															Drag Triggers, Add Constants, and Select Operators to Create a Formula
														</FormulaHelpText>
												}
											</FormulaInput>
										</div>
									)}
								</Droppable>
								<Droppable droppableId="trash">
									{(provided, snapshot) => (
										<div
											{...provided.droppableProps}
											ref={provided.innerRef}
											style={getTrashStyle(snapshot.isDraggingOver, snapshot.isDragging)}
										>
											<TrashInput sx={{ zIndex: "999" }}>
												<Grid container justifyContent="center" alignItems="center">
													<Grid item>
														<Delete />
													</Grid>
												</Grid>
											</TrashInput>
										</div>
									)}
								</Droppable>
							</FormulaContainer>
						</Grid>

						<Grid item xs={3}>
							<Droppable droppableId="triggers" isDropDisabled={true}>
								{(provided, snapshot) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}
										style={getListStyle(snapshot.isDraggingOver)}
									>
										<SidebarContainer>
											<Typography sx={{ padding: "5px" }} variant="h6">
												Triggers
											</Typography>
											<Divider sx={{ width: "100%" }} />
											<RuleSidebarContents>
												{
													triggers.map((trigger, index) =>
														<Draggable key={index} draggableId={index.toString()} index={index}>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided.draggableProps.style,
																		trigger
																	)}
																>
																	<SidebarRow>
																		<Typography sx={{ fontWeight: "600" }} variant="body2">
																			{trigger.triggerName}
																		</Typography>
																		<>
																			{trigger.location.name ? (
																				<div>
																					<SidebarRowAddButton onClick={() => addTriggerToFormula(trigger.id)}>
																						<Add sx={{ cursor: "pointer" }} />
																					</SidebarRowAddButton>
																					<Box sx={{ display: "flex", flexDirection: "column" }}>
																						<Typography variant="body3">Location: {trigger.location.name}</Typography>
																						<Typography variant="body3">Field Name: {trigger.triggerField.name}</Typography>
																						<Typography variant="body3">Field Type: {trigger.triggerField.fieldType}</Typography>
																					</Box>
																				</div>
																			) : null}
																		</>
																	</SidebarRow>
																	<Divider sx={{ width: "100%", paddingTop: "5px" }} />
																</div>
															)}
														</Draggable>
													)
												}
											</RuleSidebarContents>
										</SidebarContainer>
									</div>
								)}
							</Droppable>
						</Grid>
					</Grid>
				</DragDropContext>

				<Dialog open={customTriggerIsToggled} onClose={handleCustomTriggerToggle} sx={{ overflow: "hidden" }} >
					<DialogTitle variant="h6">Insert a constant into your formula.</DialogTitle>
					<Divider sx={{ width: "100%" }} />
					<DialogContent>
						<CoAppTextField
							placeholder="ex. Aspheric"
							onChange={updateNewCustomTrigger}
							value={newCustomTrigger}
							label="Constant"
							id="custom-trigger"
							inputProps={{ maxLength: 150 }}
						/>
					</DialogContent>
					<Divider sx={{ width: "100%" }} />
					<DialogActions sx={{ marginTop: "7px" }}>
						<CoAppTextButton onClick={handleCustomTriggerToggle}>Cancel</CoAppTextButton>
						<CoAppActionButton disabled={newCustomTrigger === ""} onClick={() => { addCustomTriggerToFormula(newCustomTrigger); }}>Insert</CoAppActionButton>
					</DialogActions>
				</Dialog>

				<Dialog open={insertSnapinIsToggled} onClose={handleSnapinToggle} sx={{ overflow: "hidden" }} >
					<DialogTitle variant="h6">Insert a Snap-In into your formula.</DialogTitle>
					<Divider sx={{ width: "100%" }} />
					<DialogContent>
						<Select
							label="Snap-In"
							id="snapin"
							value={newSnapin}
							onChange={(e) => setNewSnapin(e.target.value)}
							fullWidth
						>
							<option value="1">Snap-In 1</option>
							<option value="2">Snap-In 2</option>
							<option value="3">Snap-In 3</option>
						</Select>
					</DialogContent>
					<Divider sx={{ width: "100%" }} />
					<DialogActions sx={{ marginTop: "7px" }}>
						<CoAppTextButton onClick={handleSnapinToggle}>Cancel</CoAppTextButton>
						<CoAppActionButton onClick={() => { addCustomTriggerToFormula(newCustomTrigger); }}>Insert</CoAppActionButton>
					</DialogActions>
				</Dialog>
			</>
		);
	} else {
		return <Typography>Getting things ready</Typography>;
	}
}