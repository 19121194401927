import { Container, styled } from "@mui/material";

export const SidebarContainer = styled(Container)(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	border: "1px solid #A9A9A9",
	borderRadius: theme.spacing(2),
	boxShadow: "0 1px 3px grey",
	height: "400px",
	marginTop: "18px",
	overflow: "hidden",
	paddingLeft: "5px!important",
	paddingRight: "5px!important",
	padding: "10px",
	width: "300px",
	[theme.breakpoints.up(1900)]: {
		marginLeft: "100px"
	},
	[theme.breakpoints.between(1600, 1850)]: {
		marginLeft: "50px"
	},
	marginLeft: "-50px"
}));

export const SidebarRow = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	width: "100%",
	height: "60px",
	padding: "2px",
	display: "inline-block",
	marginLeft: "5px"
}));

export const SidebarRowAddButton = styled("div")(({ theme, hasWhich }) => ({
	color: "#1D38BD",
	float: "right",
	marginRight: "30px",
	marginTop: hasWhich ? "20px" : "5px",
	"&.MuiSvgIcon-root": {
		fontSize: "small",
		verticalAlign: "middle",
		"&:hover": {
			cursor: "pointer",
			background: "none",
			color: "#A9A9A9"
		}
	}
}));