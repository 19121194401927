import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { CoAppActionButton, CoAppCancelTextButton } from "../styled/global.styled";

export default function ConfirmationDialog({ dialogDetails }) {
	if ("confirmationListItems" in dialogDetails) {
		return (
			<Dialog open={dialogDetails.dialogOpen}>
				<DialogTitle variant="h6">{dialogDetails.confirmationTitle}</DialogTitle>
				<DialogContent>
					{dialogDetails.confirmationMessage}
					<ul>
						{dialogDetails.confirmationListItems.map((item, index) => (
							<li key={index} style={{ color: "#A2A2A2", marginBottom: "5px" }}>{item}</li>
						))}
					</ul>
				</DialogContent>
				<DialogActions sx={{ justifyContent: "flex-start", marginLeft: "5px" }}>
					<CoAppActionButton onClick={dialogDetails.confirmClickHandler}>Confirm</CoAppActionButton>
					<CoAppCancelTextButton sx={{ padding: "12px", color: "#A1A1A1" }} onClick={dialogDetails.cancelClickHandler}>Cancel</CoAppCancelTextButton>
				</DialogActions>
			</Dialog>
		);

	} else {
		return (
			<Dialog open={dialogDetails.dialogOpen}>
				<DialogTitle variant="h6">{dialogDetails.confirmationTitle}</DialogTitle>
				<DialogContent>{dialogDetails.confirmationMessage}</DialogContent>
				<DialogActions sx={{ justifyContent: "flex-start", marginLeft: "5px" }}>
					<CoAppActionButton onClick={dialogDetails.confirmClickHandler}>Confirm</CoAppActionButton>
					<CoAppCancelTextButton sx={{ padding: "12px", color: "#A1A1A1" }} onClick={dialogDetails.cancelClickHandler}>Cancel</CoAppCancelTextButton>
				</DialogActions>
			</Dialog>
		);
	}
}