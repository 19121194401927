import { Chip, styled, TextField } from "@mui/material";

export const TagManagementChip = styled(Chip)(({ theme }) => ({
	marginRight: "5px",
	borderRadius: "4px",
	color: theme.palette.text.primary,
	backgroundColor: theme.palette.action.selected,
	"& .MuiChip-deleteIcon": {
		color: "#000000",
		opacity: 0.26,
		"&:hover": {
			opacity: 0.26,
			color: "#000000"
		}
	}
}));

export const TagManagementOption = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	width: "285px",
	":hover": {
		backgroundColor: theme.palette.primary._states.hover,
	},
	marginLeft: "10px",
	paddingLeft: "10px",
	height: "43px",
}));

export const CreateTagContainer = styled("div")(({ theme }) => ({
	":hover": {
		backgroundColor: theme.palette.primary._states.hover,
	},
	width: "285px",
	marginLeft: "10px",
	paddingLeft: "10px",
	height: "43px",
	display: "flex",
	alignItems: "center",
	cursor: "pointer"
}));

export const TagManagementTextField = styled(TextField)(({ theme, valueerror = "false" }) => ({
	width: "280px",
	height: "40px",
	margin: "10px",
	paddingLeft: "10px",
	marginBottom: valueerror === "true" ? "30px" : "0px"
}));