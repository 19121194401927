import { Divider, InputLabel, Paper, Select, Stack, StepConnector, stepConnectorClasses, StepLabel, styled, TextField } from "@mui/material";

export const ScimWizardContainer = styled("div")((props) => ({
	backgroundColor: "#F8F9FA",
	height: "100%",
}));

export const ScimWizardStepContainer = styled("div")((props) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	margin: "50px 100px 100px 100px",
}));

export const ScimWizardHeaderDivider = styled(Divider)((props) => ({
	width: "90%",
	margin: "10px auto",
}));

export const ScimWizardHeaderContainer = styled("div")((props) => ({
	width: "100%",
	marginTop: "20px",
}));

export const ScimWizardStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
	display: "flex",
	height: 50,
	alignItems: "center",
	borderRadius: "50%",
	zIndex: 2,
	"& .WizardStepIcon-completedIcon": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: ownerState.active ? "#FFFFFF" : "#2FBC70",
		border: "12px solid #2FBC70"
	},
	"& .WizardStepIcon-circle": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "#FFFFFF",
		border: "10px solid #2FBC70",
		marginTop: 0,
		fontSize: "0.7rem",
		color: ownerState.active ? "#2FBC70" : "#A2A2A2"
	},
	"& .WizardStepIcon-circle-active-incomplete": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "#FFFFFF",
		border: "10px solid #2FBC70",
		opacity: "0.5",
		marginTop: 0,
		fontSize: "0.7rem",
		color: ownerState.active ? "#2FBC70" : "#A2A2A2"
	},
	"& .WizardStepIcon-circle-inactive-incomplete": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "grey",
		border: "10px solid #eaeaf0",
		marginTop: 0,
		fontSize: "0.7rem",
		color: ownerState.active ? "#2FBC70" : "#A2A2A2"
	}
}));

export const ScimWizardStepConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 23
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#2FBC70"
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#2FBC70",
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
		borderTopWidth: 4,
		marginLeft: "-7.5px",
		marginRight: "-10px"
	}
}));

export const ScimWizardStepButtonsDiv = styled(Paper)(({ theme }) => ({
	paddingTop: "10px",
	boxShadow: "0px -1px 3px rgba(50, 50, 50, 0.75)",
	width: "100%",
	position: "fixed",
	bottom: 0,
	height: "75px",
}));

export const ScimWizardStepLabel = styled(StepLabel)(({ theme, active }) => ({
	"& .MuiStepLabel-alternativeLabel": {
		fontSize: "0.7rem !important",
		color: active ? "#2FBC70 !important" : "#A2A2A2 !important",
		marginTop: "0px !important"
	}
}));

/**
 * Step 1. Congifure SSO + SCIM.
 */

export const ScimWizardDescopeFlowContainer = styled("div")((props) => ({
	margin: "100px 350px 100px 350px"
}));

/**
 * End Step 1.
 */

/**
 * Step 2. Map groups.
 */

export const ScimWizardStack = styled(Stack)((props) => ({
	margin: "auto",
	borderRadius: "5px",
	boxShadow: "0px 2px 2px 2px #00000026",
	width: "100%",
	border: "1px solid #00000026",
}));

export const ScimWizardStackItem = styled("div")(({ itemtype }) => ({
	borderRadius: itemtype === "header" ? "5px 5px 0px 0px" : itemtype === "footer" ? "0px 0px 5px 5px" : "0px",
	backgroundColor: itemtype === "header" ? "#F7F8F9" : "#FFFFFF",
	width: "auto",
	position: "relative",
	display: "flex",
	fontSize: "1rem",
	alignItems: "center",
	height: "50px",
	borderBottom: "1px solid #00000026",
	paddingLeft: "15px",
	color: ["header", "footer"].includes(itemtype) ? "#000000" : "#A2A2A2",
}));

export const ScimWizardGroupConnector = styled("div")((props) => ({
	color: props.isconnected ? "#2FBD70" : "red",
	paddingLeft: "20px",
	paddingRight: "35px"
}));

export const ScimWizardStackItemContent = styled("div")(({ itemtype }) => ({
	minWidth: "200px",
	marginLeft: itemtype === "header" ? "45px" : "0px",

}));

export const ScimWizardStackTextItemContent = styled("p")((props) => ({
	width: props.issetuppage ? "700px" : "150px",
	marginLeft: props.issetuppage && !props.isunmapped ? "20px" : "0px",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	overflow: "hidden"
}));

export const ScimWizardMultiSelectWithSearch = styled(Select)((props) => ({
	"&.MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none"
		},
		"&:hover fieldset": {
			border: "none"
		},
		"&.Mui-focused fieldset": {
			border: "none"
		}
	},
	backgroundColor: "#FFFFFF",
	width: "450px",
}));

export const ScimWizardInputLabel = styled(InputLabel)((props) => ({
	width: "200px",
}));

export const ScimWizardStackSearchField = styled(TextField)((props) => ({
	"& fieldset": {
		borderRadius: "50px 50px 50px 50px",
	}
}));

/**
 * End Step 2.
 */
