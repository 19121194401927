import { Container, Stack, styled } from "@mui/material";

/**
 * Reaction sidebar specific.
 */
export const RuleReactionSidebarContainer = styled(Container)(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	border: "1px solid #A9A9A9",
	borderRadius: theme.spacing(2),
	boxShadow: "0 1px 3px grey",
	marginTop: "70px",
	height: "400px",
	overflow: "hidden",
	paddingLeft: "5px!important",
	paddingRight: "5px!important",
	padding: "10px",
	width: "300px"
}));
/**
 * End reaction sidebar specific.
 */

/**
 * Trigger sidebar specific.
 */
export const RuleTriggerSidebarContainer = styled(Container)(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	border: "1px solid #A9A9A9",
	borderRadius: theme.spacing(2),
	boxShadow: "0 1px 3px grey",
	height: "400px",
	marginTop: "18px",
	[theme.breakpoints.between(1500, 1600)]: {
		marginRight: "50px"
	},
	marginRight: "100px",
	overflow: "hidden",
	paddingLeft: "5px!important",
	paddingRight: "5px!important",
	padding: "10px",
	width: "300px",
}));
/**
 * End trigger sidebar specific.
 */

/**
 * Rule wizard sidebars agnostic.
 */
export const RuleSidebarContents = styled(Stack)(({ step }) => ({
	overflowY: "auto",
	overflowX: "hidden",
	maxHeight: "500px",
	"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
		backgroundColor: "lightgrey",
		width: "0.8em",
		borderRadius: 8
	},
	"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
		borderRadius: 8,
		backgroundColor: "#6b6b6b"
	},
	"&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
		backgroundColor: "#2FBC70",
	},
	"&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
		backgroundColor: "#2FBC70",
	},
	"&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
		backgroundColor: "#2FBC70",
	},
}));


export const RuleSidebarRow = styled("div")(({ theme }) => ({
	...theme.typography.body2,
	padding: "3px",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between"
}));

export const RuleSidebarRowActions = styled("div")(({ theme }) => ({
	float: "right",
	margin: "auto 0",
	"& svg": {
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.background.default
		}
	}
}));
/**
 * End rule wizard sidebars agnostic.
 */