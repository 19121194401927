import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useDescope, useSession } from "@descope/react-sdk";
import { CircularProgress, Container, Stack } from "@mui/material";

import Unauthorized from "./components/global/components/unauthorized";
import pages from "./constants/pages";
import useAuthorization from "./helpers/useAuthorization";
import {
	setEmail, setFirstName, setGroups, setLastName, setOrgId,
	setPermissions, setPhone, setRoles, setUserId
} from "./redux/userSlice";

const ProtectedRoute = ({ permissions, isInclusive }) => {
	const isAuthorized = useAuthorization(permissions, isInclusive);

	let dispatch = useDispatch();
	let navigate = useNavigate();

	const { logout } = useDescope();

	const { isAuthenticated, isSessionLoading } = useSession();

	const logoutUser = async () => {
		try {
			logout();
		} catch (e) {
			console.error(e);
		}

		dispatch(setUserId(null));
		dispatch(setEmail(null));
		dispatch(setFirstName(null));
		dispatch(setGroups([]));
		dispatch(setLastName(null));
		dispatch(setPermissions([]));
		dispatch(setPhone(null));
		dispatch(setRoles([]));
		dispatch(setOrgId(null));
		navigate(pages.login);
	};

	useEffect(() => {
		if (!isSessionLoading) {
			if (!isAuthenticated) {
				logoutUser();
			}
		}
	}, [isSessionLoading, isAuthenticated]);

	if (isSessionLoading) {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	} else if (isAuthenticated && !isAuthorized) {
		return (
			<Unauthorized />
		);
	} else if (isAuthenticated && isAuthorized) {
		return (
			<div>
				<Outlet />
			</div>
		);
	}
};

export default ProtectedRoute;