import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Dialog } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import {
	selectCoappGroups, selectCoappRoles, selectMappedGroups,
	selectMappedRoles, selectRawExternalGroups, selectUnmappedGroups,
	selectUnmappedRoles, setCoappGroups, setCoappRoles,
	setMappedGroups, setMappedRoles, setOrganizationName,
	setRawExternalGroups, setUnmappedGroups, setUnmappedRoles
} from "../../../../redux/scimWizardSlice";
import { selectUser } from "../../../../redux/userSlice";
import CoAppConfirmationModal from "../../../global/components/modals/coapp-confirmation-modal";

import ScimWizardStepper from "./stepper/scim-wizard-stepper";
import Step1 from "./steps/step-1";
import Step2 from "./steps/step-2";
import Step3 from "./steps/step-3";
import Step4 from "./steps/step-4";
import { ScimWizardContainer } from "./styled/scim-wizard.styled";
import ScimWizardHeader from "./scim-wizard-header";

function getSteps() {
	return [
		{ "num": 1, "desc": "Configure SSO + SCIM" },
		{ "num": 2, "desc": "Map Roles" },
		{ "num": 3, "desc": "Map Groups" },
		{ "num": 4, "desc": "Confirm Setup" },
	];
}

export default function ScimWizard() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const currentUser = useSelector(selectUser);
	const mappedGroups = useSelector(selectMappedGroups);
	const unmappedGroups = useSelector(selectUnmappedGroups);
	const coappGroups = useSelector(selectCoappGroups);
	const mappedRoles = useSelector(selectMappedRoles);
	const unmappedRoles = useSelector(selectUnmappedRoles);
	const coappRoles = useSelector(selectCoappRoles);
	const rawExternalGroups = useSelector(selectRawExternalGroups);
	const authHeader = useAuthHeader();

	const [activeStep, setActiveStep] = useState(0);
	const [titleCount, setTitleCount] = useState(0);
	const [tenantDetails, setTenantDetails] = useState({});
	const [stepsCompleted, setStepsCompleted] = useState({});
	const [idpConfigComplete, setIdpConfigComplete] = useState(false);
	const [wizardError, setWizardError] = useState("");
	const [confirmationDialogContent, setConfirmationDialogContent] = useState({ dialogOpen: false });

	const steps = getSteps();

	const getStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<>
						<Step1 setIdpConfigComplete={setIdpConfigComplete} tenantDetails={tenantDetails} handleSubmit={handleSubmit} />
					</>
				);
			case 1:
				return (
					<>
						<Step2 setTitleCount={setTitleCount} />
					</>
				);
			case 2:
				return (
					<>
						<Step3 setTitleCount={setTitleCount} />
					</>
				);
			case 3:
				return (
					<>
						<Step4 />
					</>
				);
			default:
				return "Unknown step";
		}
	};

	const handleSubmit = () => {
		switch (activeStep) {
			case 0:
				if (idpConfigComplete) {
					handleNext();
				} else {
					setWizardError("Please complete the SSO + SCIM configuration before proceeding.");
				}
				break;
			case 1:
				switch (hasMappedSomeRoles()) {
					case "none":
						setWizardError("Please map at least one role before proceeding.");
						break;
					case "all":
						handleNext();
						break;
					case "some":
						handleNonMappedRolesWarning();
						break;
					default:
						break;
				}
				break;
			case 2:
				switch (hasMappedSomeGroups()) {
					case "none":
						setWizardError("Please map at least one group before proceeding.");
						break;
					case "all":
						handleNext();
						break;
					case "some":
						handleNonMappedGroupsWarning();
						break;
					default:
						break;
				}
				break;
			case 3:
				handleConfigurationComplete();
				break;
			default:
				break;
		}
	};

	const hasMappedSomeGroups = () => {
		let keys = Object.keys(mappedGroups);
		let haveBeenMapped = [];
		let haveNotBeenMapped = [];
		for (let k of keys) {
			if (mappedGroups[`${k}`].length > 0) {
				haveBeenMapped.push(k);
			} else {
				haveNotBeenMapped.push(k);
			}
		}

		dispatch(setUnmappedGroups(haveNotBeenMapped));

		if (haveBeenMapped.length === 0) {
			return "none";
		} else if (haveBeenMapped.length === coappGroups.length) {
			return "all";
		} else {
			return "some";
		}
	};

	const hasMappedSomeRoles = () => {
		let keys = Object.keys(mappedRoles);
		let haveBeenMapped = [];
		let haveNotBeenMapped = [];
		for (let k of keys) {
			if (mappedRoles[`${k}`].length > 0) {
				haveBeenMapped.push(k);
			} else {
				haveNotBeenMapped.push(k);
			}
		}

		dispatch(setUnmappedRoles(haveNotBeenMapped));

		if (haveBeenMapped.length === 0) {
			return "none";
		} else if (haveBeenMapped.length === coappRoles.length) {
			return "all";
		} else {
			return "some";
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		setTitleCount(0);
	};

	const handleClearCurrentConfiguration = () => {
		axios.delete(apiRoutes.ssoAndScim(currentUser.organizationId), { headers: authHeader })
			.then(res => {
				navigate(pages.organizationManagement);
				let tempMappedGroups = { ...mappedGroups };
				for (let key in tempMappedGroups) {
					tempMappedGroups[`${key}`] = [];
				}
				dispatch(setMappedGroups(tempMappedGroups));
				setConfirmationDialogContent({ dialogOpen: false });
			})
			.catch(err => console.error(err));
	};

	const handleConfigurationConfirmed = () => {
		let tempMappedGroups = { ...mappedGroups };
		for (let key in tempMappedGroups) {
			if (tempMappedGroups[`${key}`].length === 0) {
				delete tempMappedGroups[`${key}`];
			}
		}
		let tempMappedRoles = { ...mappedRoles };
		for (let key in tempMappedRoles) {
			if (tempMappedRoles[`${key}`].length === 0) {
				delete tempMappedRoles[`${key}`];
			}
		}
		let mappings = {
			groups: tempMappedGroups,
			roles: tempMappedRoles,
			rawExternalGroups: rawExternalGroups,
			groupsToDelete: unmappedGroups,
			rolesToDelete: unmappedRoles
		};
		axios.post(apiRoutes.ssoAndScim(currentUser.organizationId), mappings, { headers: authHeader })
			.then(() => {
				setConfirmationDialogContent({ dialogOpen: false });
				handleResetScimWizardReduxState();
				navigate(pages.organizationManagement);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleResetScimWizardReduxState = () => {
		dispatch(setMappedGroups({}));
		dispatch(setMappedRoles({}));
		dispatch(setUnmappedGroups([]));
		dispatch(setUnmappedRoles([]));
		dispatch(setOrganizationName(""));
		dispatch(setRawExternalGroups([]));
		dispatch(setCoappGroups([]));
		dispatch(setCoappRoles([]));
	};

	const handleConfigurationComplete = () => {
		let dialogContent = {
			dialogOpen: true,
			confirmationMessage: "This will override any manual role and group assignments you've made in CoApp.",
			confirmationTitle: "Warning",
			confirmClickHandler: () => {
				handleConfigurationConfirmed();
			},
			cancelClickHandler: () => {
				handleClearCurrentConfiguration();
			}
		};
		setConfirmationDialogContent(dialogContent);
	};

	const handleNonMappedGroupsWarning = () => {
		let dialogContent = {
			dialogOpen: true,
			confirmationMessage: "It seems like you haven't connected all your CoApp groups to at least one IDP group. While the unmapped CoApp groups will still be there, all users in those groups won't be linked to them anymore. Are you sure you want to continue?",
			confirmationTitle: "Warning",
			confirmClickHandler: () => {
				handleNext();
				setConfirmationDialogContent({ dialogOpen: false });
			},
			cancelClickHandler: () => {
				setConfirmationDialogContent({ dialogOpen: false });
			}
		};
		setConfirmationDialogContent(dialogContent);
	};

	const handleNonMappedRolesWarning = () => {
		let dialogContent = {
			dialogOpen: true,
			confirmationMessage: "It seems like you haven't connected all your CoApp roles to at least one IDP group. While the unmapped CoApp roles will still be there, all users in those roles won't be linked to them anymore. Are you sure you want to continue?",
			confirmationTitle: "Warning",
			confirmClickHandler: () => {
				handleNext();
				setConfirmationDialogContent({ dialogOpen: false });
			},
			cancelClickHandler: () => {
				setConfirmationDialogContent({ dialogOpen: false });
			}
		};
		setConfirmationDialogContent(dialogContent);
	};


	const handleExitEditing = async () => {
		if (activeStep === 0) {
			handleClearCurrentConfiguration();
			return;
		}
		let dialogContent = {
			dialogOpen: true,
			confirmationMessage: "Are you sure you would like to exit? All progress made up until this point will be lost.",
			confirmationTitle: "Exit SSO+SCIM Configuration",
			confirmClickHandler: () => {
				handleClearCurrentConfiguration();
			},
			cancelClickHandler: () => {
				setConfirmationDialogContent({ dialogOpen: false });
			}
		};
		setConfirmationDialogContent(dialogContent);
	};

	/**
	* Step Progression
	*/
	const handleMoveUpOneStep = () => {
		setActiveStep((activeStep) => activeStep + 1);
	};

	const handleNext = async () => {
		switch (activeStep) {
			case 0:
				setStepsCompleted({ ...stepsCompleted, [`${activeStep}`]: true });
				handleMoveUpOneStep();
				break;
			case 1:
				setStepsCompleted({ ...stepsCompleted, [`${activeStep}`]: true });
				handleMoveUpOneStep();
				break;
			case 2:
				setStepsCompleted({ ...stepsCompleted, [`${activeStep}`]: true });
				handleMoveUpOneStep();
				break;
			case 3:
				setStepsCompleted({ ...stepsCompleted, [`${activeStep}`]: true });
				handleMoveUpOneStep();
				break;
			default:
				console.log("unknown case");
				break;
		}
		setTitleCount(0);
	};

	const initOrganization = () => {
		axios.get(apiRoutes.getOrganization(currentUser.organizationId), {
			headers: authHeader
		})
			.then((res) => {
				setTenantDetails({ secretScimKey: res.data.secretScimKey, tenantId: res.data.descopeTenantId });
				dispatch(setOrganizationName(res.data.name));
			})
			.catch(err => console.error(err));
	};

	useEffect(() => {
		initOrganization();
	}, []);

	return (
		<Fragment>
			<ScimWizardContainer>
				<form noValidate autoComplete="off">
					<div>
						<ScimWizardHeader title={steps[activeStep]["desc"]} count={titleCount} />
						{getStepContent(activeStep)}
					</div>
				</form>
			</ScimWizardContainer>

			<ScimWizardStepper
				activeStep={activeStep} setActiveStep={setActiveStep} stepsCompleted={stepsCompleted}
				handleSubmit={handleSubmit} handleBack={handleBack} handleExitEditing={handleExitEditing} steps={steps}
			/>
			<Dialog onClose={() => setWizardError("")} open={wizardError !== ""}>
				<Alert severity="error">{wizardError}</Alert>
			</Dialog>
			<CoAppConfirmationModal
				dialogOpen={confirmationDialogContent.dialogOpen}
				dialogTitle={confirmationDialogContent.confirmationTitle}
				dialogMessage={confirmationDialogContent.confirmationMessage}
				confirmClickHandler={confirmationDialogContent.confirmClickHandler}
				cancelClickHandler={confirmationDialogContent.cancelClickHandler}
				actionText={confirmationDialogContent.confirmationTitle === "Warning" ? "Confirm" : "Exit"}
			/>
		</Fragment>
	);
}
