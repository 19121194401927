import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Divider, Switch, Typography } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectNavState, setNavState } from "../../../../redux/navigationSlice";
import { selectPermissions, selectUserId, setPermissions } from "../../../../redux/userSlice";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../global/components/modals/coapp-edit-name-modal";

import { PermissionGroupContainer, PermissionsContainer } from "./styled/role.styled";

export default function RolePermissions() {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { roleId } = useParams();
	const { state } = useLocation();
	const navState = useSelector(selectNavState);
	const currentUserPermissions = useSelector(selectPermissions);
	const currentUserId = useSelector(selectUserId);
	const [role, setRole] = useState({});
	const [rolePermissions, setRolePermissions] = useState([]);
	const [permissionSeed, setPermissionSeed] = useState("");
	const [deleteRoleDialogOpen, setDeleteRoleDialogOpen] = useState(false);
	const [editRoleDialogOpen, setEditRoleDialogOpen] = useState(false);
	const [roleNameInput, setRoleNameInput] = useState("");
	const [roleNameError, setRoleNameError] = useState("");
	const [manageUsersWarningDialogOpen, setManageUsersWarningDialogOpen] = useState(false);

	const init = () => {
		if (roleId) {
			axios.get(apiRoutes.getRoleById(roleId), { headers: authHeader })
				.then((response) => {
					setRole(response.data);
					axios.get(apiRoutes.getPermissions, { headers: authHeader })
						.then((res) => {
							res.data.forEach(permission => {
								response.data.permissions.forEach(rolePermission => {
									if (rolePermission.name === permission.name) {
										permission.active = true;
									}
								});
							});
							setRolePermissions(res.data);
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const toggleDeleteRoleDialog = () => {
		setDeleteRoleDialogOpen(!deleteRoleDialogOpen);
	};

	const deleteRole = () => {
		axios.delete(apiRoutes.deleteRole + "/" + roleId, {
			headers: authHeader
		}).then((res) => {
			if (res.status === 200) {
				updateNavigation(true);
				dispatch(setMessage(messages.ROLE_DELETION_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				navigate(pages.roleManagement);
			} else {
				console.log(res);
				dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			}
			toggleDeleteRoleDialog();
		}).catch((err) => {
			console.log(err);
			dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
			toggleDeleteRoleDialog();
		});
	};

	const toggleEditRoleDialog = () => {
		setEditRoleDialogOpen(!editRoleDialogOpen);
		setRoleNameInput("");
		setRoleNameError("");
	};

	const handleRoleNameChange = (e) => {
		setRoleNameInput(e.target.value);
		setRoleNameError("");
	};

	const handleRoleNameChangeSubmit = (e) => {
		if (!roleNameInput) return;

		if (roleNameInput === state.name) {
			toggleEditRoleDialog();
			return;
		}

		axios.put(apiRoutes.updateRole(roleId), { name: roleNameInput }, { headers: authHeader })
			.then(() => {
				toggleEditRoleDialog();
				updateNavigation();
				dispatch(setMessage(messages.ROLE_NAME_UPDATED_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setRoleNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Role"));
					return;
				}
				toggleEditRoleDialog();
				dispatch(setMessage(messages.ROLE_NAME_UPDATE_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const updateNavigation = (isDeleteEvent = false) => {
		const oldNavState = [...navState];
		if (!isDeleteEvent) {
			let copyGroupChildren = [...oldNavState[8].children].slice(1); //update nav state to reflect group name update
			copyGroupChildren.unshift({
				id: Math.random(),
				name: roleNameInput,
				children: [],
				isGroupingLabel: true
			});

			oldNavState[8] = {
				...oldNavState[8],
				children: copyGroupChildren,
				isChangeEvent: true,
				isStillSelected: true,
				selectedResource: "Permissions",
				isNameChangeEvent: true
			};
		} else {
			oldNavState[8] = {
				...oldNavState[8],
				children: [],
				isStillSelected: true,
				selectedResource: ""
			};
		}
		dispatch(setNavState(oldNavState));
	};

	const handleCurrentUserPermissionUpdate = () => {
		axios.get(apiRoutes.getUser(1, currentUserId, "", null), { headers: authHeader })
			.then(res => {
				dispatch(setPermissions(res.data.permissions));
			})
			.catch(err => console.log(err));
	};

	const togglePermission = (permission, bypassCheck = true) => {
		if (permission?.name?.includes("MANAGE_USERS") && permission?.active && !bypassCheck) {
			const countOfManageUsersPermissions = currentUserPermissions.filter(p => p.includes("MANAGE_USERS")).length;
			if (countOfManageUsersPermissions === 1) {
				setManageUsersWarningDialogOpen(true);
				return;
			}
		}
		//permission variable will be an empty object when user wants to proceed with manage users 
		//permission removal, so we need to find the permission object to pass to our services
		if (Object.keys(permission).length === 0) {
			//filter permissions to extract manage users permission
			const manageUsersPermission = rolePermissions.filter(p => p.name.includes("MANAGE_USERS"));
			permission = manageUsersPermission[0];
		}
		axios.put(apiRoutes.updateRolePermission(roleId, permission.id), { active: !permission.active }, { headers: authHeader })
			.then((res) => {
				if (res.status === 200) {
					dispatch(setMessage(permission.active ? messages.PERMISSION_REMOVED_SUCCESS_MSG : messages.PERMISSION_ADDED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
					setPermissionSeed(Math.random());
					//TODO:: placeholder, update navigate to expected destination once determined
					if (permission.name.includes("MANAGE_USERS") && manageUsersWarningDialogOpen) {
						navigate(pages.home);
					}

					const currentUserIsInCurrentRolesUsers = role.users.filter(user => user.id === currentUserId).length > 0;
					if (currentUserIsInCurrentRolesUsers) {
						handleCurrentUserPermissionUpdate();
					}
				} else {
					dispatch(setMessage(permission.active ? messages.PERMISSION_REMOVED_ERROR_MSG : messages.PERMISSION_ADDED_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(permission.active ? messages.PERMISSION_REMOVED_ERROR_MSG : messages.PERMISSION_ADDED_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	useEffect(() => {
		init();
	}, [permissionSeed, state.name]);

	const groupedPermissions = {};
	rolePermissions.forEach(permission => {
		if (!groupedPermissions[permission.category]) {
			groupedPermissions[permission.category] = [];
		}
		groupedPermissions[permission.category].push(permission);
	});
	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} Permissions Management`}
				resourceType="Role"
				subResourceType="Permissions"
				addIsPresent={false}
				editIsPresent={true}
				editOnClickHandler={toggleEditRoleDialog}
				deleteIsPresent={true}
				deleteOnClickHandler={toggleDeleteRoleDialog}
				hasDescription={true}
				description={`Manage permissions for ${state.name}.`}
			/>
			<PermissionsContainer>
				{Object.keys(groupedPermissions).map((category, categoryIndex) => (
					<div key={category}>
						{categoryIndex !== 0 && <Divider sx={{ width: "60%", margin: "25px 0px 0px 25px" }} />}
						<Typography sx={{ margin: "25px" }} variant="h3">{category}</Typography>
						{groupedPermissions[category].map((permission, index) => (
							<PermissionGroupContainer key={index}>
								<Typography variant="h3" sx={{ display: "inline" }}>
									<Switch sx={{ marginRight: "5px" }} checked={permission.active} onChange={() => togglePermission(permission, false)} />
									{permission.friendlyName}
								</Typography>
								<Typography sx={{ marginLeft: "15px", paddingBottom: categoryIndex === Object.keys(groupedPermissions).length - 1 ? "20px" : "0px" }} variant="body2">{permission.description}</Typography>
							</PermissionGroupContainer>
						))}
					</div>
				))}
			</PermissionsContainer>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteRoleDialogOpen}
				dialogTitle={`Delete ${state.name} role?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "role")}
				confirmClickHandler={deleteRole}
				cancelClickHandler={toggleDeleteRoleDialog}
				actionText="Delete"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={manageUsersWarningDialogOpen}
				dialogTitle="Warning: You will no longer be able to manage permissions"
				dialogMessage={messages.REMOVE_MANAGE_USERS_ROLE_MSG(state.name)}
				confirmClickHandler={() => togglePermission({})}
				cancelClickHandler={() => setManageUsersWarningDialogOpen(false)}
				actionText="Proceed"
			/>
			<CoAppEditNameModal
				dialogOpen={editRoleDialogOpen}
				dialogTitle="Edit role name"
				changeHandler={handleRoleNameChange}
				placeholderText={state.name}
				confirmClickHandler={handleRoleNameChangeSubmit}
				cancelClickHandler={toggleEditRoleDialog}
				actionText="Save"
				editNameError={roleNameError}
				setEditNameError={setRoleNameError}
			/>
		</>
	);
}