import { useEffect } from "react";
import { Alert, Dialog, Step, StepButton, StepLabel, Stepper, Tooltip } from "@mui/material";

import { CoAppActionButton, CoAppCancelTextButton } from "../../global/styled/global.styled";
import SaveProgressIndicator from "../rules/save-progress-indicator";
import { WizardStepConnector, WizardStepIconRoot, WizardStepperContainer } from "../styled/rule-wizard.styled";

function WizardStepIcon(props) {
	const { active, completed, className } = props;
	if (completed) {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-completedIcon" />
			</WizardStepIconRoot>);
	} else if (active && !completed) {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle-active-incomplete" />
			</WizardStepIconRoot>
		);
	} else if (!active && !completed) {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle-inactive-incomplete" />
			</WizardStepIconRoot>
		);
	} else {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle" />
			</WizardStepIconRoot>
		);
	}
}

export default function WizardStepper(props) {
	const activeStep = props.activeStep;
	const steps = props.steps;
	const stepsCompleted = props.stepsCompleted;
	const handleStepButtonClick = props.handleStepButtonClick;
	const handleSubmit = props.handleSubmit;
	const handleBack = props.handleBack;
	const handleExitEditing = props.handleExitEditing;
	const isSavingProgress = props.isSavingProgress;
	const wizardFormError = props.wizardFormError;
	const setWizardFormError = props.setWizardFormError;

	useEffect(() => {
	}, [isSavingProgress]);

	return (
		<WizardStepperContainer>
			<CoAppCancelTextButton onClick={handleExitEditing}>Exit</CoAppCancelTextButton>
			<Stepper connector={<WizardStepConnector />} alternativeLabel nonLinear activeStep={activeStep} sx={{ width: "50%" }}>
				{steps.map(step => (
					<Step completed={stepsCompleted[step.num - 1]} key={step.num}>
						<Tooltip title={step.desc} placement="top">
							<StepButton onClick={() => handleStepButtonClick(step.num)}>
								<StepLabel StepIconComponent={WizardStepIcon} />
							</StepButton>
						</Tooltip>
					</Step>
				))}
			</Stepper>


			<CoAppCancelTextButton onClick={handleBack} disabled={activeStep === 0}>
				Back
			</CoAppCancelTextButton>

			{activeStep === 4 ? (
				<CoAppActionButton onClick={handleSubmit}>
					Finish
				</CoAppActionButton>
			) : (
				<CoAppActionButton onClick={handleSubmit}>
					Next
				</CoAppActionButton>
			)}
			<SaveProgressIndicator activeStep={activeStep} isSavingProgress={isSavingProgress} />
			<Dialog onClose={() => setWizardFormError("")} open={wizardFormError !== ""}>
				<Alert severity="error">{wizardFormError}</Alert>
			</Dialog>
		</WizardStepperContainer>
	);
}