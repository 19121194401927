import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Switch } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectNavState, setNavState } from "../../../../redux/navigationSlice";
import { selectOrganizationId } from "../../../../redux/userSlice";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridNameCell from "../../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/coapp-standard-datagrid";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../global/components/modals/coapp-edit-name-modal";

export default function GroupPlugins() {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const organizationId = useSelector(selectOrganizationId);
	const navState = useSelector(selectNavState);
	const { groupId } = useParams();
	const { state } = useLocation();

	const [groupPlugins, setGroupPlugins] = useState([]);
	const [editGroupIsToggled, setEditGroupIsToggled] = useState(false);
	const [deleteGroupIsToggled, setDeleteGroupIsToggled] = useState(false);
	const [groupNameInput, setGroupNameInput] = useState("");
	const [groupNameError, setGroupNameError] = useState("");

	const columns = [
		{
			field: "name",
			headerName: "Name",
			editable: false,
			flex: .5,
			renderCell: (params) => (
				<CoAppDataGridNameCell item={params.row} />
			),
		},

		{
			field: "status",
			headerName: "Status",
			editable: false,
			flex: 0.5,
			renderCell: (params) => (
				<Switch
					checked={params.row.activeForGroup}
					disabled={params.row.active ? false : true}
					onChange={() => deactivatePlugin(params.row)}
				/>
			),
		},
	];

	const deactivatePlugin = (plugin) => {
		axios.put(apiRoutes.updateGroupPlugin(groupId, plugin.id), { active: !plugin.activeForGroup }, { headers: authHeader })
			.then(() => {
				init();
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(messages.GROUP_PLUGIN_TOGGLE_SUCCESS_MSG));
			})
			.catch((error) => {
				console.log(error);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.GROUP_PLUGIN_TOGGLE_ERROR_MSG));
			});
	};

	const handleToggleGroupEditDialog = () => {
		setEditGroupIsToggled(!editGroupIsToggled);
		setGroupNameError("");
		setGroupNameInput("");
	};

	const handleGroupNameChange = (e) => {
		setGroupNameInput(e.target.value);
		setGroupNameError("");
	};

	const handleGroupNameChangeSubmit = () => {
		if (!groupNameInput) return;

		if (groupNameInput === state.name) {
			handleToggleGroupEditDialog();
			return;
		}

		axios.put(apiRoutes.updateGroup(groupId), { name: groupNameInput }, { headers: authHeader })
			.then(() => {
				setEditGroupIsToggled(false);
				updateNavigation();
				dispatch(setMessage(messages.GROUP_NAME_UPDATED_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setGroupNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Group"));
					return;
				}
				handleToggleGroupEditDialog();
				dispatch(setMessage(messages.GROUP_NAME_UPDATE_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const updateNavigation = (isDeleteEvent = false) => {
		const oldNavState = [...navState];
		if (!isDeleteEvent) {
			let copyGroupChildren = [...oldNavState[7].children].slice(1); //update nav state to reflect group name update
			copyGroupChildren.unshift({
				id: Math.random(),
				name: groupNameInput,
				children: [],
				isGroupingLabel: true
			});

			oldNavState[7] = {
				...oldNavState[7],
				children: copyGroupChildren,
				isChangeEvent: true,
				isStillSelected: true,
				selectedResource: "Plugins",
				isNameChangeEvent: true
			};
		} else {
			oldNavState[7] = {
				...oldNavState[7],
				children: [],
				isStillSelected: true,
				resourseSelected: ""
			};
		}
		dispatch(setNavState(oldNavState));
	};

	const handleToggleDeleteGroupDialog = () => {
		setDeleteGroupIsToggled(!deleteGroupIsToggled);
	};

	const confirmGroupDeletion = () => {
		axios.delete(apiRoutes.deleteGroup + "/" + groupId, {
			headers: authHeader
		}).then((res) => {
			if (res.status === 200) {
				dispatch(setMessage(messages.GROUP_DELETED_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				updateNavigation(true);
				navigate(pages.groupManagement);
			} else {
				console.log(res);
				dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			}
			handleToggleDeleteGroupDialog();
		}).catch((err) => {
			console.log(err);
			dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
			handleToggleDeleteGroupDialog();
		});
	};

	const handleOrganizationalPluginRetrieval = async () => {
		let plugins = [];
		await axios.get(apiRoutes.getPlugins(organizationId), { headers: authHeader })
			.then((response) => {
				if (response.data.length === 0) return;

				const platforms = response.data;

				platforms.forEach(platform => {
					if (!platform.plugins) {
						return;
					}
					platform.plugins.forEach(plugin => {
						if (!plugin.active) return;
						plugins.push({
							id: plugin.id,
							name: plugin.name,
							groups: plugin.groups.map(group => group.name),
							active: plugin.active,
							platformId: platform.id,
						});
					});
				});
			})
			.catch((error) => {
				console.log(error);
			});
		return plugins;
	};

	const init = async () => {
		await axios.get(apiRoutes.getGroupPlugins(groupId), { headers: authHeader })
			.then(async (response) => {
				let orgPlugins = await handleOrganizationalPluginRetrieval();
				let plugins = orgPlugins.map(orgPlugin => {
					const groupPlugin = response.data.find(groupPlugin => groupPlugin.id === orgPlugin.id);
					return {
						...orgPlugin,
						activeForGroup: groupPlugin === undefined ? false : groupPlugin.active
					};

				});
				plugins = plugins.sort((a, b) => a.name.localeCompare(b.name));
				setGroupPlugins(plugins);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		init();
	}, [state.name]);

	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} Plugin Management`}
				subResourceType="Plugin"
				resourceType="Group"
				addIsPresent={false}
				editIsPresent={true}
				editOnClickHandler={handleToggleGroupEditDialog}
				deleteIsPresent={true}
				deleteOnClickHandler={handleToggleDeleteGroupDialog}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={groupPlugins}
				pinnedColumns={[]}
				allowSelection={false}
				targetResource="plugins"
				parentResource="group"
				parentResourceName={state.name}
				bulkDestructiveText="Remove"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteGroupIsToggled}
				dialogTitle={`Delete ${state.name} group?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "group")}
				confirmClickHandler={confirmGroupDeletion}
				cancelClickHandler={handleToggleDeleteGroupDialog}
				actionText="Delete"
			/>
			<CoAppEditNameModal
				dialogOpen={editGroupIsToggled}
				dialogTitle="Edit group name"
				changeHandler={handleGroupNameChange}
				placeholderText={state.name}
				confirmClickHandler={handleGroupNameChangeSubmit}
				cancelClickHandler={handleToggleGroupEditDialog}
				actionText="Save"
				editNameError={groupNameError}
				setEditNameError={setGroupNameError}
			/>
		</>
	);
}