import { Outlet } from "react-router";

import { CoAppMain } from "./components/global/styled/global.styled";
import ResponsiveAppBar from "./components/header";
import CoAppNavDrawer from "./components/navigation/coapp-nav-drawer";

const WithNavRoute = () => {
	return (
		<>
			<ResponsiveAppBar />
			<CoAppNavDrawer />
			<CoAppMain>
				<Outlet />
			</CoAppMain>
		</>
	);
};

export default WithNavRoute;