import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add, Delete, Link, LinkOff, Search } from "@mui/icons-material";
import { Checkbox, checkboxClasses, FormControl, InputAdornment, ListSubheader, MenuItem } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../../constants/api-routes";
import messageLevels from "../../../../../constants/message-levels";
import messages from "../../../../../constants/messages";
import useAuthHeader from "../../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../../redux/alertSlice";
import { selectMappedGroups, setCoappGroups, setMappedGroups, setRawExternalGroups } from "../../../../../redux/scimWizardSlice";
import { selectUser } from "../../../../../redux/userSlice";
import CoAppIconTextButton from "../../../../global/components/inputs/coapp-icon-text-button";
import CoAppTextField from "../../../../global/components/inputs/coapp-textfield";
import CoAppDestructiveConfirmationModal from "../../../../global/components/modals/coapp-destructive-confirmation-modal";
import { CoAppActionButton, CoAppTextButton } from "../../../../global/styled/global.styled";
import {
	ScimWizardGroupConnector,
	ScimWizardInputLabel,
	ScimWizardMultiSelectWithSearch,
	ScimWizardStack, ScimWizardStackItem, ScimWizardStackItemContent,
	ScimWizardStackSearchField,
	ScimWizardStackTextItemContent,
	ScimWizardStepContainer
} from "../styled/scim-wizard.styled";

const containsText = (text, searchText) =>
	text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function Step3(props) {

	const authHeader = useAuthHeader();
	const currentUser = useSelector(selectUser);
	const mappedGroups = useSelector(selectMappedGroups);
	const dispatch = useDispatch();

	const [groupsLoaded, setGroupsLoaded] = useState(false);
	const [idpGroupsLoaded, setIdpGroupsLoaded] = useState(false);
	const [groups, setGroups] = useState([]);
	const [idpGroups, setIdpGroups] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [isAddingGroup, setIsAddingGroup] = useState(false);
	const [newGroupName, setNewGroupName] = useState("");
	const [groupListSeed, setGroupListSeed] = useState(null);
	const [confirmationDialogContent, setConfirmationDialogContent] = useState({ dialogOpen: false });

	const initGroups = () => {
		axios.get(apiRoutes.getGroups, {
			headers: authHeader
		})
			.then((res) => {
				setGroups(res.data);
				dispatch(setCoappGroups(res.data));
				let tempObj = {};
				for (let g in res.data) {
					tempObj[res.data[g].name] = [];
				}
				dispatch(setMappedGroups(Object.keys(mappedGroups).length > 0 ? mappedGroups : tempObj));
				props.setTitleCount(res.data.length);
				setGroupsLoaded(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const initIdpGroups = () => {
		axios.get(apiRoutes.getExternalGroups(currentUser.organizationId), {
			headers: authHeader
		})
			.then((res) => {
				setIdpGroups(res.data);
				setIdpGroupsLoaded(true);
				dispatch(setRawExternalGroups(res.data));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDropdownSelectionChange = (e, coappGroupName) => {
		let temp = { ...mappedGroups };
		temp[`${coappGroupName}`] = e.target.value;
		dispatch(setMappedGroups(temp));
	};

	const handleCreateGroupOpen = () => {
		setIsAddingGroup(true);
	};

	const handleGroupCreation = () => {
		let groupJSON = {
			"groupName": newGroupName
		};
		axios.post(apiRoutes.createGroup, groupJSON, {
			headers: authHeader
		})
			.then(() => {
				let temp = { ...mappedGroups };
				temp[`${newGroupName}`] = [];
				dispatch(setMappedGroups(temp));
				handleGroupActionAlert("success");
			})
			.catch(err => {
				console.error(err);
				handleGroupActionAlert("error");
			});
	};

	const deleteGroup = (groupName, groupId) => {
		axios.delete(apiRoutes.deleteGroup + "/" + groupId, {
			headers: authHeader
		}).then(() => {
			let tempMappedGroups = { ...mappedGroups };
			delete tempMappedGroups[`${groupName}`];
			dispatch(setMappedGroups(tempMappedGroups));
			handleGroupActionAlert("success", "deletion");
			setConfirmationDialogContent({ dialogOpen: false });
		}).catch((err) => {
			handleGroupActionAlert("error", "deletion");
			console.log(err);
		});
	};

	const handleGroupDeletion = (groupName, groupId) => {
		let dialogContent = {
			dialogOpen: true,
			confirmationMessage: "Are you sure you would like to delete this group?",
			confirmationTitle: `Delete "${groupName}"`,
			confirmClickHandler: () => {
				let tempGroups = [...groups];
				tempGroups = tempGroups.filter((group) => group.name !== groupName);
				setGroups(tempGroups);
				deleteGroup(groupName, groupId);
			},
			cancelClickHandler: () => {
				setConfirmationDialogContent({ dialogOpen: false });
			}
		};
		setConfirmationDialogContent(dialogContent);
	};

	const handleGroupActionAlert = (level, eventType = "creation") => {
		setIsAddingGroup(false);
		setGroupListSeed(Math.random());
		dispatch(setLevel(level));
		if (eventType === "creation") {
			dispatch(setMessage(level === "success" ? messages.GROUP_CREATED_SUCCESS_MSG : messages.GROUP_CREATION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(level === "success" ? messageLevels.SUCCESS : messageLevels.ERROR));
		} else {
			dispatch(setMessage(level === "success" ? messages.GROUP_DELETED_SUCCESS_MSG : messages.GROUP_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(level === "success" ? messageLevels.SUCCESS : messageLevels.ERROR));
		}
		dispatch(setOpen(true));
	};

	useEffect(() => {
		initGroups();
		initIdpGroups();
	}, [groupListSeed]);

	if (!groupsLoaded && !idpGroupsLoaded) {
		return (
			<div>
				Loading...
			</div>
		);
	} else {
		return (
			<ScimWizardStepContainer>
				<ScimWizardStack>
					<ScimWizardStackItem key={"stack-header"} itemtype={"header"}>
						<ScimWizardStackItemContent>CoApp Groups</ScimWizardStackItemContent>
						<ScimWizardStackItemContent itemtype={"header"}>IdP Groups</ScimWizardStackItemContent>
					</ScimWizardStackItem>
					{
						groups.map((group) => {
							return (
								<ScimWizardStackItem key={group.id}>
									<ScimWizardStackTextItemContent title={group.name}>{group.name}</ScimWizardStackTextItemContent>
									{
										mappedGroups[`${group.name}`].length === 0
											?
											<ScimWizardGroupConnector isconnected={false} title="Not Mapped"><LinkOff fontSize="medium" /></ScimWizardGroupConnector>
											:
											<ScimWizardGroupConnector isconnected={true} title="Mapped"><Link fontSize="medium" /></ScimWizardGroupConnector>

									}
									<ScimWizardStackItemContent>
										<FormControl size="small">
											{
												mappedGroups[`${group.name}`].length === 0
													?
													<ScimWizardInputLabel id="groups">Select Idp Groups</ScimWizardInputLabel>
													:
													null
											}
											<ScimWizardMultiSelectWithSearch
												value={mappedGroups[`${group.name}`]}
												label="Select Groups"
												labelId="groups"
												MenuProps={{ autoFocus: false }}
												multiple
												renderValue={(selected) => selected.join(", ")}
												onChange={(e) => handleDropdownSelectionChange(e, group.name)}
												onClose={() => setSearchText("")}
											>
												<ListSubheader>
													<ScimWizardStackSearchField
														size="small"
														autoFocus
														placeholder="Find Group"
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<Search />
																</InputAdornment>
															)
														}}
														onChange={(e) => setSearchText(e.target.value)}
														onKeyDown={(e) => {
															e.stopPropagation();
														}}
													/>
												</ListSubheader>
												{
													idpGroups.filter((option) => containsText(option.externalGroupName, searchText)).map((option, index) => (
														<MenuItem key={index} value={option.externalGroupName}>
															<Checkbox checked={mappedGroups[`${group.name}`].indexOf(option.externalGroupName) > -1} sx={{
																[`&.${checkboxClasses.checked}`]: {
																	color: "#2FBD70",
																}
															}} />
															{option.externalGroupName}
														</MenuItem>
													))
												}
											</ScimWizardMultiSelectWithSearch>
										</FormControl>
									</ScimWizardStackItemContent>
									<CoAppIconTextButton
										onClick={() => handleGroupDeletion(group.name, group.id)}
										text="Delete"
										icon={<Delete fontSize="small" />}
									/>
								</ScimWizardStackItem>
							);
						})
					}
					<ScimWizardStackItem key={"stack-footer"} itemtype={"footer"}>
						{
							isAddingGroup ?
								<ScimWizardStackItemContent sx={{ marginTop: "10px", marginBottom: "2px" }}>
									<CoAppTextField
										size="small"
										sx={{ height: "18px", marginRight: "15px", marginTop: "0px" }}
										label="Group Name"
										onChange={(e) => setNewGroupName(e.target.value)}
									/>
									<CoAppActionButton sx={{ height: "30px", marginTop: "5px" }} size="small" onClick={handleGroupCreation}>Create group</CoAppActionButton>
									<CoAppTextButton sx={{ height: "30px", marginTop: "5px" }} size="small" onClick={() => setIsAddingGroup(false)}>Cancel</CoAppTextButton>
								</ScimWizardStackItemContent>
								:
								<CoAppIconTextButton icon={<Add fontSize="small" />} text="Add new group" onClick={handleCreateGroupOpen} />
						}
					</ScimWizardStackItem>
				</ScimWizardStack>
				<CoAppDestructiveConfirmationModal
					dialogOpen={confirmationDialogContent.dialogOpen}
					dialogTitle={confirmationDialogContent.confirmationTitle}
					dialogMessage={confirmationDialogContent.confirmationMessage}
					confirmClickHandler={confirmationDialogContent.confirmClickHandler}
					cancelClickHandler={confirmationDialogContent.cancelClickHandler}
					actionText="Delete"
				/>
			</ScimWizardStepContainer>
		);
	}
}