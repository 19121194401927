import { useEffect, useState } from "react";

import { WizardSavedProgressIndicator } from "../styled/rule-wizard.styled";

export default function SaveProgressIndicator({ activeStep, isSavingProgress }) {
	const [lastSavedIndicator, setLastSavedIndicator] = useState(null);

	const calculateLastSave = (numInMs) => {
		if (numInMs <= 1000) {
			return "Saved less than a second ago";
		} else if (numInMs >= 1000 && numInMs <= 60000) {
			return `Saved ${Math.round(numInMs / 1000)} seconds ago`;
		} else if (numInMs >= 60000) {
			if (Math.round(numInMs / 60000) === 1) {
				return `Saved ${Math.round(numInMs / 60000)} minute ago`;
			} else {
				return `Saved ${Math.round(numInMs / 60000)} minutes ago`;
			}
		}
	};

	useEffect(() => {
		const interval = setInterval(() => setLastSavedIndicator(calculateLastSave(new Date() - isSavingProgress.lastSaved)), 1000);
		return () => {
			clearInterval(interval);
		};
	}, [lastSavedIndicator, isSavingProgress]);

	return (
		(
			<WizardSavedProgressIndicator>{activeStep === 0 && isSavingProgress.lastSaved === null ?
				"No progress saved yet..." : calculateLastSave(new Date() - isSavingProgress.lastSaved)}</WizardSavedProgressIndicator>
		)
	);
}