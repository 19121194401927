import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectOrganizationId } from "../../../../redux/userSlice";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../../../global/components/datagrid/coapp-datagrid-list-cell";
import CoAppDataGridNameCell from "../../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/coapp-standard-datagrid";
import CoAppAddItemsToListModal from "../../../global/components/modals/coapp-add-items-to-list-modal";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import ThreeDotMenu from "../../../global/components/three-dot-menu";
import { CoAppMainContainer } from "../../../global/styled/global.styled";
import { SubMenuContainer } from "../../styled/settings.styled";

export default function PluginGroups() {
	const authHeader = useAuthHeader();
	const { pluginId } = useParams();
	const { state } = useLocation();
	const organizationId = useSelector(selectOrganizationId);
	const dispatch = useDispatch();
	const currentPlugin = state.plugin ? state.plugin : state;

	const [pluginGroups, setPluginGroups] = useState([]);
	const [pluginActive, setPluginActive] = useState(state.plugin ? state.plugin.active : state.active);
	const [removeGroupDialogOpen, setRemoveGroupDialogOpen] = useState(false);
	const [groupBeingRemoved, setGroupBeingRemoved] = useState({});
	const [addGroupsOpen, setAddGroupsOpen] = useState(false);
	const [groupsToAdd, setGroupsToAdd] = useState([]);
	const [availableGroups, setAvailableGroups] = useState([]);

	const columns = [
		{
			field: "name",
			headerName: "Name",
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridNameCell item={params.row} />
			)
		},
		{
			field: "users",
			headerName: "Users",
			editable: true,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value} resource="Users" isUser={true} />
			)
		},
		{
			field: "actions",
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<div>
					<ThreeDotMenu
						options={[
							{
								name: "Remove",
								optionClickHandler: () => {
									handleOpenRemoveGroupConfirmDialog(params.row);
								}
							},
						]}
					/>
				</div>
			),
		},
	];

	const initPluginGroups = () => {
		axios.get(apiRoutes.getGroupsForPlugin(pluginId), { headers: authHeader })
			.then((response) => {
				setPluginGroups(response.data);
			})
			.catch((error) => {
				console.error("Error fetching plugin groups: ", error);
			});
	};

	const handlePluginStatusToggleChange = () => {
		axios.put(apiRoutes.togglePluginStatusForOrganization(organizationId, currentPlugin.platformId, pluginId), {}, { headers: authHeader })
			.then(() => {
				setPluginActive(!pluginActive);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(pluginActive ? messages.PLUGIN_DISABLED_SUCCESS_MSG : messages.PLUGIN_ENABLED_SUCCESS_MSG));
			})
			.catch((error) => {
				console.error(error);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(pluginActive ? messages.PLUGIN_DISABLED_ERROR_MSG : messages.PLUGIN_ENABLED_ERROR_MSG));
			});
	};

	const handleRemoveGroupFromPlugin = () => {
		axios.delete(apiRoutes.removePluginFromGroup(groupBeingRemoved.id, pluginId), { headers: authHeader })
			.then(() => {
				setRemoveGroupDialogOpen(false);
				initPluginGroups();
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(messages.GROUP_REMOVAL_SUCCESS_MSG));
			})
			.catch((err) => {
				console.error(err);
				setRemoveGroupDialogOpen(false);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.GROUP_REMOVAL_ERROR_MSG));
			});
	};

	const handleOpenRemoveGroupConfirmDialog = (group) => {
		setRemoveGroupDialogOpen(true);
		setGroupBeingRemoved(group);
	};

	const handleCloseRemoveGroupConfirmDialog = () => {
		setRemoveGroupDialogOpen(false);
		setGroupBeingRemoved({});
	};

	const handleAddGroupsToPlugin = () => {
		let postBody = {
			groups: groupsToAdd.map(group => group.id),
			pluginActiveStatus: pluginActive
		};
		axios.post(apiRoutes.addGroupsToPlugin(pluginId), postBody, { headers: authHeader })
			.then(() => {
				setAddGroupsOpen(false);
				setAvailableGroups([]);
				setGroupsToAdd([]);
				initPluginGroups();
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(messages.GROUP_ADD_TO_PLUGIN_SUCCESS_MSG(groupsToAdd.length, currentPlugin.name)));
			})
			.catch((err) => {
				console.error(err);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.GROUP_ADD_TO_PLUGIN_ERROR_MSG(groupsToAdd.length, currentPlugin.name)));
			});
	};

	const handleAddGroupsToGroupsToAdd = (value) => {
		//value is an array of group names, we need to convert them to group objects to display group name properly
		value = value.map((group) => {
			let tempArr = [...pluginGroups, ...availableGroups];
			let foundGroup = tempArr.find((row) => row.name === group);
			return foundGroup;
		});
		setGroupsToAdd(value);
	};

	const handleOpenAddGroupsDialog = () => {
		axios.get(apiRoutes.getGroups, { headers: authHeader })
			.then((response) => {
				let currentGroups = pluginGroups.map(group => group.id);
				let availableGroups = response.data.filter(group => !currentGroups.includes(group.id));
				setAvailableGroups(availableGroups);
				setAddGroupsOpen(true);
			})
			.catch((error) => {
				console.error("Error fetching groups: ", error);
			});
	};

	const handleAddGroupsDialogToggle = () => {
		setAddGroupsOpen(!addGroupsOpen);
		setAvailableGroups([]);
		setGroupsToAdd([]);
	};

	useEffect(() => {
		initPluginGroups();
	}, []);

	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} Plugin Management`}
				resourceType="Plugin"
				subResourceType="Group"
				addIsPresent={true}
				addOnClickHandler={handleOpenAddGroupsDialog}
				editIsPresent={false}
				deleteIsPresent={false}
			/>
			<CoAppMainContainer sx={{ p: 2 }}>
				<SubMenuContainer>
					<Typography variant="h1">{currentPlugin.name}</Typography>
					<FormControlLabel
						control={
							<Switch
								checked={pluginActive}
								onClick={handlePluginStatusToggleChange}
							/>
						}
						sx={{ pointerEvents: "none" }}
						label={<Typography variant="body3" sx={{ fontSize: "12px", pt: "2px", pl: "2px" }}>{pluginActive ? "Active" : "Inactive"}</Typography>}
						labelPlacement="start"
					/>

				</SubMenuContainer>
				<CoAppStandardDataGrid
					columns={columns}
					rows={pluginGroups}
					pinnedColumns={["actions"]}
					allowSelection={true}
					targetResource="groups"
					parentResource="plugin"
					parentResourceName={currentPlugin.name}
					bulkDestructiveText="Remove"
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={removeGroupDialogOpen}
					dialogTitle={`Remove ${groupBeingRemoved.name} from ${currentPlugin.name}?`}
					dialogMessage={messages.REMOVAL_CONFIRMATION_MSG(currentPlugin.name, groupBeingRemoved.name, "plugin", "group")}
					cancelClickHandler={handleCloseRemoveGroupConfirmDialog}
					confirmClickHandler={handleRemoveGroupFromPlugin}
					actionText="Remove"
				/>
				<CoAppAddItemsToListModal
					addButtonTitle="Save"
					inputLabel="Group(s)"
					addItemsIsToggled={addGroupsOpen}
					addItemsToCollection={handleAddGroupsToPlugin}
					addItemsToNewArray={handleAddGroupsToGroupsToAdd}
					dialogTitle={`Add Group(s) to ${currentPlugin.name} plugin`}
					items={availableGroups}
					itemsToAdd={groupsToAdd}
					toggleDialog={handleAddGroupsDialogToggle}
				/>
			</CoAppMainContainer >
			<CoAppAddItemsToListModal
				addButtonTitle="Save"
				inputLabel="Group(s)"
				addItemsIsToggled={addGroupsOpen}
				addItemsToCollection={handleAddGroupsToPlugin}
				addItemsToNewArray={handleAddGroupsToGroupsToAdd}
				dialogTitle={`Add Group(s) to ${currentPlugin.name} plugin`}
				items={availableGroups}
				itemsToAdd={groupsToAdd}
				toggleDialog={handleAddGroupsDialogToggle}
			/>
		</>
	);
}