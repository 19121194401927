import { useEffect, useMemo, useState } from "react";
import { Delete, FilterAlt, Restore } from "@mui/icons-material";
import { Grid, IconButton, Typography, useTheme } from "@mui/material";
import { GridPagination, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-premium";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import messages from "../../constants/messages";
import useAuthHeader from "../../helpers/useAuthHeader";
import CoAppDataGridDateTimeCell from "../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDataGridHeader from "../global/components/datagrid/coapp-datagrid-header";
import DataGridCustomNoRowsOverlay from "../global/components/datagrid/datagrid-custom-norows-overlay";
import CoAppDestructiveConfirmationModal from "../global/components/modals/coapp-destructive-confirmation-modal";
import {
	CoAppDataGrid, CoAppDataGridPaginationContainer, CoAppDataGridToolbar,
	CoAppLightTooltip
} from "../global/styled/global.styled";

import RuleDataGridLongTextCell from "./rule-datagrid-long-text-cell";
import RuleDataGridSelectionRow from "./rule-datagrid-selection-row";

const datagridInitialState = {
	filter: {
		filterModel: {
			items: [],
			quickFilterExcludeHiddenColumns: true,
		},
	},
	pagination: {
		paginationModel: { pageSize: 50, page: 0 },
	}
};

const getDifferenceInDays = (dateToCompare) => {
	let now = new Date();
	const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30));
	const diffInMs = Math.abs(thirtyDaysAgo - dateToCompare);
	return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
};

export default function RuleTrashDataGrid() {
	const theme = useTheme();
	const authHeader = useAuthHeader();
	const [selectionModel, setSelectionModel] = useState([]);
	const [rules, setRules] = useState([]);
	const [destructiveModalOpen, setDestructiveModalOpen] = useState(false);
	const [bulkDestructiveModalOpen, setBulkDestructiveModalOpen] = useState(false);
	const [ruleBeingDeleted, setRuleBeingDeleted] = useState({ name: "" });

	const handleRowSelectionChange = (newSelectionModel) => {
		setSelectionModel(newSelectionModel);
	};

	const handleDeleteRule = () => {
		axios.delete(apiRoutes.deleteRule + "/" + ruleBeingDeleted.id + "?isHardDelete=1", {
			headers: authHeader
		})
			.then(() => {
				//TODO:: add alert handling
				handleDeleteRuleToggle();
				initTrashLibrary();
			})
			.catch(err => {
				console.error(err);
			});
	};

	const handleRestoreRule = (ruleId) => {
		axios.put(apiRoutes.restoreRule + "/" + ruleId, {}, {
			headers: authHeader
		})
			.then(() => {
				//TODO:: add alert handling
				initTrashLibrary();
			})
			.catch(err => {
				console.error(err);
			});
	};

	const handleBulkRuleRestoration = () => {
		const restorationData = {
			ruleIds: selectionModel
		};

		axios.put(apiRoutes.bulkRestoreRules, restorationData, { headers: authHeader })
			.then(() => {
				//TODO:: add appropriate notification here during notification overhaul work
				initTrashLibrary();
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleBulkRuleDeletion = () => {
		const deletionData = {
			ruleIds: selectionModel
		};

		axios.delete(apiRoutes.bulkPermanentDeleteRules + "?isHardDelete=1", { headers: authHeader, data: deletionData })
			.then(() => {
				//TODO:: add appropriate notification here during notification overhaul work
				initTrashLibrary();
				setBulkDestructiveModalOpen(false);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const columns = [
		{
			field: "name",
			headerName: "Rule name",
			flex: 1,
			renderCell: (params) => (
				<RuleDataGridLongTextCell value={params.value} isTrashed={true} />
			)
		},
		{
			field: "description",
			headerName: "Description",
			flex: 1,
			renderCell: (params) => (
				<RuleDataGridLongTextCell value={params.value} isTrashed={true} />
			)
		},
		{
			field: "permanentDeletionIn",
			headerName: "Permanent deletion in",
			flex: 1,
			renderCell: (params) => {
				const diff = getDifferenceInDays(new Date(params.row.dateTimeTrashed));
				return (<Typography variant="body2" color="text.disabled">{`${diff === 1 ? `${diff} day` : `${diff} days`}`}</Typography>);
			},
			filterable: false
		},
		{
			field: "dateTimeTrashed",
			headerName: "Moved to trash",
			flex: 1,
			type: "dateTime",
			valueGetter: (params) => new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.value} isTrashed={true} />
			)
		},
		{
			field: "delete",
			type: "actions",
			flex: .15,
			renderCell: (params) => (
				<CoAppLightTooltip title="Permanently delete">
					<IconButton onClick={() => handleDeleteRuleToggle(params.row)}>
						<Delete sx={{ color: theme.palette.icon.red }} />
					</IconButton>
				</CoAppLightTooltip>
			)
		},
		{
			field: "restore",
			type: "actions",
			flex: .15,
			renderCell: (params) => (
				<CoAppLightTooltip title="Restore">
					<IconButton onClick={() => handleRestoreRule(params.row.id)}>
						<Restore sx={{ color: theme.palette.icon.grey }} />
					</IconButton>
				</CoAppLightTooltip>
			)
		}
	];

	const invisibleFields = ["id"];
	const filterableColumns = useMemo(
		() => columns
			.filter((column) => !invisibleFields.includes(column.field)),
		[columns]);

	function CustomToolbar() {
		return (
			<div style={{ width: "100%" }}>
				<CoAppDataGridToolbar container>
					<Grid item xxl={0.1} xl={0.1} lg={0.2} sm={0.2} />
					<Grid item xxl={1} xl={1} lg={1.2} sm={1.5}>
						<GridToolbarFilterButton />
					</Grid>
					<Grid item xxl={4} xl={4} lg={3.5} sm={3}>
						<GridToolbarQuickFilter sx={{ width: { xxl: "220px", xl: "220px", lg: "200px", sm: "150px" } }} />
					</Grid>
					<CoAppDataGridPaginationContainer item>
						<GridPagination rowsPerPageOptions={[]} />
					</CoAppDataGridPaginationContainer>
					<RuleDataGridSelectionRow
						selectedRules={selectionModel}
						totalRules={rules}
						isTrash={true}
						shouldShow={selectionModel.length > 0}
						onClearSelectionClickHandler={() => setSelectionModel([])}
						onRestoreClickHandler={handleBulkRuleRestoration}
						onDeleteClickHandler={() => setBulkDestructiveModalOpen(true)}
					/>
				</CoAppDataGridToolbar>
			</div>
		);
	}

	const initTrashLibrary = () => {
		axios.get(apiRoutes.getRules + "?isTrashed=1", { headers: authHeader })
			.then(res => {
				setRules(res.data.rules);
			})
			.catch(err => console.log(err));
	};

	const handleDeleteRuleToggle = (rule = { name: "" }) => {
		setDestructiveModalOpen(prev => !prev);
		setRuleBeingDeleted(rule);
	};

	useEffect(() => {
		initTrashLibrary();
	}, []);

	return (
		<>
			<CoAppDataGridHeader
				title="Trash"
				resourceType="Rule"
				addIsPresent={false}
				editIsPresent={false}
				deleteIsPresent={false}
			/>
			<CoAppDataGrid
				rows={rules}
				columns={filterableColumns}
				checkboxSelection
				disableRowSelectionOnClick
				initialState={{
					...datagridInitialState,
					pinnedColumns: { left: ["delete", "restore", "__check__", "name"] }
				}}
				rowSelectionModel={selectionModel}
				onRowSelectionModelChange={handleRowSelectionChange}
				slots={{
					toolbar: CustomToolbar,
					noResultsOverlay: DataGridCustomNoRowsOverlay,
					noRowsOverlay: DataGridCustomNoRowsOverlay,
					openFilterButtonIcon: FilterAlt
				}}
				slotProps={{
					noResultsOverlay: { message: "No rules found." },
					noRowsOverlay: { message: "No rules found." }
				}}
				hideFooter
				disableColumnMenu
			/>

			<CoAppDestructiveConfirmationModal
				dialogOpen={destructiveModalOpen}
				dialogTitle={`Delete ${ruleBeingDeleted.name} rule?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(ruleBeingDeleted.name, "rule")}
				confirmClickHandler={handleDeleteRule}
				cancelClickHandler={handleDeleteRuleToggle}
				actionText="Delete"
			/>

			<CoAppDestructiveConfirmationModal
				dialogOpen={bulkDestructiveModalOpen}
				dialogTitle="Permanently delete selected rules?"
				dialogMessage={messages.BULK_PERMANENTLY_DELETE_MSG("rules")}
				confirmClickHandler={handleBulkRuleDeletion}
				cancelClickHandler={() => setBulkDestructiveModalOpen(false)}
				actionText="Permanently delete"
			/>
		</>
	);
}