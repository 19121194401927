import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "@descope/react-sdk";
import { LicenseInfo } from "@mui/x-license-pro";

import store from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

LicenseInfo.setLicenseKey("726673f75c78b4d92d5da900f4510d9fTz04MDA1MixFPTE3MzM0NTc3NzAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

root.render(
	<AuthProvider projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID}>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
