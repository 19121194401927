import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import useAuthHeader from "../../helpers/useAuthHeader";

import { RequestCard, RequestMetadata, RequestMetadataHeader } from "./styled/requests.styled";

export default function ManagerApprovalRequest() {
	const { action } = useParams();
	const { requestId } = useParams();
	let authHeader = useAuthHeader();

	const [approved, setApproved] = useState(false);
	const [rejected, setRejected] = useState(false);
	const [requestError, setRequestError] = useState("");

	const [request, setRequest] = useState({});
	const [rule, setRule] = useState({});

	const approveRequest = () => {
		axios.put(apiRoutes.requestUpdate + "/" + requestId + "/approve", {
			headers: authHeader
		})
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					setApproved(true);
				} else {
					setRequestError("Error approving request: " + res.data.message);
				}
			}).catch((err) => {
				console.log(err);
				setRequestError("Error approving request: " + err.response.data.message);
			});
	};

	const initRequest = () => {
		axios.get(apiRoutes.getRequest + "/" + requestId, {
			headers: authHeader
		})
			.then((res) => {
				setRequest(res.data);
				setRule(res.data.rule);
				if (action === "approve") {
					approveRequest();
				} else if (action === "reject") {
					rejectRequest();
				}
			}).catch((err) => {
				setRequestError("Error retrieving request: " + err);
				console.log(err);
			});
	};

	const rejectRequest = () => {
		axios.put(apiRoutes.requestUpdate + "/" + requestId + "/reject", {
			headers: authHeader
		})
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					setRejected(true);
				} else {
					setRequestError("Error rejecting request");
				}
			}).catch((err) => {
				console.log(err);
				setRequestError("Error rejecting request: " + err.response.data.message);
			});
	};

	useEffect(() => {
		initRequest();
	}, []);

	return (
		<React.Fragment>
			<RequestCard>
				<Grid container spacing={1} justifyContent="space-evenly">
					{
						approved ?
							<Grid item xs={10}>
								<h3>Request Approved!</h3>
							</Grid>
							: null
					}
					{
						rejected ?
							<Grid item xs={10}>
								<h3>Request Rejected!</h3>
							</Grid>
							: null
					}
					{
						requestError ?
							<Grid item xs={10}>
								<h3>{requestError}</h3>
							</Grid>
							: null
					}
					<Grid item xs={10}>
						<RequestMetadataHeader>Request Code:</RequestMetadataHeader>
						<RequestMetadata>{request.requestCode}</RequestMetadata>
					</Grid>
					<Grid item xs={10}>
						<RequestMetadataHeader>Requester:</RequestMetadataHeader>
						<RequestMetadata>{request.requester}</RequestMetadata>
					</Grid>
					<Grid item xs={10} >
						<RequestMetadataHeader>Time of Request:</RequestMetadataHeader>
						<RequestMetadata>{request.createdAt}</RequestMetadata>
					</Grid>
					<Grid item xs={10}>
						<RequestMetadataHeader>Executed Rule:</RequestMetadataHeader>
						<RequestMetadata>{rule.name}</RequestMetadata>
					</Grid>
				</Grid>
			</RequestCard>
		</React.Fragment>
	);
}
