import { useSelector } from "react-redux";

import { selectMappedGroups, selectMappedRoles, selectOrganizationName, selectUnmappedGroups, selectUnmappedRoles } from "../../../../../redux/scimWizardSlice";
import ConfirmSetup from "../../../../global/components/confirm-setup";
import { ScimWizardStepContainer } from "../styled/scim-wizard.styled";

export default function Step4() {
	const organizationName = useSelector(selectOrganizationName);
	const mappedGroups = useSelector(selectMappedGroups);
	const unmappedGroups = useSelector(selectUnmappedGroups);
	const mappedRoles = useSelector(selectMappedRoles);
	const unmappedRoles = useSelector(selectUnmappedRoles);

	const sections = [
		{
			title: "Details",
			items: [
				[
					{ title: "Provider", value: "Office 365" },
					{ title: "Organization", value: organizationName }
				]
			]
		},
		{
			title: "Mapped Groups",
			items: Object.entries(mappedGroups).map(([key, value]) => {
				if (value.length === 0) return [];
				return [
					{ title: key, value: value.join(", ") }
				];
			})
		},
		{
			title: "Unmapped Groups (users within these groups will be removed from the group)",
			items: unmappedGroups.map((group) => {
				return [
					{ title: group, value: "" }
				];
			})
		},
		{
			title: "Mapped Roles",
			items: Object.entries(mappedRoles).map(([key, value]) => {
				if (value.length === 0) return [];
				return [
					{ title: key, value: value.join(", ") }
				];
			})
		},
		{
			title: "Unmapped Roles (users with these roles will be removed from the role)",
			items: unmappedRoles.map((role) => {
				return [
					{ title: role, value: "" }
				];
			})
		}

	];
	return (
		<ScimWizardStepContainer>
			<ConfirmSetup sections={sections} />
		</ScimWizardStepContainer>
	);
}