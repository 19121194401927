import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	user: {
		email: null,
		firstName: null,
		groups: [],
		lastName: null,
		organizationId: null,
		organizationForceLogin: null,
		organizationIdentityProvider: null,
		organizationKillSwitch: null,
		organizationSsoAndScim: null,
		permissions: [],
		phone: null,
		roles: [],
		userId: null,
	},
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		setFirstName: (state, action) => {
			state.firstName = action.payload;
		},
		setGroups: (state, action) => {
			state.groups = action.payload;
		},
		setLastName: (state, action) => {
			state.lastName = action.payload;
		},
		setOrgId: (state, action) => {
			state.organizationId = action.payload;
		},
		setOrganizationForceLogin: (state, action) => {
			state.organizationForceLogin = action.payload;
		},
		setOrganizationIdentityProvider: (state, action) => {
			state.organizationIdentityProvider = action.payload;
		},
		setOrganizationKillSwitch: (state, action) => {
			state.organizationKillSwitch = action.payload;
		},
		setOrganizationSsoAndScim: (state, action) => {
			state.organizationSsoAndScim = action.payload;
		},
		setPermissions: (state, action) => {
			state.permissions = action.payload;
		},
		setPhone: (state, action) => {
			state.phone = action.payload;
		},
		setRoles: (state, action) => {
			state.roles = action.payload;
		},
		setUserId: (state, action) => {
			state.userId = action.payload;
		}
	}
});

export const selectEmail = (state) => state.user.email;
export const selectFirstName = (state) => state.user.firstName;
export const selectGroups = (state) => state.user.groups;
export const selectLastName = (state) => state.user.lastName;
export const selectOrganizationId = (state) => state.user.organizationId;
export const selectOrganizationForceLogin = (state) => state.user.organizationForceLogin;
export const selectOrganizationIdentityProvider = (state) => state.user.organizationIdentityProvider;
export const selectOrganizationKillSwitch = (state) => state.user.organizationKillSwitch;
export const selectOrganizationSsoAndScim = (state) => state.user.organizationSsoAndScim;
export const selectPermissions = (state) => state.user.permissions;
export const selectPhone = (state) => state.user.phone;
export const selectRoles = (state) => state.user.roles;
export const selectUser = (state) => state.user;
export const selectUserId = (state) => state.user.userId;

export const {
	setEmail,
	setFirstName,
	setGroups,
	setLastName,
	setPermissions,
	setPhone,
	setRoles,
	setUserId,
	setOrgId,
	setOrganizationForceLogin,
	setOrganizationIdentityProvider,
	setOrganizationKillSwitch,
	setOrganizationSsoAndScim,
} = userSlice.actions;

export default userSlice.reducer;