import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	mappedGroups: {},
	mappedRoles: {},
	organizationName: "",
	rawExternalGroups: [],
	coappGroups: [],
	unmappedGroups: [],
	coappRoles: [],
	unmappedRoles: []
};

export const scimWizardSlice = createSlice({
	name: "scimWizard",
	initialState,
	reducers: {
		setMappedGroups: (state, action) => {
			state.mappedGroups = action.payload;
		},
		setMappedRoles: (state, action) => {
			state.mappedRoles = action.payload;
		},
		setOrganizationName: (state, action) => {
			state.organizationName = action.payload;
		},
		setRawExternalGroups: (state, action) => {
			state.rawExternalGroups = action.payload;
		},
		setCoappGroups: (state, action) => {
			state.coappGroups = action.payload;
		},
		setUnmappedGroups: (state, action) => {
			state.unmappedGroups = action.payload;
		},
		setCoappRoles: (state, action) => {
			state.coappRoles = action.payload;
		},
		setUnmappedRoles: (state, action) => {
			state.unmappedRoles = action.payload;
		}
	}
});

export const selectMappedGroups = (state) => state.scimWizard.mappedGroups;
export const selectMappedRoles = (state) => state.scimWizard.mappedRoles;
export const selectOrganizationName = (state) => state.scimWizard.organizationName;
export const selectRawExternalGroups = (state) => state.scimWizard.rawExternalGroups;
export const selectCoappGroups = (state) => state.scimWizard.coappGroups;
export const selectUnmappedGroups = (state) => state.scimWizard.unmappedGroups;
export const selectCoappRoles = (state) => state.scimWizard.coappRoles;
export const selectUnmappedRoles = (state) => state.scimWizard.unmappedRoles;

export const {
	setMappedGroups,
	setMappedRoles,
	setOrganizationName,
	setRawExternalGroups,
	setCoappGroups,
	setUnmappedGroups,
	setCoappRoles,
	setUnmappedRoles
} = scimWizardSlice.actions;

export default scimWizardSlice.reducer;