import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	CircularProgress, Container,
	Stack, Switch
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectNavState, setNavState } from "../../../../redux/navigationSlice";
import CoAppDataGridDateTimeCell from "../../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridNameCell from "../../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/coapp-standard-datagrid";
import CoAppAddItemsToListModal from "../../../global/components/modals/coapp-add-items-to-list-modal";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../global/components/modals/coapp-edit-name-modal";
import ThreeDotMenu from "../../../global/components/three-dot-menu";

export default function GroupRules() {
	const authHeader = useAuthHeader();
	const { groupId } = useParams();
	const navState = useSelector(selectNavState);
	const dispatch = useDispatch();
	const { state } = useLocation(); //state will have the nav object passed from the previous page, containing the current name
	const navigate = useNavigate();

	const [addRulesIsToggled, setAddRulesIsToggled] = useState(false);
	const [availableRules, setAvailableRules] = useState([]);
	const [selectedRules, setSelectedRules] = useState([]);
	const [rulesToAdd, setRulesToAdd] = useState([]);
	const [groupRules, setGroupRules] = useState([]);
	const [groupRulesLoaded, setGroupRulesLoaded] = useState(false);
	const [editGroupIsToggled, setEditGroupIsToggled] = useState(false);
	const [deleteGroupIsToggled, setDeleteGroupIsToggled] = useState(false);
	const [removeRulesIsToggled, setRemoveRulesIsToggled] = useState(false);
	const [groupNameInput, setGroupNameInput] = useState("");
	const [groupNameError, setGroupNameError] = useState("");

	const columns = [
		{
			editable: false,
			field: "name",
			flex: 1,

			headerName: "Rule Name",
			pinnable: false,
			renderCell: (params) => (
				<CoAppDataGridNameCell item={params.row} />
			),
		},
		{
			editable: true,
			field: "active",
			flex: 1,
			headerName: "Status",
			pinnable: false,
			renderCell: (params) => (
				<Switch
					checked={params.row.active}
					disabled={params.row.isDraft}
					onChange={() => handleRuleStatusToggle(params.row.id, params.row.active)}
				/>
			),
		},
		{
			editable: false,
			field: "updatedAt",
			flex: 1,
			headerName: "Modified",
			pinnable: false,
			valueGetter: (params) => new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.value} />
			),
		},
		{
			editable: false,
			field: "createdAt",
			flex: 0.5,
			headerName: "Created",
			pinnable: false,
			valueGetter: (params) => new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.value} />
			),
		},
		{
			field: "actions",
			disableExport: true,
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<div>
					<ThreeDotMenu
						options={[
							{
								name: "Remove From Group",
								optionClickHandler: () => {
									removeRuleFromGroup(params.row.id);
								},
							},
							{
								name: "View Rule",
								optionClickHandler: () => {
									navigate(pages.editRule(params.row.id));
								},
							},
						]}
					/>
				</div>
			),
		},
	];

	const handleToggleGroupEditDialog = () => {
		setEditGroupIsToggled(!editGroupIsToggled);
		setGroupNameError("");
		setGroupNameInput("");
	};

	const handleGroupNameChange = (e) => {
		setGroupNameInput(e.target.value);
		setGroupNameError("");
	};

	const handleGroupNameChangeSubmit = () => {
		if (!groupNameInput) return;

		if (groupNameInput === state.name) {
			handleToggleGroupEditDialog();
			return;
		}

		axios.put(apiRoutes.updateGroup(groupId), { name: groupNameInput }, { headers: authHeader })
			.then(() => {
				setEditGroupIsToggled(false);
				updateNavigation();
				dispatch(setMessage(messages.GROUP_NAME_UPDATED_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setGroupNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Group"));
					return;
				}
				handleToggleGroupEditDialog();
				dispatch(setMessage(messages.GROUP_NAME_UPDATE_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const updateNavigation = (isDeleteEvent = false) => {
		const oldNavState = [...navState];
		if (!isDeleteEvent) {
			let copyGroupChildren = [...oldNavState[7].children].slice(1);
			copyGroupChildren.unshift({
				id: Math.random(),
				name: groupNameInput,
				children: [],
				isGroupingLabel: true
			});

			oldNavState[7] = {
				...oldNavState[7],
				children: copyGroupChildren,
				isChangeEvent: true,
				isStillSelected: true,
				selectedResource: "Rules",
				isNameChangeEvent: true
			};
		} else {
			oldNavState[7] = {
				...oldNavState[7],
				children: [],
				isStillSelected: true,
				selectedResource: ""
			};
		}
		dispatch(setNavState(oldNavState));
	};

	const handleToggleDeleteGroupDialog = () => {
		setDeleteGroupIsToggled(!deleteGroupIsToggled);
	};

	const confirmGroupDeletion = () => {
		axios.delete(apiRoutes.deleteGroup + "/" + groupId, {
			headers: authHeader
		}).then((res) => {
			if (res.status === 200) {
				dispatch(setMessage(messages.GROUP_DELETED_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				updateNavigation(true);
				navigate(pages.groupManagement);
			} else {
				console.log(res);
				dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			}
			handleToggleDeleteGroupDialog();
		}).catch((err) => {
			console.log(err);
			dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
			handleToggleDeleteGroupDialog();
		});
	};

	const addRulesToGroup = () => {
		let requestJson = {
			ruleIds: rulesToAdd.map((rule) => rule.id)
		};
		axios.post(apiRoutes.addRulesToGroup(groupId), requestJson, { headers: authHeader })
			.then((response) => {
				if (response.status === 200) {
					init();
					dispatch(setMessage(messages.RULE_ADDED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
				} else {
					dispatch(setMessage(messages.RULE_ADDED_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
				toggleAddNewDialog();
			})
			.catch((error) => {
				console.log(error);
				toggleAddNewDialog();
				dispatch(setMessage(messages.RULE_ADDED_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const addRuleToRulesToAdd = (value) => {
		//value is an array of rule names, we need to convert them to rule objects to display name properly
		value = value.map((rule) => {
			let tempArr = [...groupRules, ...availableRules];
			let foundRule = tempArr.find((row) => row.name === rule);
			return foundRule;
		});
		setRulesToAdd(value);
	};

	const init = () => {
		let ruleQueryObject = {
			groupId: groupId,
		};
		axios.get(apiRoutes.getRules, {
			headers: authHeader,
			params: ruleQueryObject,
		})
			.then((response) => {
				setGroupRules(response.data.rules);
				setGroupRulesLoaded(true);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const initAvailableRules = () => {
		axios.get(apiRoutes.getRules, { headers: authHeader })
			.then((response) => {
				let availableRules = response.data.rules.filter((rule) => {
					let ruleIndex = groupRules.findIndex((groupRule) => groupRule.id === rule.id);
					return ruleIndex === -1;
				});
				setAvailableRules(availableRules);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const removeRuleFromGroup = (ruleId) => {
		axios.delete(apiRoutes.removeRuleFromGroup(groupId, ruleId), { headers: authHeader })
			.then((response) => {
				if (response.status === 200) {
					init();
					dispatch(setMessage(messages.RULE_REMOVED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
				} else {
					dispatch(setMessage(messages.RULE_REMOVED_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.RULE_REMOVED_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const handleRuleStatusToggle = (ruleId, currentStatus) => {
		axios.put(apiRoutes.toggleRuleStatus + "/" + ruleId, {}, {
			headers: authHeader
		})
			.then(() => {
				init();
				dispatch(setMessage(currentStatus ? messages.RULE_DEACTIVATION_SUCCESS_MSG : messages.RULE_ACTIVATION_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch(err => {
				console.log("error: " + err);
				dispatch(setMessage(currentStatus ? messages.RULE_DEACTIVATION_SUCCESS_MSG : messages.RULE_ACTIVATION_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	//TODO:: When implementing bulk destructive actions app wide, we should optimize this by not iteratively calling our services but rather call once with the selected ids
	//see bulk removal of users from a role in role-users.js
	const removeRulesFromGroup = () => {
		selectedRules.forEach((rule) => {
			removeRuleFromGroup(rule);
		});
	};

	const toggleAddNewDialog = () => {
		if (!addRulesIsToggled) {
			initAvailableRules();
			setAddRulesIsToggled(true);
		} else {
			setAddRulesIsToggled(false);
			setAvailableRules([]);
			setRulesToAdd([]);
		}
	};

	useEffect(() => {
		init();
	}, [state.name]);

	if (groupRulesLoaded) {
		return (
			<>
				<CoAppDatagridHeader
					title={`${state.name} Rule Management`}
					subResourceType="Rule"
					resourceType="Group"
					addOnClickHandler={toggleAddNewDialog}
					addIsPresent={true}
					editIsPresent={true}
					editOnClickHandler={handleToggleGroupEditDialog}
					deleteIsPresent={true}
					deleteOnClickHandler={handleToggleDeleteGroupDialog}
				/>
				<CoAppStandardDataGrid
					columns={columns}
					rows={groupRules}
					pinnedColumns={["actions", "__check__", "name", "status"]}
					allowSelection={true}
					targetResource="rules"
					parentResource="group"
					parentResourceName={state.name}
					bulkActionText="Remove"
					selectionModel={selectedRules}
					setSelectionModel={setSelectedRules}
				/>
				<CoAppAddItemsToListModal
					addButtonTitle="Save"
					inputLabel="Rule(s)"
					addItemsIsToggled={addRulesIsToggled}
					addItemsToCollection={addRulesToGroup}
					addItemsToNewArray={addRuleToRulesToAdd}
					dialogTitle={`Add rule(s) to ${state.name} group`}
					items={availableRules}
					itemsToAdd={rulesToAdd}
					toggleDialog={toggleAddNewDialog}
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={deleteGroupIsToggled}
					dialogTitle={`Delete ${state.name} group?`}
					dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "group")}
					confirmClickHandler={confirmGroupDeletion}
					cancelClickHandler={handleToggleDeleteGroupDialog}
					actionText="Delete"
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={removeRulesIsToggled}
					dialogTitle={`Remove ${selectedRules.length > 1 ? "rules" : "rule"} from ${state.name} group?`}
					dialogMessage={`Are you sure that you want to remove the selected ${selectedRules.length > 1 ? "rules" : "rule"} from the ${state.name} group?`}
					confirmClickHandler={removeRulesFromGroup}
					cancelClickHandler={() => setRemoveRulesIsToggled(false)}
					actionText="Remove"
				/>
				<CoAppEditNameModal
					dialogOpen={editGroupIsToggled}
					dialogTitle="Edit group name"
					changeHandler={handleGroupNameChange}
					placeholderText={state.name}
					confirmClickHandler={handleGroupNameChangeSubmit}
					cancelClickHandler={handleToggleGroupEditDialog}
					actionText="Save"
					editNameError={groupNameError}
					setEditNameError={setGroupNameError}
				/>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}