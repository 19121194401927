import { Autocomplete, Checkbox, Chip, Typography } from "@mui/material";

import { CoAppDialogTooltipContainer, CoAppDialogTooltipText, CoAppLightTooltip } from "../../styled/global.styled";

import CoAppTextField from "./coapp-textfield";

export default function CoAppMultiSelect(props) {
	const {
		options, value, onChange,
		label, placeholder, disabled,
		isFreeSolo, customStyles, hasToolTip,
		chipSize, rawOptions = [], customRenderTags = null,
		customRenderCheckboxes = null
	} = props;

	const handleCheckboxRenderOptions = (props, option, { selected }) => {
		const { key, ...optionProps } = props;

		return (
			<li key={Math.random()} {...optionProps}>
				<Checkbox
					style={{ marginRight: 8 }}
					checked={selected}
					value={option}
				/>
				<div style={{ display: "block" }}>
					{!option.includes("@") ? option : null} {/* If the option is an email (meaning name is not available), we don't show it */}
					<Typography variant="body2" color="text.secondary">{hasToolTip ? rawOptions.find((item) => item.name === option).email : null}</Typography>
				</div>
			</li>
		);
	};

	const handleRenderTags = (tagValue, getTagProps) => {
		return tagValue.map((option, index) => {
			const { name, email } = rawOptions.find((item) => item.name === option);
			const { key, ...tagProps } = getTagProps({ index });
			return (
				<CoAppLightTooltip
					key={key}
					title={
						name !== email ?
							<CoAppDialogTooltipContainer>
								<CoAppDialogTooltipText name="true">{name}</CoAppDialogTooltipText>
								<CoAppDialogTooltipText>{email}</CoAppDialogTooltipText>
							</CoAppDialogTooltipContainer>
							:
							null
					}
				>
					<Chip {...tagProps} label={name !== email ? name : email} />
				</CoAppLightTooltip>
			);
		});
	};

	return (
		<Autocomplete
			sx={customStyles}
			freeSolo={isFreeSolo}
			multiple
			disableCloseOnSelect
			fullWidth
			ChipProps={{ size: chipSize || "medium" }}
			limitTags={10}
			options={options}
			value={value}
			onChange={onChange}
			disabled={disabled}
			getOptionLabel={(option) => option}
			renderInput={(params) => (
				<CoAppTextField
					{...params}
					label={label}
					placeholder={placeholder}
					error={props.error}
					helperText={props.helperText}
					FormHelperTextProps={{ style: { color: "#DE3730" } }}
				/>
			)}
			renderOption={!isFreeSolo ? customRenderCheckboxes !== null ? customRenderCheckboxes : handleCheckboxRenderOptions : null}
			renderTags={hasToolTip ? customRenderTags !== null ? customRenderTags : handleRenderTags : null}
		/>
	);
}