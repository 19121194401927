import { Fragment } from "react";

import {
	CoAppConfirmContainer,
	CoAppConfirmStack,
	CoAppConfirmStackItem,
	CoAppConfirmStackItemContent,
	CoAppConfirmStackItemGroup,
	CoAppConfirmStackItemGroupTitle,
} from "../styled/global.styled";

export default function ConfirmSetup({ sections }) {
	return (
		<CoAppConfirmContainer>
			{
				sections.map((section, index) => {
					if (section.items.length === 0) return null;
					return (
						<Fragment key={index}>
							<CoAppConfirmStackItemGroupTitle isfirst={index === 0}>{section.title}</CoAppConfirmStackItemGroupTitle>
							<CoAppConfirmStack key={index}>
								{
									section.items.map((item, index) => {
										return (
											<CoAppConfirmStackItemGroup key={index}>
												{
													item.map((i, index) => {
														return (
															<CoAppConfirmStackItem key={index}>
																<CoAppConfirmStackItemContent hasvalue={i.value.length > 0}>{i.title}</CoAppConfirmStackItemContent>
																<CoAppConfirmStackItemContent sx={{ color: "#000000" }}>{i.value}</CoAppConfirmStackItemContent>
															</CoAppConfirmStackItem>
														);
													})
												}
											</CoAppConfirmStackItemGroup>
										);
									})
								}
							</CoAppConfirmStack>
						</Fragment>
					);
				})
			}
		</CoAppConfirmContainer>
	);
}