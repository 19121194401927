import {
	useCallback, useEffect, useImperativeHandle,
	useMemo, useRef, useState
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FilterAlt } from "@mui/icons-material";
import {
	Autocomplete, Checkbox, Grid,
	Switch, Typography
} from "@mui/material";
import { GridPagination, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-premium";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import messages from "../../constants/messages";
import pages from "../../constants/pages";
import { arrayOperators, handleArrayFiltering, statusOperators } from "../../helpers/datagridFiltering";
import useAuthHeader from "../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../redux/alertSlice";
import CoAppDataGridDateTimeCell from "../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDataGridHeader from "../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../global/components/datagrid/coapp-datagrid-list-cell";
import DataGridCustomNoRowsOverlay from "../global/components/datagrid/datagrid-custom-norows-overlay";
import CoAppTextField from "../global/components/inputs/coapp-textfield";
import CoAppBulkApplyModal from "../global/components/modals/coapp-bulk-apply-modal";
import CoAppConfirmationModal from "../global/components/modals/coapp-confirmation-modal";
import CoAppDestructiveConfirmationModal from "../global/components/modals/coapp-destructive-confirmation-modal";
import ThreeDotMenu from "../global/components/three-dot-menu";
import { CoAppDataGrid, CoAppDataGridPaginationContainer, CoAppDataGridToolbar } from "../global/styled/global.styled";
import TagManagement from "../tags/tag-management";

import RuleDataGridLongTextCell from "./rule-datagrid-long-text-cell";
import RuleDataGridSelectionRow from "./rule-datagrid-selection-row";

const datagridInitialState = {
	pagination: {
		paginationModel: { pageSize: 50, page: 0 },
	}
};

const arrayColumns = ["groups", "tags"];

export default function RuleLibraryDataGrid() {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [rules, setRules] = useState([]);
	const [selectionModel, setSelectionModel] = useState([]);
	const [destructiveModalOpen, setDestructiveModalOpen] = useState(false);
	const [ruleBeingDeleted, setRuleBeingDeleted] = useState({ name: "" });
	const [bulkApplyModalOpen, setBulkApplyModalOpen] = useState(false);
	const [bulkDestructiveModalOpen, setBulkDestructiveModalOpen] = useState(false);
	const [bulkStatusUpdateOpen, setBulkStatusUpdateModalOpen] = useState(false);
	const [isBulkActivating, setIsBulkActivating] = useState(false);
	const [bulkStatusUpdateTitle, setBulkStatusUpdateTitle] = useState("");
	const [bulkApplyAvailableItems, setBulkApplyAvailableItems] = useState([]);
	const [bulkApplyItems, setBulkApplyItems] = useState([]);
	const [bulkApplyItemType, setBulkApplyItemType] = useState("");
	const [bulkApplyRemovedItems, setBulkApplyRemovedItems] = useState([]);

	const model = bulkApplyItemType.toLowerCase().includes("tag") ? "tags" : "groups";

	const handleStatusToggleChange = (rule) => {
		axios.put(apiRoutes.toggleRuleStatus + "/" + rule.id, {}, { headers: authHeader })
			.then(() => {
				initRuleLibrary();
			})
			.catch(err => {
				console.log("error: " + err);
			});
	};

	const handleAddRuleClick = () => {
		navigate(pages.newRule);
	};

	const handleEditRuleClick = (ruleId) => {
		navigate(pages.editRule(ruleId));
	};

	const handleDeleteRule = (ruleId) => {
		axios.delete(apiRoutes.deleteRule + "/" + ruleId, {
			headers: authHeader
		})
			.then(() => {
				//TODO:: publish an info type alert and provide undo option during notification overhaul work
				initRuleLibrary();
			})
			.catch(err => {
				console.error(err);
			});
	};

	const handleRestoreRule = (ruleId) => {
		axios.put(apiRoutes.restoreRule + "/" + ruleId, {}, {
			headers: authHeader
		})
			.then(() => {
				//TODO:: publish an info type alert and provide undo option during notification overhaul work
				initRuleLibrary();
			})
			.catch(err => {
				console.error(err);
			});
	};

	const handleCopyRuleClick = (ruleId) => {
		axios.post(apiRoutes.copyRule + "/" + ruleId, {}, {
			headers: authHeader
		})
			.then(() => {
				initRuleLibrary();
				dispatch(setLevel("success"));
				dispatch(setMessage(messages.RULE_COPY_SUCCESS_MSG));
				dispatch(setOpen(true));
			})
			.catch(err => {
				console.error(err);
				dispatch(setLevel("error"));
				dispatch(setMessage(messages.RULE_COPY_ERROR_MSG));
				dispatch(setOpen(true));
			});
	};

	const columns = [
		{
			field: "name",
			headerName: "Rule name",
			flex: 1,
			renderCell: (params) => (
				<RuleDataGridLongTextCell value={params.value} hasBeforeEffect={!params.row.active && params.row.isDraft} />
			)
		},
		{
			field: "active",
			headerName: "Status",
			flex: 1,
			align: "center",
			headerAlign: "center",
			renderCell: (params) => (
				<Switch
					checked={params.value}
					disabled={params.row.isDraft}
					onChange={() => handleStatusToggleChange(params.row)}
				/>
			)
		},
		{
			field: "description",
			headerName: "Description",
			flex: 1,
			renderCell: (params) => (
				<RuleDataGridLongTextCell value={params.value} />
			)
		},
		{
			field: "groups",
			headerName: "Groups",
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value} resource="Groups" />
			)
		},
		{
			field: "tags",
			headerName: "Tags",
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value} resource="Tags" />
			)
		},
		{
			field: "createdAt",
			headerName: "Created",
			flex: 1,
			type: "dateTime",
			valueGetter: (params) => new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.value} />
			),
		},
		{
			field: "updatedAt",
			headerName: "Updated",
			flex: 1,
			type: "dateTime",
			valueGetter: (params) => new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.value} />
			),
		},
		{
			field: "actions",
			type: "actions",
			flex: .1,
			renderCell: (params) => (
				<div>
					<ThreeDotMenu
						options={[
							{
								name: "Edit",
								optionClickHandler: () => {
									handleEditRuleClick(params.row.id);
								},
							},
							{
								name: "Duplicate",
								optionClickHandler: () => {
									handleCopyRuleClick(params.row.id);
								},
							},
							{
								name: "Delete",
								optionClickHandler: () => {
									handleDeleteRuleToggle(params.row);
								},
							}
						]}
					/>
				</div>
			)
		}
	];

	function ArrayTypeInput(props) {
		const { item, applyValue, itemType } = props;
		const [items, setItems] = useState([]);

		useEffect(() => {
			if (itemType === "tags") {
				axios.get(apiRoutes.getTags, { headers: authHeader })
					.then(res => {
						setItems(res.data);
					})
					.catch(err => console.log(err));
			} else {
				axios.get(apiRoutes.getGroups, { headers: authHeader })
					.then(res => {
						setItems(res.data);
					})
					.catch(err => console.log(err));
			}
		}, []);

		const handleSelectionChange = (event, value, reason) => {
			applyValue({ ...item, value: value });
		};

		const handleCheckboxRenderOptions = (props, option, { selected }) => {
			const { key, ...optionProps } = props;
			return (
				<li key={Math.random()} {...optionProps}>
					<Checkbox
						style={{ marginRight: 5 }}
						checked={selected}
						value={option.name}
					/>
					<Typography variant="body2" color="text.primary">{option.name}</Typography>
				</li>
			);
		};

		return (
			<Autocomplete
				multiple
				size="small"
				sx={{ marginTop: "-16px" }}
				limitTags={2}
				options={items}
				getOptionLabel={(option) => option.name}
				value={item.value}
				onChange={handleSelectionChange}
				disableCloseOnSelect
				renderInput={(params) => (
					<CoAppTextField
						{...params}
						label="Filter value"
						placeholder={`Search ${itemType === "tags" ? "tags" : "groups"}`}
						error={props.error}
						helperText={props.helperText}
						FormHelperTextProps={{ style: { color: "#DE3730" } }}
					/>
				)
				}
				renderOption={handleCheckboxRenderOptions}
			/>
		);
	}

	const invisibleFields = ["id"];
	const filterableColumns = useMemo(
		() => columns
			.filter((column) => !invisibleFields.includes(column.field))
			.map((column) => {
				if (arrayColumns.includes(column.field)) {
					return {
						...column,
						getApplyQuickFilterFn: handleArrayFiltering,
						filterOperators: [
							...arrayOperators,
							{
								label: "has any of",
								value: "hasAnyOf",
								getApplyFilterFn: (filterItem) => {
									return (value) => {
										if (!filterItem.value) return null;
										const valueNames = value.value.map(item => item.name.toLowerCase());
										const filterItemNames = filterItem.value.map(item => item.name.toLowerCase());
										return valueNames.some(name => filterItemNames.includes(name));
									};
								},
								InputComponent: ArrayTypeInput,
								InputComponentProps: {
									itemType: column.field
								}
							}
						]
					};
				} else if (column.field === "active") {
					return {
						...column,
						filterOperators: statusOperators
					};
				} else {
					return column;
				}
			}),
		[columns]);

	const initRuleLibrary = () => {
		axios.get(apiRoutes.getRules, { headers: authHeader })
			.then(res => {
				setRules(res.data.rules);
			})
			.catch(err => console.log(err));
	};

	const CustomToolbar = useCallback(() => {
		return (
			<div style={{ width: "100%" }}>
				<CoAppDataGridToolbar container>
					<Grid item xxl={0.1} xl={0.1} lg={0.2} sm={0.2} />
					<Grid item xxl={0.8} xl={0.8} lg={1} sm={1.2}>
						<GridToolbarFilterButton />
					</Grid>
					<Grid item sx={{ marginTop: "-3px" }}>
						<TagManagement initRuleLibrary={initRuleLibrary} />
					</Grid>
					<Grid item xxl={4} xl={4} lg={3.5} sm={3}>
						<GridToolbarQuickFilter sx={{ width: { xxl: "220px", xl: "220px", lg: "200px", sm: "150px" } }} />
					</Grid>
					<CoAppDataGridPaginationContainer item>
						<GridPagination rowsPerPageOptions={[]} />
					</CoAppDataGridPaginationContainer>
					<RuleDataGridSelectionRow
						selectedRules={selectionModel}
						totalRules={rules}
						shouldShow={selectionModel.length > 0}
						onClearSelectionClickHandler={() => setSelectionModel([])}
						onDeactivateClickHandler={() => handleBulkStatusUpdateModalToggle("deactivate")}
						onActivateClickHandler={() => handleBulkStatusUpdateModalToggle("activate")}
						onDeleteClickHandler={handleBulkSoftDeleteRuleToggle}
						onTagClickHandler={() => handleBulkApplyModalToggle("tags")}
						onGroupClickHandler={() => handleBulkApplyModalToggle("groups")}
					/>
				</CoAppDataGridToolbar>
			</div>
		);
	}, []);

	const handleBulkApplyModalToggle = (model) => {
		setBulkApplyModalOpen(true);
		setBulkApplyItemType(model === "tags" ? "Tags" : "Groups");
		const apiRoute = model === "tags" ? apiRoutes.getTags : apiRoutes.getGroups;
		axios.get(apiRoute, { headers: authHeader })
			.then(res => {
				setBulkApplyAvailableItems(res.data);
				let bulkItems = [];
				for (let ruleId of selectionModel) {
					let foundRule = rules.find(rule => rule.id === ruleId);
					if (foundRule[model] && foundRule[model].length > 0) {
						bulkItems = [...bulkItems, ...foundRule[model].map(item => { return { name: item.name }; })];
					}
				}
				let justNames = bulkItems.map(item => item.name);
				let uniqueItems = [...new Set(justNames)].map(name => { return { name: name }; });
				setBulkApplyItems([...new Set(uniqueItems)]);
			})
			.catch(err => console.log(err));
	};

	const handleBulkApplyModalClose = () => {
		setBulkApplyModalOpen(false);
		setBulkApplyItems([]);
		setBulkApplyAvailableItems([]);
		setBulkApplyItemType("");
		setBulkApplyRemovedItems([]);
	};

	const handleAddOrRemoveItemChange = (items) => {
		const removedItems = bulkApplyItems.filter(item => !items.includes(item.name));
		if (removedItems.length > 0) {
			setBulkApplyRemovedItems(prev => [...prev, ...removedItems]);
		}
		setBulkApplyItems(items.map(itemName => { return { name: itemName }; }));
	};

	const handleBulkApplyConfirmation = () => {
		const putData = {
			ruleIds: selectionModel,
			itemsToRemove: calculateItemsRemovedFromAll(),
			itemsToAdd: calculateItemsToAddToAll(),
			model: model
		};
		axios.post(apiRoutes.bulkApplyRuleChanges, putData, { headers: authHeader })
			.then(() => {
				initRuleLibrary();
				handleBulkApplyModalClose();
				setSelectionModel([]);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const calculateItemsRemovedFromAll = () => {
		const itemsRemovedFromAll = [];
		for (let item of bulkApplyAvailableItems) {
			const isCurrentlyAppliedToRule = selectionModel.some(ruleId => {
				let foundRule = rules.find(rule => rule.id === ruleId);
				return foundRule[model].map(item => item.name).includes(item.name);
			});
			const isNotBeingAdded = !bulkApplyItems.map(item => item.name).includes(item.name);
			if ((isCurrentlyAppliedToRule && isNotBeingAdded)) {
				itemsRemovedFromAll.push(item);
			}
		}
		return itemsRemovedFromAll;
	};

	const calculateItemsToAddToAll = () => {
		const itemsToAdd = [];
		for (let item of bulkApplyAvailableItems) {
			const isCurrentlyAppliedToEveryRule = selectionModel.every(ruleId => {
				let foundRule = rules.find(rule => rule.id === ruleId);
				return foundRule[model].map(item => item.name).includes(item.name);
			});

			const unchangedItems = calculateUnchangedItems();
			const isUnchanged = unchangedItems.map(item => item.name).includes(item.name);
			const isSelected = bulkApplyItems.map(item => item.name).includes(item.name);
			if (!isCurrentlyAppliedToEveryRule && !isUnchanged && isSelected) {
				itemsToAdd.push(item);
			}
		}
		return itemsToAdd;
	};

	const calculateUnchangedItems = () => {
		const unchangedItems = [];
		for (let item of bulkApplyItems) {
			const isCurrentlyAppliedToRule = selectionModel.some(ruleId => {
				let foundRule = rules.find(rule => rule.id === ruleId);
				return foundRule[model].map(item => item.name).includes(item.name);
			});

			const hasBeenRemovedBefore = bulkApplyRemovedItems.filter(removedItem => removedItem.name === item.name).length > 0;
			if (isCurrentlyAppliedToRule && !hasBeenRemovedBefore) {
				unchangedItems.push(item);
			}
		}
		return unchangedItems;
	};

	const handleRowSelectionChange = (newSelectionModel) => {
		setSelectionModel(newSelectionModel);
	};

	const handleDeleteRuleToggle = (rule = { name: "" }) => {
		setDestructiveModalOpen(prev => !prev);
		setRuleBeingDeleted(rule);
	};

	const handleBulkSoftDeleteRuleToggle = () => {
		setBulkDestructiveModalOpen(prev => !prev);
	};

	const handleBulkSoftDeleteRulesConfirmation = () => {
		const deleteData = {
			ruleIds: selectionModel
		};
		axios.delete(apiRoutes.bulkDeleteRules + "?isHardDelete=0", { headers: authHeader, data: deleteData })
			.then(() => {
				//TODO:: add appropriate notification here during notification overhaul work
				initRuleLibrary();
				handleBulkSoftDeleteRuleToggle();
				setSelectionModel([]);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleBulkStatusUpdateConfirmation = () => {
		let newStatus = isBulkActivating ? true : false;

		const putData = {
			ruleIds: getRulesToBeUpdated(),
			newStatus: newStatus
		};

		axios.put(apiRoutes.bulkUpdateRuleStatus, putData, { headers: authHeader })
			.then(() => {
				//TODO:: add appropriate notification here during notification overhaul work
				initRuleLibrary();
				handleBulkStatusUpdateModalToggle();
				setSelectionModel([]);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const getRulesToBeUpdated = () => {
		const objectifiedSelectedRules = selectionModel.map(ruleId => {
			const foundRule = rules.find(totalRule => totalRule.id === ruleId);
			return foundRule;
		});
		let ruleIds = [];
		for (let rule of objectifiedSelectedRules) {
			if (isBulkActivating) {
				if (!rule.active) {
					ruleIds.push(rule.id);
				}
			} else {
				if (rule.active) {
					ruleIds.push(rule.id);
				}
			}
		}
		return ruleIds;
	};

	const getBulkStatusUpdateModalTitle = () => {
		const objectifiedSelectedRules = selectionModel.map(ruleId => {
			const foundRule = rules.find(totalRule => totalRule.id === ruleId);
			return foundRule;
		});

		if (isBulkActivating) {
			const toBeActivatedCount = objectifiedSelectedRules.filter(rule => !rule.active).length;
			return `Activate selected inactive rules (${toBeActivatedCount})?`;
		} else {
			const toBeDeactivatedCount = objectifiedSelectedRules.filter(rule => rule.active).length;
			return `Deactivate selected active rules (${toBeDeactivatedCount})?`;
		}
	};

	const handleBulkStatusUpdateModalToggle = (status = "activate") => {
		if (status === "activate") {
			setBulkStatusUpdateTitle(getBulkStatusUpdateModalTitle());
			setBulkStatusUpdateModalOpen(prev => !prev);
			setIsBulkActivating(true);
		} else {
			setBulkStatusUpdateTitle(getBulkStatusUpdateModalTitle());
			setBulkStatusUpdateModalOpen(prev => !prev);
			setIsBulkActivating(false);
		}
	};

	useEffect(() => {
		initRuleLibrary();
	}, []);

	return (
		<>
			<CoAppDataGridHeader
				title="Rules"
				resourceType="Rule"
				addIsPresent={true}
				addOnClickHandler={handleAddRuleClick}
				editIsPresent={false}
				deleteIsPresent={false}
			/>
			<CoAppDataGrid
				rows={rules}
				columns={filterableColumns}
				checkboxSelection
				disableRowSelectionOnClick
				initialState={{
					...datagridInitialState,
					pinnedColumns: { left: ["actions", "__check__", "name"] }
				}}
				rowSelectionModel={selectionModel}
				onRowSelectionModelChange={handleRowSelectionChange}
				slots={{
					toolbar: CustomToolbar,
					noResultsOverlay: DataGridCustomNoRowsOverlay,
					noRowsOverlay: DataGridCustomNoRowsOverlay,
					openFilterButtonIcon: FilterAlt
				}}
				slotProps={{
					noResultsOverlay: { message: "No rules found." },
					noRowsOverlay: { message: "No rules found." },
					filterPanel: {
						filterFormProps: {
							logicOperatorInputProps: {
								size: "small",
							},
							columnInputProps: {
								size: "small",
							},
							operatorInputProps: {
								size: "small",
							},
							valueInputProps: {
								InputComponentProps: {
									size: "small",
								},
							},
						},
						sx: {
							"& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
							"& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
							"& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
							"& .MuiDataGrid-filterFormValueInput": { width: 200 }
						},
					},
				}}
				hideFooter
				disableColumnMenu
			/>
			<CoAppBulkApplyModal
				dialogOpen={bulkApplyModalOpen}
				dialogTitle={`Add/remove ${bulkApplyItemType.toLowerCase().includes("group") ? "groups" : "tags"} for selected rules (${selectionModel.length})`}
				cancelClickHandler={handleBulkApplyModalClose}
				confirmClickhandler={handleBulkApplyConfirmation}
				availableItems={bulkApplyAvailableItems}
				items={bulkApplyItems}
				selectedItemChangeHandler={handleAddOrRemoveItemChange}
				resource={bulkApplyItemType}
				targetResource="rules"
				selectedRules={selectionModel}
				totalRules={rules}
				itemsRemoved={bulkApplyRemovedItems}
				calculateItemsRemovedFromAll={calculateItemsRemovedFromAll}
				calculateItemsToAddToAll={calculateItemsToAddToAll}
				calculateUnchangedItems={calculateUnchangedItems}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={destructiveModalOpen}
				dialogTitle={`Delete ${ruleBeingDeleted.name} rule?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(ruleBeingDeleted.name, "rule")}
				confirmClickHandler={handleDeleteRule}
				cancelClickHandler={handleDeleteRuleToggle}
				actionText="Delete"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={bulkDestructiveModalOpen}
				dialogTitle={`Send selected (${selectionModel.length}) rules to the trash?`}
				dialogMessage="Are you sure you would like to send the selected rules within your organization to the trash? These can be restored later."
				confirmClickHandler={handleBulkSoftDeleteRulesConfirmation}
				cancelClickHandler={handleBulkSoftDeleteRuleToggle}
				actionText="Send to trash"
			/>
			<CoAppConfirmationModal
				dialogOpen={bulkStatusUpdateOpen}
				dialogTitle={bulkStatusUpdateTitle}
				dialogMessage={`Are you sure you would like to ${isBulkActivating ? "activate" : "deactivate"} the selected ${isBulkActivating ? "inactive" : "active"} rules within your organization?`}
				confirmClickHandler={handleBulkStatusUpdateConfirmation}
				cancelClickHandler={handleBulkStatusUpdateModalToggle}
				actionText={isBulkActivating ? "Activate" : "Deactivate"}
			/>
		</>
	);
}