import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

import { CoAppSettingsBackButton } from "../global/styled/global.styled";

export default function SettingsMenuBack( props ) {

	const navigate = useNavigate();

	const buttonText = props.buttonText;
	const navigateTo = props.navigateTo;

	return (
		<CoAppSettingsBackButton onClick={() => navigate(navigateTo)}><ArrowBack />{ buttonText }</CoAppSettingsBackButton>
	);
}