import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDescope } from "@descope/react-sdk";
import { Delete } from "@mui/icons-material";
import {
	FormControlLabel, Grid, Stack,
	Switch, Typography
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import prettifyDate from "../../../helpers/prettifyDate";
import useAuthHeader from "../../../helpers/useAuthHeader";
import useAuthorization from "../../../helpers/useAuthorization";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import { selectOrganizationSsoAndScim, selectUser, setPermissions } from "../../../redux/userSlice";
import {
	setFirstName, setGroups, setLastName,
	setOrgId, setPhone, setRoles,
	setUserId
} from "../../../redux/userSlice";
import CoAppIconTextButton from "../../global/components/coapp-icon-text-button";
import CoAppMultiSelect from "../../global/components/inputs/coapp-multiselect";
import CoAppTextField from "../../global/components/inputs/coapp-textfield";
import CoAppConfirmationModal from "../../global/components/modals/coapp-confirmation-modal";
import CoAppDestructiveConfirmationModal from "../../global/components/modals/coapp-destructive-confirmation-modal";
import Unauthorized from "../../global/components/unauthorized";
import { CoAppActionButton, CoAppCancelTextButton, CoAppProfileAvatar } from "../../global/styled/global.styled";

import { UserProfileNameText } from "./styled/user-profile.styled";

const initialUserDetails = {
	onLoadDetails: {
		active: null,
		firstName: "",
		lastName: "",
		roles: [],
		groups: []
	},
	onEditDetails: {
		active: null,
		firstName: "",
		lastName: "",
		roles: [],
		groups: []
	}
};
export default function UserProfile() {
	let authHeader = useAuthHeader();
	const isAuthorized = useAuthorization(["MANAGE_USERS_AND_GROUPS"]);
	const isSsoAndScimEnabled = useSelector(selectOrganizationSsoAndScim);
	const user = useSelector(selectUser);
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { logout } = useDescope();

	const [isEditing, setIsEditing] = useState(false);
	const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	const [availableGroups, setAvailableGroups] = useState([]);
	const [availableRoles, setAvailableRoles] = useState([]);
	const [email, setEmail] = useState("");
	const [lastExtensionLogin, setLastExtensionLogin] = useState("");
	const [userCreationDate, setUserCreationDate] = useState("");
	const [userDetails, setUserDetails] = useState(initialUserDetails);
	const [userUpdateSeed, setUserUpdateSeed] = useState(0);

	const initUserProfile = () => {
		const isSelf = user.userId === +id ? 1 : 0;
		axios.get(apiRoutes.getUser(isSelf, id, ""), { headers: authHeader })
			.then(async user => {
				let details = {
					onLoadDetails: {
						active: user.data.active,
						firstName: user.data.firstname,
						lastName: user.data.lastname,
						roles: user.data.roles,
						groups: user.data.groups.map(g => g.name),
					},
					onEditDetails: {
						active: user.data.active,
						firstName: user.data.firstname,
						lastName: user.data.lastname,
						roles: user.data.roles,
						groups: user.data.groups.map(g => g.name),
					}
				};
				setUserDetails(details);
				setEmail(user.data.email);

				let lastLogin = user.data.lastExtensionLogin === null ? "Never" : prettifyDate(user.data.lastExtensionLogin);
				setLastExtensionLogin(lastLogin);
				setUserCreationDate(prettifyDate(user.data.createdAt));

				if (isAuthorized) {
					initRolesAndGroupsForEditing();
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	const initRolesAndGroupsForEditing = () => {
		axios.get(apiRoutes.getGroups, { headers: authHeader })
			.then(res => {
				setAvailableGroups(res.data);
			})
			.catch(err => {
				console.log(err);
			});

		axios.get(apiRoutes.getRoles, { headers: authHeader })
			.then(res => {
				setAvailableRoles(res.data);
			})
			.catch(err => {
				console.log(err);
			});
	};

	/**
	 * User confirms account de-activation
	 * - Deactive user
	 * - If currently logged in user, log them out, redirect to login page.
	 * - If not currently logged in user, just de-activate user. 
	 */
	const handleDeactivateAccountClick = () => {
		let userJSON = {
			active: userDetails.onLoadDetails.active ? false : true
		};
		axios.put(apiRoutes.setUserActive + "/" + id, userJSON, {
			headers: authHeader
		}).then(() => {
			if (user.userId === +id) {
				logout();
				dispatch(setUserId(null));
				dispatch(setEmail(null));
				dispatch(setFirstName(null));
				dispatch(setLastName(null));
				dispatch(setPhone(null));
				dispatch(setRoles(null));
				dispatch(setOrgId(null));
				navigate(pages.login);
			} else {
				initUserProfile();
				handleToggleDisableDialog();
			}
		}).catch((err) => {
			console.log(err);
			return null;
		});
	};

	const handleDeleteAccountClick = () => {
		axios.delete(apiRoutes.deleteUser + "/" + id, {
			headers: authHeader
		}).then(() => {
			if (user.userId === +id) {
				logout();
				dispatch(setUserId(null));
				dispatch(setEmail(null));
				dispatch(setFirstName(null));
				dispatch(setLastName(null));
				dispatch(setPhone(null));
				dispatch(setRoles(null));
				dispatch(setOrgId(null));
				navigate(pages.login);
			} else {
				handleToggleDeleteDialog();
			}
		}).catch((err) => {
			console.log(err);
			return null;
		});
	};

	const getAvatarText = () => {
		if (!email) return null;
		if (userDetails.onLoadDetails.firstName && userDetails.onLoadDetails.lastName) {
			return userDetails.onLoadDetails.firstName[0].toUpperCase() + userDetails.onLoadDetails.lastName[0].toUpperCase();
		} else {
			return email[0].toUpperCase();
		}
	};

	const handleSaveChangesClick = () => {
		let userJSON = {};
		const { onLoadDetails, onEditDetails } = userDetails;

		if (onLoadDetails.firstName !== onEditDetails.firstName) {
			userJSON.firstName = onEditDetails.firstName;
		}
		if (onLoadDetails.lastName !== onEditDetails.lastName) {
			userJSON.lastName = onEditDetails.lastName;
		}
		if (JSON.stringify(onLoadDetails.roles) !== JSON.stringify(onEditDetails.roles)) {
			userJSON.roles = onEditDetails.roles;
		}
		if (JSON.stringify(onLoadDetails.groups) !== JSON.stringify(onEditDetails.groups)) {
			userJSON.groups = onEditDetails.groups;
		}

		if (Object.keys(userJSON).length > 0) {
			axios.put(apiRoutes.updateUser + "/" + id, userJSON, {
				headers: authHeader
			}).then((res) => {
				if (user.userId === + id) {
					dispatch(setFirstName(res.data.firstname));
					dispatch(setLastName(res.data.lastname));
					dispatch(setGroups(res.data.groups));
					dispatch(setRoles(res.data.roles));
					dispatch(setPermissions(res.data.permissions));
				}
				handleUpdateUserAlert("success");
			}).catch((err) => {
				handleUpdateUserAlert("error");
				setUserDetails({ ...userDetails, onEditDetails: userDetails.onLoadDetails });
				console.log(err);
				return null;
			});
		}
	};

	const handleUpdateUserAlert = (level) => {
		setUserUpdateSeed(Math.random());
		setIsEditing(false);
		dispatch(setOpen(true));
		dispatch(setMessage(level === "success" ? messages.USER_UPDATE_SUCCESS_MSG : messages.USER_UPDATE_ERROR_MSG));
		dispatch(setLevel(level));
	};

	const handleToggleDisableDialog = () => {
		setIsDisableDialogOpen(!isDisableDialogOpen);
	};

	const handleToggleDeleteDialog = () => {
		setIsDeleteDialogOpen(!isDeleteDialogOpen);
	};

	//Sort group and role arrays so the stringification comparison behaves as expected
	//Dress up a new object so we are not mutating the original array when we sort
	const userDetailsHaveChanged = (details) => {
		let temp = {
			...details,
			onEditDetails: {
				...details.onEditDetails,
				roles: [...details.onEditDetails.roles].sort(),
				groups: [...details.onEditDetails.groups].sort()
			},
			onLoadDetails: {
				...details.onLoadDetails,
				roles: [...details.onLoadDetails.roles].sort(),
				groups: [...details.onLoadDetails.groups].sort()
			}
		};
		return JSON.stringify(temp.onLoadDetails) !== JSON.stringify(temp.onEditDetails);
	};

	const handleMultiselectChange = (e, value, reason, field) => {
		const newDetails = { ...userDetails, onEditDetails: { ...userDetails.onEditDetails, [field]: value } };
		if (userDetailsHaveChanged(newDetails)) {
			setIsEditing(true);
		} else {
			setIsEditing(false);
		}
		setUserDetails(newDetails);
	};

	const handleValueChange = (e, field) => {
		const newDetails = { ...userDetails, onEditDetails: { ...userDetails.onEditDetails, [e.target.id !== undefined ? e.target.id : field]: e.target.value } };
		if (userDetailsHaveChanged(newDetails)) {
			setIsEditing(true);
		} else {
			setIsEditing(false);
		}
		setUserDetails(newDetails);
	};

	const handleCancelButtonClick = () => {
		setUserDetails({ ...userDetails, onEditDetails: userDetails.onLoadDetails });
		setIsEditing(false);
	};

	useEffect(() => {
		if (isAuthorized || user.userId === +id) {
			initUserProfile();
		}
	}, [userUpdateSeed]);

	if (isAuthorized || user.userId === +id) {
		return (
			<Grid container spacing={1}>
				<Grid container justifyContent="space-between">
					<Stack direction="row" spacing={1} sx={{ margin: "25px" }}>
						<CoAppProfileAvatar h="60px" w="60px" fs="28px">
							{getAvatarText()}
						</CoAppProfileAvatar>
						<UserProfileNameText>{userDetails.onLoadDetails.firstName && userDetails.onLoadDetails.lastName ? `${userDetails.onLoadDetails.firstName} ${userDetails.onLoadDetails.lastName}` : null}</UserProfileNameText>
					</Stack>
					<FormControlLabel
						sx={{ marginRight: "10px" }}
						labelPlacement="start"
						label={<Typography variant="body1">{userDetails.onLoadDetails.active ? "Active" : "Inactive"}</Typography>}
						control={
							<Switch
								checked={userDetails.onLoadDetails.active}
								onClick={handleToggleDisableDialog}
								disabled={!isAuthorized || isSsoAndScimEnabled}
							/>
						}
					/>
				</Grid>
				<Grid container sx={{ marginLeft: "30px" }}>
					<Grid item xxl={2.5} xl={3} lg={2.7} md={3}>
						<CoAppTextField
							sx={{ width: { xxl: "250px", xl: "220px", lg: "170px", md: "150px" } }}
							id="firstName"
							label="First Name"
							onChange={handleValueChange}
							value={userDetails.onEditDetails.firstName}
							variant="standard"
							disabled={!isAuthorized || isSsoAndScimEnabled}
						/>
					</Grid >
					<Grid item xxl={4} xl={3.5} lg={3.8} md={3.5}>
						<CoAppTextField
							sx={{ width: { xxl: "250px", xl: "220px", lg: "170px", md: "150px" } }}
							id="lastName"
							variant="standard"
							label="Last Name"
							value={userDetails.onEditDetails.lastName}
							onChange={handleValueChange}
							disabled={!isAuthorized || isSsoAndScimEnabled}
						/>
					</Grid>
					<Grid item xl={5} md={5}>
						<CoAppTextField
							sx={{ width: { xxl: "565px", xl: "565px", lg: "420px", md: "380px" } }}
							disabled={true}
							id="email"
							label="Email"
							isemail="true"
							value={email}
							variant="standard"
						/>
					</Grid>
				</Grid>
				<Grid container sx={{ marginLeft: "30px", marginTop: "50px" }} alignItems="flex-end">
					<Grid item xs={6.5}>
						<CoAppMultiSelect
							chipSize="small"
							customStyles={{ width: { xxl: "565px", xl: "565px", lg: "420px", md: "380px" } }}
							disabled={!isAuthorized || isSsoAndScimEnabled}
							label="Roles"
							options={availableRoles.map(role => role.name)}
							value={userDetails.onEditDetails.roles}
							onChange={(e, value, reason) => handleMultiselectChange(e, value, reason, "roles")}
						/>
					</Grid>
					<Grid item xs={5.5}>
						<CoAppMultiSelect
							chipSize="small"
							customStyles={{ width: { xxl: "565px", xl: "565px", lg: "420px", md: "380px" } }}
							disabled={!isAuthorized || isSsoAndScimEnabled}
							label="Groups"
							options={availableGroups.map(role => role.name)}
							value={userDetails.onEditDetails.groups}
							onChange={(e, value, reason) => handleMultiselectChange(e, value, reason, "groups")}
						/>
					</Grid>
				</Grid>
				<Grid container sx={{ marginLeft: "20px", marginTop: "50px", position: "relative" }} wrap="nowrap">
					<Grid item xs={3.5} md={2.5} xxl={4} xl={4}>
						<CoAppIconTextButton
							icon={<Delete fontSize="small" sx={{ marginRight: "5px" }} />}
							text="Delete account"
							onClick={handleToggleDeleteDialog}
							isDestructive={true}
						/>
					</Grid>
					<Grid item xs={8} md sx={{ marginTop: "10px" }}>
						<Stack direction="row" spacing={3}>
							<Typography variant="body2" color="text.secondary">Last extension heartbeat: {lastExtensionLogin.toString().replace(",", " at ")}</Typography>
							<Typography variant="body2" color="text.secondary">Created: {userCreationDate.toString().replace(",", " at ")}</Typography>
						</Stack>
					</Grid>
					<Grid sx={{ display: isEditing ? "flex" : "none", position: "absolute", right: 20 }}>
						<CoAppCancelTextButton onClick={handleCancelButtonClick}>Cancel</CoAppCancelTextButton>
						<CoAppActionButton onClick={handleSaveChangesClick}>Save</CoAppActionButton>
					</Grid>
				</Grid>
				<CoAppDestructiveConfirmationModal
					dialogOpen={isDisableDialogOpen && userDetails.onLoadDetails.active}
					dialogMessage={`Are you sure you want to disable ${email}? The user will no longer be able to log in once disabled.`}
					dialogTitle={`Disable ${email}`}
					confirmClickHandler={handleDeactivateAccountClick}
					cancelClickHandler={handleToggleDisableDialog}
					actionText="Disable"
				/>
				<CoAppConfirmationModal
					dialogOpen={isDisableDialogOpen && !userDetails.onLoadDetails.active}
					dialogMessage={`Are you sure you want to enable ${email}? The user will be able to log in once enabled.`}
					dialogTitle={`Enable ${email}`}
					confirmClickHandler={handleDeactivateAccountClick}
					cancelClickHandler={handleToggleDisableDialog}
					actionText="Enable"
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={isDeleteDialogOpen}
					dialogMessage={`Are you sure you want to delete ${email}? The user will no longer exist in your organization once deleted.`}
					dialogTitle={`Delete ${email}`}
					confirmClickHandler={handleDeleteAccountClick}
					cancelClickHandler={handleToggleDeleteDialog}
					actionText="Delete"
				/>
			</Grid >
		);
	} else {
		return (
			<Unauthorized />
		);
	}
}