import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
	CircularProgress, Container, Stack
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import CoAppDataGridDateTimeCell from "../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../global/components/datagrid/coapp-datagrid-header";
import CoAppStandardDataGrid from "../../global/components/datagrid/coapp-standard-datagrid";
import CoAppKillswitchModal from "../../global/components/modals/coapp-killswitch-modal";

import { WorkstationSwitch } from "./styled/workstation.styled";
import WorkstationDataGridSelectionRow from "./workstation-datagrid-selection-row";

export default function WorkstationManagement() {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();

	const [workstationsSelected, setWorkstationsSelected] = useState([]);
	const [workstationKillswitchDialogOpen, setWorkstationKillswitchDialogOpen] = useState(false);
	const [workstationsLoaded, setWorkstationsLoaded] = useState(false);
	const [workstations, setWorkstations] = useState([]);
	const [workstationsForDisplayInModal, setWorkstationsForDisplayInModal] = useState([]);
	const [actionType, setActionType] = useState("Activate");
	const columns = [
		{
			field: "serialNumber",
			headerName: "Serial Number",
			flex: 1,
			editable: false,
		},
		{
			field: "killSwitchActive",
			headerName: "Kill Switch Status",
			flex: 1,
			editable: true,
			type: "boolean",
			renderCell: (params) => (
				<WorkstationSwitch
					checked={params.row.killSwitchActive}
					disabled={false}
					onChange={(e) => handleKillSwitchModalToggle(params.row, params.row.killSwitchActive ? true : false)}
				/>
			)
		},
		{
			field: "hostName",
			headerName: "Computer Name",
			flex: 1,
			editable: false,
		},
		{
			field: "ip",
			headerName: "Internal IP",
			flex: 1,
			editable: false,
		},
		{
			field: "macAddress",
			headerName: "Mac Address",
			flex: 1,
			editable: false,
		},
		{
			field: "lastLogUpload",
			headerName: "Last Log Upload",
			flex: 1,
			editable: false,
			valueGetter: (params) => new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.value} />
			)
		},
		{
			field: "lastPhoneHome",
			headerName: "Last extension heartbeat",
			flex: 1,
			editable: false,
			valueGetter: (params) => new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.value} />
			)
		}
	];

	const handleKillSwitchModalToggle = (workstation = null, isDeactivate = false) => {
		if (workstation) {
			setWorkstationsForDisplayInModal([workstation]);
			setWorkstationKillswitchDialogOpen(true);
			setActionType(isDeactivate ? "Deactivate" : "Activate");
		} else {
			let workstationsForDisplay = [];
			workstationsSelected.forEach((selectedUUID) => {
				let workstation = workstations.filter(workstation => {
					if (isDeactivate) {
						return selectedUUID === workstation.uuid && workstation.killSwitchActive;
					} else {
						return selectedUUID === workstation.uuid && !workstation.killSwitchActive;
					}
				});
				if (workstation.length > 0) {
					workstationsForDisplay.push(workstation[0]);
				}
			});
			setWorkstationsForDisplayInModal(workstationsForDisplay);
			setWorkstationKillswitchDialogOpen(true);
			setActionType(isDeactivate ? "Deactivate" : "Activate");
		}
	};


	const initWorkstationManagement = () => {
		axios.get(apiRoutes.getWorkstations, { headers: authHeader })
			.then((res) => {
				setWorkstations(res.data);
				setWorkstationsLoaded(true);
			}).catch((err) => {
				console.log(err);
				setWorkstationsLoaded(true);
			});
	};

	const handleBulkWorkstationStatusToggle = () => {
		let workstationJSON = {
			newStatus: actionType === "Deactivate" ? false : true,
			workstationUUIDs: workstationsForDisplayInModal.map(workstation => workstation.uuid),
		};
		axios.put(apiRoutes.bulkWorkstationKillSwitchToggle, workstationJSON, {
			headers: authHeader
		}).then(() => {
			initWorkstationManagement();
			setWorkstationKillswitchDialogOpen(false);
			setWorkstationsSelected([]);
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.SUCCESS));
			dispatch(setMessage(messages.WORKSTATION_STATUS_TOGGLE_SUCCESS_MSG));
		})
			.catch((error) => {
				console.error(error);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.WORKSTATION_STATUS_TOGGLE_ERROR_MSG));
			});
	};

	const handleWorkstationStatusToggle = () => {
		axios.put(apiRoutes.workstationKillSwitch(workstationsForDisplayInModal[0].uuid), { newStatus: actionType === "Deactivate" ? false : true }, { headers: authHeader })
			.then(() => {
				initWorkstationManagement();
				setWorkstationKillswitchDialogOpen(false);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(messages.WORKSTATION_STATUS_TOGGLE_SUCCESS_MSG));
			})
			.catch((error) => {
				console.error(error);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(messages.WORKSTATION_STATUS_TOGGLE_ERROR_MSG));
			});
	};

	const getWorkstationBulkActionText = () => {
		let text = "Activate";
		if (workstationsSelected.length >= 1) {
			let shouldDeactivate = workstationsSelected.every((uuid) => {
				let workstation = workstations.filter(workstation => workstation.uuid === uuid);
				if (!workstation[0].killSwitchActive) {
					return false;
				} else {
					return true;
				}
			});
			if (shouldDeactivate) {
				text = "Deactivate";
			}
		}
		if (workstationsForDisplayInModal.length === 1) {
			if (workstationsForDisplayInModal[0].killSwitchActive) {
				text = "Deactivate";
			}
		}
		return text;
	};

	useEffect(() => {
		initWorkstationManagement();
	}, []);

	if (workstationsLoaded && workstations) {
		return (
			<>
				<CoAppDatagridHeader
					title="Workstations"
					addIsPresent={false}
					editIsPresent={false}
					deleteIsPresent={false}
				/>
				<CoAppStandardDataGrid
					columns={columns}
					rows={workstations}
					pinnedColumns={["__check__", "serialNumber", "killSwitchActive"]}
					allowSelection={true}
					targetResource="workstations"
					selectionModel={workstationsSelected}
					setSelectionModel={setWorkstationsSelected}
					handleModalToggle={() => handleKillSwitchModalToggle()}
					bulkActionText={getWorkstationBulkActionText()}
					customSelectionRow={
						<WorkstationDataGridSelectionRow
							shouldShow={workstationsSelected.length > 0}
							selectedItems={workstationsSelected}
							workstations={workstations}
							onClearClickHandler={() => setWorkstationsSelected([])}
							bulkActivateHandler={() => handleKillSwitchModalToggle(null, false)}
							bulkDeactivateHandler={() => handleKillSwitchModalToggle(null, true)}
						/>
					}
				/>
				<CoAppKillswitchModal
					dialogOpen={workstationKillswitchDialogOpen}
					dialogTitle={workstationsSelected.length > 1 ? `${getWorkstationBulkActionText()} kill switch on selected workstations?` : `${getWorkstationBulkActionText()} workstation kill switch?`}
					dialogMessage={messages.WORKSTATION_KILLSWITCH_MSG(workstationsSelected.length, workstationsForDisplayInModal[0]?.killSwitchActive)}
					confirmClickHandler={
						workstationsForDisplayInModal.length > 1 ?
							() => handleBulkWorkstationStatusToggle()
							:
							() => handleWorkstationStatusToggle()
					}
					cancelClickHandler={() => setWorkstationKillswitchDialogOpen(false)}
					actionText={`${actionType} Kill Switch`}
					workstations={workstationsForDisplayInModal}
					isOrganizationWide={false}
				/>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}