import { configureStore } from "@reduxjs/toolkit";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import alertReducer from "./alertSlice";
import navigationReducer from "./navigationSlice";
import ruleWizardReducer from "./ruleWizardSlice";
import scimWizardReducer from "./scimWizardSlice";
import userReducer from "./userSlice";

const persistConfig = {
	key: "root",
	version: 1,
	storage,
};

const rootReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
	reducer: {
		alert: alertReducer,
		navigation: navigationReducer,
		ruleWizard: ruleWizardReducer,
		scimWizard: scimWizardReducer,
		user: rootReducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

/**
 * Setup for testing comps that manipulate redux state.
 */

export const setupStore = (preloadedState) => {
	return configureStore({
		reducer: {
			alert: alertReducer,
			navigation: navigationReducer,
			ruleWizard: ruleWizardReducer,
			scimWizard: scimWizardReducer,
			user: rootReducer
		},
		preloadedState,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				},
			}),
	});
};

export let persistor = persistStore(store);
export default store;