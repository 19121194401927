import { styled } from "@mui/material";

export const PermissionCategoryTitle = styled("div")(() => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	margin: "30px 30px 15px 20px"
}));

export const PermissionNameDescContainer = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
}));

export const RoleTitle = styled("div")(() => ({
	fontSize: "1rem",
	marginLeft: "10px",
	fontWeight: "600"
}));

export const RoleSubtitle = styled("div")(() => ({
	color: "#A2A2A2",
	fontSize: ".75rem",
	marginLeft: "10px",
	fontWeight: "400"
}));

export const PermissionGroupContainer = styled("div")(() => ({
	marginLeft: "25px"
}));

export const PermissionsContainer = styled("div")(({ theme }) => ({
	overflowY: "auto",
	height: "715px",
	[theme.breakpoints.between(1600, 1900)]: {
		height: "780px"
	},
	[theme.breakpoints.between(1500, 1600)]: {
		height: "610px"
	},
	[theme.breakpoints.between(1000, 1400)]: {
		height: "600px"
	},
	"&::-webkit-scrollbar": {
		backgroundColor: "#D7EBE5",
		width: "15px",
		borderRadius: "0px 0px 14px 0px"
	},
	"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
		borderRadius: "8px",
		backgroundColor: "#BDBDBD",
		width: "7px",
		border: "4px solid transparent",
		backgroundClip: "content-box"
	}
}));