import { Typography } from "@mui/material";

import { ScimWizardHeaderContainer, ScimWizardHeaderDivider } from "./styled/scim-wizard.styled";

export default function ScimWizardHeader({ title, count }) {
	return (
		<ScimWizardHeaderContainer>
			<Typography variant="h1" sx={{ marginLeft: "95px" }}>{count ? `${title} (${count})` : title}</Typography>
			<ScimWizardHeaderDivider />
		</ScimWizardHeaderContainer>
	);
}