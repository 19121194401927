import { Add, Delete, Edit } from "@mui/icons-material";
import { Grid, Stack, Typography } from "@mui/material";

import { CoAppDataGridHeaderTitle } from "../../styled/global.styled";
import CoAppIconActionButton from "../inputs/coapp-icon-action-button";
import CoAppIconTextButton from "../inputs/coapp-icon-text-button";

export default function CoAppDatagridHeader(props) {
	const {
		title, resourceType, addOnClickHandler,
		editOnClickHandler, deleteOnClickHandler, addIsPresent,
		editIsPresent, deleteIsPresent, subResourceType,
		hasDescription = false, description = ""
	} = props;

	return (
		<Grid container justifyContent="space-between" sx={{ borderBottom: "1px solid lightgrey", padding: "20px" }}>
			<Grid item xxl={9.6} xl={9} lg={8} md={7} sm={5}>
				<Stack direction="row" spacing={3} alignItems="center">
					<CoAppDataGridHeaderTitle
						variant="h1"
						color="text.primary"
						title={title}
					>
						{title}
					</CoAppDataGridHeaderTitle>
					{
						addIsPresent ?
							<div>
								<CoAppIconActionButton icon={<Add sx={{ fontSize: "16px", marginRight: "5px" }} />} text={`${resourceType === "Rule" ? "Build" : "Add"} ${subResourceType ? subResourceType : resourceType}`} onClick={addOnClickHandler} />
							</div>
							:
							null
					}
				</Stack>
				{
					hasDescription ? <Typography sx={{ marginTop: "18px" }} variant="body1" color="text.primary">{description}</Typography> : null
				}
			</Grid>
			<Grid item xxl={2.4} xl={3} lg={3.5} md={5} sm={5}>
				{
					editIsPresent ? <CoAppIconTextButton icon={<Edit sx={{ fontSize: "16px", marginRight: "5px" }} />} text={`Edit ${resourceType} name`} onClick={editOnClickHandler} /> : null
				}
				{
					deleteIsPresent ? <CoAppIconTextButton icon={<Delete sx={{ fontSize: "16px", marginRight: "5px" }} />} text={`Delete ${resourceType}`} onClick={deleteOnClickHandler} isDestructive={true} /> : null
				}
			</Grid>
		</Grid >
	);
}