import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Container, Stack } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import { selectNavState, selectRoleChildren, setNavState } from "../../../redux/navigationSlice";
import { selectOrganizationIdentityProvider, selectOrganizationSsoAndScim } from "../../../redux/userSlice";
import CoAppDatagridHeader from "../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../../global/components/datagrid/coapp-datagrid-list-cell";
import CoAppDataGridNameCell from "../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppStandardDataGrid from "../../global/components/datagrid/coapp-standard-datagrid";
import CoAppCreateGroupOrRoleModal from "../../global/components/modals/coapp-create-group-or-role-modal";
import CoAppDestructiveConfirmationModal from "../../global/components/modals/coapp-destructive-confirmation-modal";
import ThreeDotMenu from "../../global/components/three-dot-menu";

export default function RoleManagement() {
	let authHeader = useAuthHeader();
	const navState = useSelector(selectNavState);
	const roleChildren = useSelector(selectRoleChildren);
	const identityProviderName = useSelector(selectOrganizationIdentityProvider);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [newRoleName, setNewRoleName] = useState("");
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const [roleCreationDialogOpen, setRoleCreationDialogOpen] = useState(false);
	const [roleDeleteDialogOpen, setRoleDeleteDialogOpen] = useState(false);
	const [roleSeed, setRoleSeed] = useState(0);
	const [roleBeingDeleted, setRoleBeingDeleted] = useState({});
	const [rolesLoaded, setRolesLoaded] = useState(false);
	const [roleNameError, setRoleNameError] = useState("");
	const [roles, setRoles] = useState([]);
	const [selectedRoles, setSelectedRoles] = useState([]);
	const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);

	const columns = [
		{
			field: "name",
			headerName: "Name",
			editable: false,
			flex: .5,
			renderCell: (params) => (
				<CoAppDataGridNameCell item={params.row} />
			),
		},
		{
			field: "users",
			headerName: "Users",
			editable: false,
			flex: .5,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value} resource="Users" isUser={true} width={225} />
			)
		},
		{
			field: "permissions",
			headerName: "Permissions",
			editable: false,
			flex: 1.25,
			renderCell: (params) => {
				let permissionsWithFriendlyNames = params.value.map(permission => { return { name: permission.friendlyName }; });
				return (<CoAppDataGridListCell items={permissionsWithFriendlyNames} resource="Permissions" />);
			}
		},
		...(organizationSsoAndScim
			? [
				{
					field: "idpgroups",
					headerName: `${identityProviderName} Groups`,
					editable: false,
					flex: 1.25,
					renderCell: (params) => (
						<CoAppDataGridListCell items={params.value} resource={`${identityProviderName} Groups`} />
					),
				},
			]
			: []),
		{
			field: "actions",
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<div>
					<ThreeDotMenu
						options={[
							{
								name: "Edit",
								optionClickHandler: () => {
									handleOpenRoleEditPage({ params });
								},
							},
							{
								name: "Duplicate",
								optionClickHandler: () => {
									duplicateRole(params.row.id);
								},
							},
							{
								name: "Delete",
								optionClickHandler: () => {
									setRoleBeingDeleted(params.row);
									setRoleDeleteDialogOpen(true);
								},
							},
						]}
					/>
				</div>
			),
		},
	];

	const handleRuleNameChange = (e) => {
		setNewRoleName(e.target.value);
		setRoleNameError("");
	};

	const createNewRole = () => {
		if (!newRoleName) {
			setRoleNameError("Required *");
			return;
		}

		let role = { name: newRoleName };

		axios.post(apiRoutes.createRole, role, { headers: authHeader })
			.then((res) => {
				if (res.status === 200) {
					initRoleManagement();
					dispatch(setMessage(messages.ROLE_CREATED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
				} else {
					dispatch(setMessage(messages.ROLE_CREATION_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
				toggleAddNewDialog();
			})
			.catch((err) => {
				if (err.response.data.message.includes("already exists")) {
					setRoleNameError(messages.RESOURCE_ALREADY_EXISTS_ERROR_MSG("Role"));
					return;
				}
				toggleAddNewDialog();
				dispatch(setMessage(messages.ROLE_CREATION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				toggleAddNewDialog();
			});
	};

	const deleteRole = () => {
		axios.delete(apiRoutes.deleteRole + "/" + roleBeingDeleted.id, { headers: authHeader })
			.then((res) => {
				if (res.status === 200) {
					dispatch(setMessage(messages.ROLE_DELETION_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
					setRoleSeed(Math.random());
				} else {
					dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
				handleCloseRoleDeletionDialog();
			})
			.catch((err) => {
				console.log(err);
				dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				handleCloseRoleDeletionDialog();
			});
	};

	const duplicateRole = (id) => {
		axios.post(apiRoutes.copyRole(id), {}, { headers: authHeader })
			.then((res) => {
				if (res.status === 200) {
					dispatch(setMessage(messages.ROLE_DUPLICATION_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
					setRoleSeed(Math.random());
				} else {
					dispatch(setMessage(messages.ROLE_DUPLICATION_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((err) => {
				console.log(err);
				dispatch(setMessage(messages.ROLE_DUPLICATION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR
				));
			});
	};

	const handleOpenRoleEditPage = (rowObject) => {
		let oldNavState = [...navState];
		oldNavState[8] = { // Roles is the 8th item in the nav list
			...oldNavState[8],
			children: [
				{
					id: Math.random(),
					name: rowObject.params.row.name,
					children: [],
					isGroupingLabel: true
				},
				...roleChildren.map((child) => {
					if (child.name === "Add/Remove IdP Groups" && organizationSsoAndScim) {
						return {
							...child,
							name: `Add/Remove ${identityProviderName} Groups`,
							page: handlePageGeneration(child.name, rowObject.params.id),
							keepOpen: true,
							isClickable: true
						};
					} else {
						return {
							...child,
							page: handlePageGeneration(child.name, rowObject.params.id),
							keepOpen: true,
							isClickable: true
						};
					}
				}).filter(child => {
					if (!organizationSsoAndScim && child.name.includes("Add/Remove")) {
						return false;
					} else {
						return true;
					}
				})
			],
			isParentChangeEvent: true
		};
		dispatch(setNavState(oldNavState));
		navigate(pages.roleManagement);
	};

	const handlePageGeneration = (pageName, roleId) => {
		switch (pageName) {
			case "Permissions":
				return pages.rolePermissions(roleId);
			case "Users":
				return pages.roleUsers(roleId);
			case "Add/Remove IdP Groups":
				return pages.roleSSO(roleId);
			default:
				return "/settings/roles";
		}
	};

	const handleCloseRoleDeletionDialog = () => {
		setRoleBeingDeleted({});
		setRoleDeleteDialogOpen(false);
	};

	const initRoleManagement = () => {
		axios.get(apiRoutes.getRoles, { headers: authHeader })
			.then((res) => {
				setRoles(res.data);
				setRolesLoaded(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const toggleAddNewDialog = () => {
		setRoleCreationDialogOpen(!roleCreationDialogOpen);
		setNewRoleName("");
		setRoleNameError("");
	};

	useEffect(() => {
		initRoleManagement();
	}, [roleSeed]);

	if (rolesLoaded) {
		return (
			<>
				<CoAppDatagridHeader
					title="Roles"
					resourceType="Role"
					addIsPresent={true}
					addOnClickHandler={toggleAddNewDialog}
					editIsPresent={false}
					deleteIsPresent={false}
				/>
				<CoAppStandardDataGrid
					columns={columns}
					rows={roles}
					pinnedColumns={["actions", "__check__", "name", "status"]}
					targetResource="roles"
					selectionModel={selectedRoles}
					setSelectionModel={setSelectedRoles}
					handleModalToggle={() => setBulkDeleteDialogOpen(true)}
				/>
				<CoAppCreateGroupOrRoleModal
					cancelClickHandler={toggleAddNewDialog}
					changeHandler={handleRuleNameChange}
					dialogOpen={roleCreationDialogOpen}
					nameError={roleNameError}
					confirmClickHandler={createNewRole}
					isRole={true}
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={roleDeleteDialogOpen}
					dialogTitle={`Delete ${roleBeingDeleted.name} role?`}
					dialogMessage={messages.DELETION_CONFIRMATION_MSG(roleBeingDeleted.name, "role")}
					confirmClickHandler={deleteRole}
					cancelClickHandler={handleCloseRoleDeletionDialog}
					actionText="Delete"
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={bulkDeleteDialogOpen}
					dialogTitle={`Delete selected ${selectedRoles.length > 1 ? "roles" : "role"}?`}
					dialogMessage={"Are you sure you want to bulk delete all the selected roles? This action cannot be undone."}
					confirmClickHandler={() => console.log("Bulk deletion not implemented yet")}
					cancelClickHandler={() => setBulkDeleteDialogOpen(false)}
					actionText="Delete"
				/>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}
