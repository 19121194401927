import { Descope } from "@descope/react-sdk";

import { ScimWizardDescopeFlowContainer, ScimWizardStepContainer } from "../styled/scim-wizard.styled";

export default function Step1(props) {

	const handleSuccessfulConfiguration = () => {
		props.setIdpConfigComplete(true);
	};

	return (
		<ScimWizardStepContainer>
			<ScimWizardDescopeFlowContainer>
				<Descope
					flowId="saml-config"
					onSuccess={handleSuccessfulConfiguration}
					autoFocus={false}
					onError={(e) => console.log("SSO + SCIM configuration failed due to: " + e)}
					form={{ "secretKey": props.tenantDetails.secretScimKey, "tenantId": props.tenantDetails.tenantId }}
				/>
			</ScimWizardDescopeFlowContainer>
		</ScimWizardStepContainer>
	);
}