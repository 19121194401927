import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Sync } from "@mui/icons-material";
import { Divider, IconButton, Stack, Switch, Typography } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import useAuthorization from "../../../helpers/useAuthorization";
import { selectUser } from "../../../redux/userSlice";
import {
	selectOrganizationForceLogin,
	selectOrganizationIdentityProvider,
	selectOrganizationKillSwitch,
	selectOrganizationSsoAndScim,
	setOrganizationForceLogin,
	setOrganizationKillSwitch,
	setOrganizationSsoAndScim
} from "../../../redux/userSlice";
import ConfirmationDialog from "../../global/components/confirmation-dialog";
import CoAppConfirmationModal from "../../global/components/modals/coapp-confirmation-modal";
import CoAppDestructiveConfirmationModal from "../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppKillswitchModal from "../../global/components/modals/coapp-killswitch-modal";
import { CoAppActionButton, CoAppLightTooltip, CoAppWarningIcon } from "../../global/styled/global.styled";
import { WorkstationSwitch } from "../workstations/styled/workstation.styled";

import {
	OrganizationManagementStack
} from "./styled/organization-management.styled";

export default function OrganizationManagement() {
	const isAuthorizedForSsoAndScim = useAuthorization(["MANAGE_SSOSCIM"], false);
	const isAuthorizedForForceLogin = useAuthorization(["MANAGE_ORG_FORCELOGIN"], false);
	const isAuthorizedForKillSwitch = useAuthorization(["MANAGE_ORG_KILLSWITCH"], false);
	let dispatch = useDispatch();
	let navigate = useNavigate();
	let authHeader = useAuthHeader();
	const currentUser = useSelector(selectUser);
	const organizationForceLogin = useSelector(selectOrganizationForceLogin);
	const identityProviderName = useSelector(selectOrganizationIdentityProvider);
	const organizationKillSwitch = useSelector(selectOrganizationKillSwitch);
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const [confirmationDialogContent, setConfirmationDialogContent] = useState({ dialogOpen: false });
	const [disableSsoConfirmationOpen, setDisableSsoConfirmationOpen] = useState(false);
	const [ssoAndScimConfiguredDate, setSsoAndScimConfiguredDate] = useState(null);
	const [forceLogin, setForceLogin] = useState(false);
	const [killSwitch, setKillSwitch] = useState(false);

	const [forceLoginIsToggled, setForceLoginIsToggled] = useState(false);
	const [killSwitchIsToggled, setKillSwitchIsToggled] = useState(false);

	const getSsoAndScimStatus = () => {
		axios.get(apiRoutes.ssoAndScim(currentUser.organizationId), {
			headers: authHeader
		}).then((res) => {
			dispatch(setOrganizationSsoAndScim(res.data.status));
			setSsoAndScimConfiguredDate(res.data.configuredOn);
		}).catch((err) => {
			console.log(err);
			return null;
		});
	};

	const configureSsoAndScim = () => {
		navigate(pages.scimWizard);
	};

	const confirmSsoAndScimConfiguration = () => {
		let dialogContent = {
			dialogOpen: true,
			confirmationMessage: "",
			confirmationListItems: [
				messages.SCIM_SETUP_MESSAGE_ONE,
				messages.SCIM_SETUP_MESSAGE_TWO,
				messages.SCIM_SETUP_MESSAGE_THREE,
				messages.SCIM_SETUP_MESSAGE_FOUR,
				messages.SCIM_SETUP_MESSAGE_FIVE
			],
			confirmationTitle: "Things to know before you proceed:",
			confirmClickHandler: () => {
				configureSsoAndScim();
			},
			cancelClickHandler: () => {
				setConfirmationDialogContent({});
			}
		};
		setConfirmationDialogContent(dialogContent);
	};

	const handleToggleDisableSsoAndScim = () => {
		setDisableSsoConfirmationOpen(!disableSsoConfirmationOpen);
	};

	const disableSsoAndScim = () => {
		axios.delete(apiRoutes.ssoAndScim(currentUser.organizationId), { headers: authHeader })
			.then(res => {
				navigate(pages.organizationManagement);
				dispatch(setOrganizationSsoAndScim(false));
				handleToggleDisableSsoAndScim();
			})
			.catch(err => console.error(err));
	};

	const getForceLoginStatus = () => {
		if (organizationForceLogin === undefined) {
			axios.get(apiRoutes.forceLogin(currentUser.organizationId), {
				headers: authHeader
			}).then((res) => {
				dispatch(setOrganizationForceLogin(res.data));
				setForceLogin(res.data);
				setForceLoginIsToggled(false);
			}).catch((err) => {
				console.log(err);
				setForceLogin(false);
			});
		} else {
			setForceLogin(organizationForceLogin);
		}
	};

	const confirmForceLoginToggle = () => {
		let forceLogin = organizationForceLogin ? false : true;

		let orgJSON = {
			forceLogin: forceLogin
		};

		axios.put(apiRoutes.forceLogin(currentUser.organizationId), orgJSON, {
			headers: authHeader
		}).then(() => {
			dispatch(setOrganizationForceLogin(forceLogin));
			setForceLoginIsToggled(false);
		}).catch((err) => {
			console.log(err);
			return null;
		});
	};

	const handleForceLoginSwitchToggle = () => {
		setForceLoginIsToggled(!forceLoginIsToggled);
	};

	const getKillSwitchStatus = () => {
		if (organizationKillSwitch === undefined) {
			axios.get(apiRoutes.killSwitch(currentUser.organizationId), {
				headers: authHeader
			}).then((res) => {
				dispatch(setOrganizationKillSwitch(res.data));
				setKillSwitchIsToggled(false);
			}).catch((err) => {
				console.log(err);
				return null;
			});
		} else {
			setKillSwitch(organizationKillSwitch);
		}
	};

	const confirmKillSwitchToggle = () => {
		let killSwitch = organizationKillSwitch ? false : true;

		let orgJSON = {
			killswitch: killSwitch
		};

		axios.put(apiRoutes.killSwitch(currentUser.organizationId), orgJSON, {
			headers: authHeader
		}).then((res) => {
			dispatch(setOrganizationKillSwitch(killSwitch));
			setKillSwitch(res.data);
			setKillSwitchIsToggled(false);
		}).catch((err) => {
			console.log(err);
			setKillSwitch(false);
			return null;
		});
	};

	const handleKillSwitchToggle = () => {
		setKillSwitchIsToggled(killSwitchIsToggled ? false : true);
	};

	const syncUsers = () => {
		axios.post(apiRoutes.sync(currentUser.organizationId), {}, {
			headers: authHeader

		}).then(() => {
			console.log("Syncing users");
		}).catch((err) => {
			console.log(err);
			return null;
		});
	};

	useEffect(() => {
		if (isAuthorizedForForceLogin)
			getForceLoginStatus();
		if (isAuthorizedForKillSwitch)
			getKillSwitchStatus();
		if (isAuthorizedForSsoAndScim)
			getSsoAndScimStatus();
	}, [organizationKillSwitch, organizationForceLogin, organizationSsoAndScim]);

	return (
		<>
			<OrganizationManagementStack spacing={3} >
				<Typography variant="h1" color="text.primary">Organization Settings</Typography>
				<Typography variant="body1" color="text.primary">Manage organization-wide settings.</Typography>
				<Divider sx={{ width: { xxl: "50%", xl: "60%", lg: "60%", md: "70%" } }} />
				<Stack direction="row" spacing={1} alignItems="center" sx={{ paddingLeft: "10px" }}>
					{
						organizationSsoAndScim ?
							<>
								<Typography variant="h3" color="text.primary">SSO + SCIM</Typography>
								<IconButton
									onClick={syncUsers}
									color="primary"
								>
									<Sync />
								</IconButton>
								<CoAppLightTooltip title="Deactivate SSO + SCIM" placement="right">
									<Switch
										checked={true}
										onChange={handleToggleDisableSsoAndScim}
									/>
								</CoAppLightTooltip>
							</>
							:
							<CoAppActionButton sx={{ width: "172px" }} onClick={confirmSsoAndScimConfiguration}>Configure SSO + SCIM</CoAppActionButton>
					}
				</Stack>
				<Typography variant="body1" color="text.primary" width={{ xxl: "50%", xl: "60%", lg: "60%", md: "70%" }} sx={{ paddingLeft: "10px" }}>
					{
						organizationSsoAndScim ?
							messages.SSO_AND_SCIM_CONFIGURED_MSG(identityProviderName) :
							messages.SSO_AND_SCIM_NOT_CONFIGURED_MSG
					}
				</Typography>
				<Divider sx={{ width: { xxl: "50%", xl: "60%", lg: "60%", md: "70%" } }} />
				<Stack direction="row" spacing={1} alignItems="center" sx={{ paddingLeft: "10px" }}>
					<CoAppWarningIcon />
					<Typography variant="h3" color="text.primary">Organization-Wide Kill Switch</Typography>
					<WorkstationSwitch
						checked={organizationKillSwitch}
						onChange={handleKillSwitchToggle}
					/>
				</Stack>
				<Typography variant="body1" color={organizationKillSwitch ? "text.error" : "text.primary"} sx={{ paddingLeft: "10px" }}>
					{
						organizationKillSwitch ?
							messages.KILLSWITCH_ENABLED_MSG :
							messages.KILLSWITCH_DISABLED_MSG
					}
				</Typography>
				<Divider sx={{ width: { xxl: "50%", xl: "60%", lg: "60%", md: "70%" } }} />
				<Stack direction="row" spacing={1} alignItems="center" sx={{ paddingLeft: "10px" }}>
					<Typography variant="h3" color="text.primary">Organization-Wide Force Login</Typography>
					<Switch
						checked={organizationForceLogin}
						onChange={handleForceLoginSwitchToggle}
					/>
				</Stack>
				<Typography variant="body1" color="text.primary" sx={{ paddingLeft: "10px" }}>
					This forces users to be logged into CoApp while using AcuityLogic.
				</Typography>
			</OrganizationManagementStack>
			<ConfirmationDialog dialogDetails={confirmationDialogContent} /> { /* TODO:: replace during scim wizard overhaul */}
			<CoAppKillswitchModal
				dialogOpen={killSwitchIsToggled && !killSwitch}
				dialogTitle="Activate organization-wide kill switch?"
				dialogMessage={messages.TOGGLE_ORGANIZATION_WIDE_KILLSWITCH_MSG("disable")}
				confirmClickHandler={confirmKillSwitchToggle}
				cancelClickHandler={handleKillSwitchToggle}
				actionText="Activate Kill Switch"
			/>
			<CoAppConfirmationModal
				dialogOpen={killSwitchIsToggled && killSwitch}
				dialogTitle="Disable organization-wide kill switch?"
				dialogMessage={messages.TOGGLE_ORGANIZATION_WIDE_KILLSWITCH_MSG("enable")}
				confirmClickHandler={confirmKillSwitchToggle}
				cancelClickHandler={handleKillSwitchToggle}
				actionText="Disable Kill Switch"
			/>
			<CoAppConfirmationModal
				dialogOpen={forceLoginIsToggled}
				dialogMessage={forceLogin ? messages.ORGANIZTION_WIDE_FORCE_LOGIN_ACTIVATE_MSG : messages.ORGANIZTION_WIDE_FORCE_LOGIN_DEACTIVATE_MSG}
				dialogTitle={`${forceLogin ? "Deactivate" : "Activate"} Organization-Wide Force Login?`}
				confirmClickHandler={confirmForceLoginToggle}
				cancelClickHandler={handleForceLoginSwitchToggle}
				actionText={forceLogin ? "Deactivate" : "Activate"}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={disableSsoConfirmationOpen}
				dialogMessage={messages.DISABLE_SSO_AND_SCIM_MSG}
				dialogTitle="Disable SSO + SCIM?"
				confirmClickHandler={disableSsoAndScim}
				cancelClickHandler={handleToggleDisableSsoAndScim}
				actionText="Disable"
			/>
		</>
	);
}