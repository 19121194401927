import React, { useEffect, useState } from "react";
import { AppBar, Box, Button, CircularProgress, Container, Stack, Toolbar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { CoAppMainContainer } from "../../global/styled/global.styled";

import LocationCreationForm from "./location-creation-form";

export default function LocationManagement() {
	const authHeader = useAuthHeader();

	const [locations, setLocations] = useState([]);
	const [locationsLoaded, setLocationsLoaded] = useState(false);

	const columns = [
		{
			field: "id",
			headerName: "id",
			width: 100,
			editable: true,
		},
		{
			field: "active",
			headerName: "Active",
			width: 100,
			editable: true,
			type: "boolean"
		},
		{
			field: "description",
			headerName: "Description",
			width: 220,
			editable: true
		},
		{
			field: "iframe",
			headerName: "IFrame",
			width: 100,
			editable: true,
			align: "left",
			headerAlign: "left",
			type: "boolean"
		},
		{
			field: "multiStepFlow",
			headerName: "Multi Step Flow",
			width: 100,
			editable: true,
			type: "boolean"
		},
		{
			field: "multiStepIdentifier",
			headerName: "Multi Step Identifier",
			width: 220,
			editable: true,
		},
		{
			field: "name",
			headerName: "Name",
			width: 220,
			editable: true,
		},
		{
			field: "pageUrl",
			headerName: "Page URL",
			width: 300,
			editable: true,
		},
		{
			field: "previewUrl",
			headerName: "Preview URL",
			width: 220,
			editable: true,
		}
	];

	const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

	const handleCreateDialogClickOpen = () => {
		setCreateDialogOpen(true);
	};

	const initLocations = () => {
		if (!locationsLoaded) {
			axios.get(apiRoutes.getRuleLocations, {
				headers: authHeader,
			}).then((res) => {
				setLocations(res.data);
				setLocationsLoaded(true);
			}).catch((err) => {
				console.log(err);
			});
		}
	};

	const processRowUpdate = (newRow) => {
		const updatedField = { ...newRow, isNew: false };
		axios.put(apiRoutes.updateRuleLocation + "/" + updatedField.id,
			{ newRow },
			{ headers: authHeader, }
		).then((response) => {
			console.log(response);
		}).catch((error) => {
			console.log(error);
		});
		return updatedField;
	};

	const handleProcessRowUpdateError = () => {
		console.log("row update error");
	};

	useEffect(() => {
		initLocations();
	}, [locations]);

	if (locationsLoaded) {
		return (
			<>
				<AppBar position="static" sx={{ backgroundColor: "#FFFFFF" }}>
					<Container maxWidth="xl">
						<Toolbar disableGutters>
							<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
							<Box sx={{ flexGrow: .025 }}>
								<Button color="inherit" sx={{ color: "#416856" }} onClick={handleCreateDialogClickOpen}>
									Create New Location
								</Button>
							</Box>
						</Toolbar>
					</Container>
				</AppBar>

				<LocationCreationForm isOpen={{ createDialogOpen }} />

				<CoAppMainContainer sx={{ p: 2 }}>
					<DataGrid
						rows={locations}
						columns={columns}
						processRowUpdate={processRowUpdate}
						onProcessRowUpdateError={handleProcessRowUpdateError}
						experimentalFeatures={{ newEditingApi: true }}
						disableRowSelectionOnClick />
				</CoAppMainContainer>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}