import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Container, Stack } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import { selectGroupChildren, selectNavState, setNavState } from "../../../redux/navigationSlice";
import { selectOrganizationIdentityProvider, selectOrganizationSsoAndScim } from "../../../redux/userSlice";
import CoAppDatagridHeader from "../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../../global/components/datagrid/coapp-datagrid-list-cell";
import CoAppDataGridNameCell from "../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppStandardDataGrid from "../../global/components/datagrid/coapp-standard-datagrid";
import CoAppCreateGroupOrRoleModal from "../../global/components/modals/coapp-create-group-or-role-modal";
import CoAppDestructiveConfirmationModal from "../../global/components/modals/coapp-destructive-confirmation-modal";
import ThreeDotMenu from "../../global/components/three-dot-menu";

export default function GroupManagement() {
	let authHeader = useAuthHeader();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const navState = useSelector(selectNavState);
	const groupChildren = useSelector(selectGroupChildren);
	const identityProviderName = useSelector(selectOrganizationIdentityProvider);

	const [groupSeed, setGroupSeed] = useState(0);
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const [organizationGroupsLoaded, setOrganizationGroupsLoaded] = useState(false);
	const [organizationGroups, setOrganizationGroups] = useState([]);
	const [groupCreateDialogOpen, setGroupCreateDialogOpen] = useState(false);
	const [groupDeleteDialogOpen, setGroupDeleteDialogOpen] = useState(false);
	const [groupBeingDeleted, setGroupBeingDeleted] = useState({ name: "" });
	const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
	const [groupNameError, setGroupNameError] = useState("");
	const [groupName, setGroupName] = useState("");
	const [selectedGroups, setSelectedGroups] = useState([]);

	const columns = [
		{
			field: "name",
			headerName: "Name",
			editable: false,
			flex: .5,
			renderCell: (params) => (
				<CoAppDataGridNameCell item={params.row} />
			),
		},
		{
			field: "users",
			headerName: "Users",
			editable: false,
			flex: .5,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value} resource="Users" isUser={true} />
			)
		},
		{
			field: "rules",
			headerName: "Rules",
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value} resource="Rules" width={475} />
			),
		},
		{
			field: "plugins",
			headerName: "Plugins",
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value} resource="Plugins" />
			),
		},
		...(organizationSsoAndScim
			? [
				{
					field: "idpgroups",
					headerName: `${identityProviderName} Groups`,
					editable: false,
					flex: 1,
					renderCell: (params) => (
						<CoAppDataGridListCell items={params.value} resource={`${identityProviderName} Groups`} />
					),
				},
			]
			: []),
		{
			field: "actions",
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<div>
					<ThreeDotMenu
						options={[
							{
								name: "Edit",
								optionClickHandler: () => {
									handleOpenGroupEditPage({ params });
								},
							},
							{
								name: "Duplicate",
								optionClickHandler: () => {
									duplicateGroup(params.row.id);
								},
							},
							{
								name: "Delete",
								optionClickHandler: () => {
									setGroupDeleteDialogOpen(true);
									setGroupBeingDeleted(params.row);
								},
							},
						]}
					/>
				</div>
			),
		}
	];

	const createNewGroup = () => {
		if (!groupName) {
			setGroupNameError("Required *");
			return;
		}

		let groupJSON = {
			"groupName": groupName,
		};

		axios.post(apiRoutes.createGroup, groupJSON, { headers: authHeader })
			.then(() => {
				setGroupSeed(Math.random());
				setGroupCreateDialogOpen(false);
				setGroupName("");
				setGroupNameError("");
				dispatch(setMessage(messages.GROUP_CREATED_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((err) => {
				if (err.response.data.message.includes("already exists")) {
					setGroupNameError(messages.RESOURCE_ALREADY_EXISTS_ERROR_MSG("Group"));
					return;
				}
				dispatch(setMessage(messages.GROUP_CREATION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const handleToggleGroupCreateDialog = () => {
		setGroupCreateDialogOpen(!groupCreateDialogOpen);
		setGroupName("");
		setGroupNameError("");
	};

	const handleGroupNameChange = (e) => {
		setGroupName(e.target.value);
		setGroupNameError("");
	};

	const deleteGroup = () => {
		axios
			.delete(apiRoutes.deleteGroup + "/" + groupBeingDeleted.id, {
				headers: authHeader,
			})
			.then((res) => {
				if (res.status === 200) {
					dispatch(setMessage(messages.GROUP_DELETED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
					setGroupSeed(Math.random());
				} else {
					dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
				handleToggleGroupDeleteDialog();
			})
			.catch((err) => {
				console.log(err);
				dispatch(setMessage(messages.GROUP_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				handleToggleGroupDeleteDialog();
			});
	};

	const duplicateGroup = (groupId) => {
		let group = organizationGroups.find((group) => group.id === groupId);
		let groupJson = {
			"name": group.name + " (copy)" + Math.floor(Math.random() * 1000),
		};
		axios
			.post(apiRoutes.copyGroup + "/" + groupId, groupJson, { headers: authHeader })
			.then((res) => {
				if (res.status === 200) {
					dispatch(setMessage(messages.GROUP_CREATED_SUCCESS_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.SUCCESS));
					setGroupSeed(Math.random());
				} else {
					dispatch(setMessage(messages.GROUP_CREATION_ERROR_MSG));
					dispatch(setOpen(true));
					dispatch(setLevel(messageLevels.ERROR));
				}
			})
			.catch((err) => {
				console.log(err);
				dispatch(setMessage(messages.GROUP_CREATION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR
				));
			});
	};

	const handleOpenGroupEditPage = (rowObject) => {
		let oldNavState = [...navState];
		oldNavState[7] = { // Groups is the 7th item in the nav list
			...oldNavState[7],
			children: [
				{
					id: Math.random(),
					name: rowObject.params.row.name,
					children: [],
					isGroupingLabel: true
				},
				...groupChildren.map((child) => {
					if (child.name === "Add/Remove IdP Groups" && organizationSsoAndScim) {
						return {
							...child,
							name: `Add/Remove ${identityProviderName} Groups`,
							page: handlePageGeneration(child.name, rowObject.params.id),
							keepOpen: true,
							isClickable: true
						};
					} else {
						return {
							...child,
							page: handlePageGeneration(child.name, rowObject.params.id),
							keepOpen: true,
							isClickable: true
						};
					}
				}).filter(child => {
					if (!organizationSsoAndScim && child.name.includes("Add/Remove")) {
						return false;
					} else {
						return true;
					}
				})
			],
			isParentChangeEvent: true
		};
		dispatch(setNavState(oldNavState));
		navigate(pages.groupManagement);
	};

	const handlePageGeneration = (pageName, groupId) => {
		switch (pageName) {
			case "Rules":
				return pages.groupRules(groupId);
			case "Users":
				return pages.groupUsers(groupId);
			case "Plugins":
				return pages.groupPlugins(groupId);
			case "Add/Remove IdP Groups":
				return pages.groupSSO(groupId);
			default:
				return pages.groupManagement;
		}
	};

	const handleToggleGroupDeleteDialog = (group = { name: "" }) => {
		setGroupDeleteDialogOpen(!groupDeleteDialogOpen);
		setGroupBeingDeleted(group);
	};

	const initGroupManagement = () => {
		axios.get(apiRoutes.getGroups, { headers: authHeader })
			.then((res) => {
				setOrganizationGroups(res.data);
				setOrganizationGroupsLoaded(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		initGroupManagement();
	}, [groupSeed]);

	if (organizationGroupsLoaded) {
		return (
			<>
				<CoAppDatagridHeader
					title="Groups"
					resourceType="Group"
					addOnClickHandler={handleToggleGroupCreateDialog}
					addIsPresent={true}
					editIsPresent={false}
					deleteIsPresent={false}
				/>
				<CoAppStandardDataGrid
					rows={organizationGroups}
					columns={columns}
					pinnedColumns={["actions", "__check__", "name"]}
					targetResource="groups"
					selectionModel={selectedGroups}
					setSelectionModel={setSelectedGroups}
					handleModalToggle={() => setBulkDeleteDialogOpen(prev => !prev)}
				/>
				<CoAppCreateGroupOrRoleModal
					dialogOpen={groupCreateDialogOpen}
					changeHandler={handleGroupNameChange}
					confirmClickHandler={createNewGroup}
					cancelClickHandler={handleToggleGroupCreateDialog}
					nameError={groupNameError}
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={groupDeleteDialogOpen}
					dialogTitle={`Delete ${groupBeingDeleted.name} group?`}
					dialogMessage={messages.DELETION_CONFIRMATION_MSG(groupBeingDeleted.name, "group")}
					confirmClickHandler={deleteGroup}
					cancelClickHandler={handleToggleGroupDeleteDialog}
					actionText="Delete"
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={bulkDeleteDialogOpen}
					dialogTitle={`Delete selected ${selectedGroups.length > 1 ? "groups" : "group"}?`}
					dialogMessage={"Are you sure you want to bulk delete all the selected groups? This action cannot be undone."}
					confirmClickHandler={() => console.log("Bulk deletion not implemented yet")}
					cancelClickHandler={() => setBulkDeleteDialogOpen(false)}
					actionText="Delete"
				/>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}