import { Route, Routes } from "react-router-dom";

import Extension from "./components/extension";
import FieldManagement from "./components/field-location-management/fields/field-management";
import LocationManagement from "./components/field-location-management/locations/location-management";
import Login from "./components/login";
import ManagerApprovalRequest from "./components/requests/manager-approval-request";
import RuleLibraryDataGrid from "./components/rule-library/rule-library-datagrid";
import RuleTrashDataGrid from "./components/rule-library/rule-trash-datagrid";
import RuleWizard from "./components/rule-wizard";
import Settings from "./components/settings";
import GroupPlugins from "./components/settings/groups/group/group-plugins";
import GroupRules from "./components/settings/groups/group/group-rules";
import GroupSSO from "./components/settings/groups/group/group-sso";
import GroupUsers from "./components/settings/groups/group/group-users";
import GroupManagement from "./components/settings/groups/group-management";
import OrganizationManagement from "./components/settings/organization";
import ScimWizard from "./components/settings/organization/scim-wizard";
import Plugin from "./components/settings/plugins/plugin";
import PluginGroups from "./components/settings/plugins/plugin";
import PluginManagement from "./components/settings/plugins/plugin-management";
import Role from "./components/settings/roles/role";
import RolePermissions from "./components/settings/roles/role/role-permissions";
import RoleSSO from "./components/settings/roles/role/role-sso";
import RoleUsers from "./components/settings/roles/role/role-users";
import RoleManagement from "./components/settings/roles/role-management";
import UserManagement from "./components/settings/users/user-management";
import UserProfile from "./components/settings/users/user-profile";
import WorkstationManagement from "./components/settings/workstations/workstation-management";
import Swagger from "./components/swagger";
import ProtectedRoute from "./ProtectedRoute";
import WithNavRoute from "./WithNavRoute";
import WithoutNavRoute from "./WithoutNavRoute";

function AppRoutes() {
	return (
		<Routes>
			<Route element={<WithoutNavRoute />}>
				<Route path="/" element={<Login />} />
				<Route path="/extension" element={<Extension />} />
				<Route exact path="/requests/:action/:requestId" element={<ManagerApprovalRequest />} />
				<Route path="/swagger" element={<ProtectedRoute />}>
					<Route exact path="/swagger" element={<Swagger />} />
				</Route>
				<Route path="/field-mgmt" element={<ProtectedRoute />}>
					<Route exact path="/field-mgmt" element={<FieldManagement />} />
				</Route>
				<Route path="/location-mgmt" element={<ProtectedRoute />}>
					<Route exact path="/location-mgmt" element={<LocationManagement />} />
				</Route>
			</Route>
			<Route element={<WithNavRoute />}>
				<Route path="/rules/new" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/rules/new" element={<RuleWizard />} />
				</Route>
				<Route path="/rules/:id" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/rules/:id" element={<RuleWizard />} />
				</Route>
				<Route exact path="/rules/library" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/rules/library" element={<RuleLibraryDataGrid />} />
				</Route>
				<Route exact path="/rules/trash" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/rules/trash" element={<RuleTrashDataGrid />} />
				</Route>
				<Route exact path='/settings/users' element={<ProtectedRoute permissions={["ACCESS_MP"]} isInclusive={true} />}>
					<Route exact path='/settings/users' element={<UserManagement />} />
				</Route>
				<Route exact path='/settings/users/:id' element={<ProtectedRoute permissions={["ACCESS_MP"]} isInclusive={true} />}>
					<Route exact path='/settings/users/:id' element={<UserProfile />} />
				</Route>
				<Route exact path="/settings" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS", "MANAGE_PLUGINS", "MANAGE_WORKSTATIONS", "MANAGE_ORG_KILLSWITCH", "MANAGE_ORG_FORCE_LOGIN", "MANAGE_SSOSCIM"]} isInclusive={false} />}>
					<Route exact path="/settings" element={<Settings />} />
				</Route>
				<Route path="/settings/groups" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/groups" element={<GroupManagement />} />
				</Route>
				<Route path="/settings/groups/:groupId/rules" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/groups/:groupId/rules" element={<GroupRules />} />
				</Route>
				<Route path="/settings/groups/:groupId/plugins" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/groups/:groupId/plugins" element={<GroupPlugins />} />
				</Route>
				<Route path="/settings/groups/:groupId/users" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/groups/:groupId/users" element={<GroupUsers />} />
				</Route>
				<Route path="/settings/groups/:groupId/sso" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/groups/:groupId/sso" element={<GroupSSO />} />
				</Route>
				<Route exact path="/settings/organization" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_ORG_KILLSWITCH", "MANAGE_ORG_FORCELOGIN", "MANAGE_SSOSCIM"]} isInclusive={false} />}>
					<Route exact path="/settings/organization" element={<OrganizationManagement />} />
				</Route>
				<Route path="/settings/role/:id" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/role/:id" element={<Role />} />
				</Route>
				<Route path="/settings/roles/:roleId/permissions" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/roles/:roleId/permissions" element={<RolePermissions />} />
				</Route>
				<Route path="/settings/roles/:roleId/sso" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/roles/:roleId/sso" element={<RoleSSO />} />
				</Route>
				<Route path="/settings/roles/:roleId/users" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/roles/:roleId/users" element={<RoleUsers />} />
				</Route>
				<Route path="/settings/roles" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
					<Route exact path="/settings/roles" element={<RoleManagement />} />
				</Route>
				<Route path="/settings/organization/sso-scim" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_SSOSCIM"]} isInclusive={true} />}>
					<Route exact path="/settings/organization/sso-scim" element={<ScimWizard />} />
				</Route>
				<Route exact path="/settings/workstations" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_WORKSTATIONS"]} isInclusive={true} />}>
					<Route exact path="/settings/workstations" element={<WorkstationManagement />} />
				</Route>
				<Route path="/settings/plugin/:id" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_PLUGINS"]} isInclusive={true} />}>
					<Route exact path="/settings/plugin/:id" element={<Plugin />} />
				</Route>
				<Route path="/settings/plugins/:pluginId/groups" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_PLUGINS"]} isInclusive={true} />}>
					<Route exact path="/settings/plugins/:pluginId/groups" element={<PluginGroups />} />
				</Route>
				<Route path="/settings/plugins" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_PLUGINS"]} isInclusive={true} />}>
					<Route exact path="/settings/plugins" element={<PluginManagement />} />
				</Route>
			</Route>
		</Routes >
	);
}

export default AppRoutes;