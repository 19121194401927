import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
	CircularProgress, Container, IconButton,
	Stack, Switch
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import useAuthHeader from "../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../redux/alertSlice";
import { selectNavState, selectPluginChildren, setNavState } from "../../../redux/navigationSlice";
import { selectOrganizationId } from "../../../redux/userSlice";
import CoAppDatagridHeader from "../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../../global/components/datagrid/coapp-datagrid-list-cell";
import CoAppStandardDataGrid from "../../global/components/datagrid/coapp-standard-datagrid";

export default function PluginManagement() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const authHeader = useAuthHeader();
	const navState = useSelector(selectNavState);
	const pluginChildren = useSelector(selectPluginChildren);
	const organizationId = useSelector(selectOrganizationId);
	const [pluginsLoaded, setPlugingsLoaded] = useState(false);


	const [plugins, setPlugins] = useState([]);

	const columns = [
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			editable: false
		},
		{
			field: "groups",
			headerName: "Groups",
			flex: 1,
			editable: false,
			renderCell: (params) => (
				<CoAppDataGridListCell items={params.value.map(group => { return { name: group }; })} resource="Groups" />
			)
		},
		{
			field: "active",
			headerName: "Status",
			flex: 1,
			editable: false,
			renderCell: (params) => (
				<Switch
					checked={params.value}
					onChange={() => handlePluginStatusToggleChange(params.row.id, params.row.platformId, params.value)}
					disabled={false}
				/>
			)
		},
		{
			field: "actions",
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<IconButton>
					<KeyboardArrowRight onClick={() => handleOpenPlugin(params.row)} />
				</IconButton>
			)
		}
	];

	const handlePluginStatusToggleChange = (pluginId, platformId, state) => {
		axios.put(apiRoutes.togglePluginStatusForOrganization(organizationId, platformId, pluginId), {}, { headers: authHeader })
			.then(() => {
				initPluginManagement();
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				dispatch(setMessage(state ? messages.PLUGIN_DISABLED_SUCCESS_MSG : messages.PLUGIN_ENABLED_SUCCESS_MSG));
			})
			.catch((error) => {
				console.error(error);
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
				dispatch(setMessage(state ? messages.PLUGIN_DISABLED_ERROR_MSG : messages.PLUGIN_ENABLED_ERROR_MSG));
			});
	};

	const handleOpenPlugin = (plugin) => {
		navigate(pages.pluginGroups(plugin.id), { state: { plugin: plugin } });
		let oldNavState = [...navState];
		oldNavState[5] = { // Plugins are the 5th item in the nav list
			...oldNavState[5],
			children: [
				{
					id: Math.random(),
					name: plugin.name,
					children: [],
					isGroupingLabel: true,
					active: plugin.active
				},
				...pluginChildren.map((child) => { return { ...child, page: `/settings/plugins/${plugin.id}/groups`, keepOpen: true }; })
			],
			isParentChangeEvent: true
		};
		dispatch(setNavState(oldNavState));
	};

	const initPluginManagement = () => {
		axios.get(apiRoutes.getPlugins(organizationId), { headers: authHeader })
			.then((response) => {
				let platforms = response.data;
				let plugins = [];
				platforms.forEach(platform => {
					if (!platform.plugins) {
						return;
					}
					platform.plugins.forEach(plugin => {
						plugins.push({
							id: plugin.id,
							name: plugin.name,
							groups: plugin.groups.map(group => group),
							active: plugin.active,
							platformId: platform.id
						});
					});
				});

				setPlugins(plugins);
				setPlugingsLoaded(true);
			})
			.catch((error) => {
				console.error(error);
			});

	};

	useEffect(() => {
		initPluginManagement();
	}, []);

	if (pluginsLoaded) {
		return (
			<>
				<CoAppDatagridHeader
					title="Plugins"
					addIsPresent={false}
					editIsPresent={false}
					deleteIsPresent={false}
				/>
				<CoAppStandardDataGrid
					columns={columns}
					rows={plugins}
					pinnedColumns={["actions", "name", "active"]}
					allowSelection={false}
					targetResource="plugins"
				/>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}