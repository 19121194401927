import { Fragment } from "react";
import { Box, Stack, Step, Stepper } from "@mui/material";

import { CoAppActionButton, CoAppTextButton } from "../../../../global/styled/global.styled";
import { ScimWizardStepButtonsDiv, ScimWizardStepConnector, ScimWizardStepIconRoot, ScimWizardStepLabel } from "../styled/scim-wizard.styled";

function WizardStepIcon(props) {
	const { active, completed, className } = props;
	if (completed) {
		return (
			<ScimWizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-completedIcon" />
			</ScimWizardStepIconRoot>);
	} else if (active && !completed) {
		return (
			<ScimWizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle-active-incomplete" />
			</ScimWizardStepIconRoot>
		);
	} else if (!active && !completed) {
		return (
			<ScimWizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle-inactive-incomplete" />
			</ScimWizardStepIconRoot>
		);
	} else {
		return (
			<ScimWizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle" />
			</ScimWizardStepIconRoot>
		);
	}
}

export default function ScimWizardStepper(props) {
	const activeStep = props.activeStep;
	const steps = props.steps;
	const stepsCompleted = props.stepsCompleted;
	const handleSubmit = props.handleSubmit;
	const handleBack = props.handleBack;
	const handleExitEditing = props.handleExitEditing;

	return (
		<Fragment>
			<ScimWizardStepButtonsDiv>
				<Stack justifyContent="center" sx={{ width: "100%" }} direction="row" >
					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<CoAppTextButton onClick={handleExitEditing}>Exit</CoAppTextButton>
					</Box>
					<Stepper connector={<ScimWizardStepConnector />} alternativeLabel nonLinear activeStep={activeStep} sx={{ width: "50%" }}>
						{steps.map(step => (
							<Step completed={stepsCompleted[step.num - 1]} key={step.num}>
								<ScimWizardStepLabel StepIconComponent={WizardStepIcon} active={activeStep === step.num - 1}>
									{step.desc}
								</ScimWizardStepLabel>
							</Step>
						))}
					</Stepper>

					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<Fragment>
							<Box sx={{ flex: "1 1 auto" }} />
							<Fragment>
								<CoAppTextButton onClick={handleBack} disabled={activeStep === 0}>
									Back
								</CoAppTextButton>

								{activeStep === 3 ? (
									<CoAppActionButton color="inherit" onClick={handleSubmit}>
										Finish
									</CoAppActionButton>
								) : (
									<CoAppActionButton color="inherit" onClick={handleSubmit}>
										Next
									</CoAppActionButton>
								)}
							</Fragment>
						</Fragment>
					</Box>
				</Stack>
			</ScimWizardStepButtonsDiv>
		</Fragment >
	);
}
