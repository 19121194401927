import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectNavState, setNavState } from "../../../../redux/navigationSlice";
import { selectOrganizationIdentityProvider, selectUser } from "../../../../redux/userSlice";
import CoAppDataGridDateTimeCell from "../../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridNameCell from "../../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/coapp-standard-datagrid";
import CoAppAddItemsToListModal from "../../../global/components/modals/coapp-add-items-to-list-modal";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../global/components/modals/coapp-edit-name-modal";
import { CoAppDataGridActionsContainer } from "../../../global/styled/global.styled";

export default function RoleSSO() {
	const authHeader = useAuthHeader();
	const currentUser = useSelector(selectUser);
	const navState = useSelector(selectNavState);
	const identityProviderName = useSelector(selectOrganizationIdentityProvider);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { roleId } = useParams();
	const { state } = useLocation();

	const [addIdPGroupsIsToggled, setAddIdPGroupsIsToggled] = useState(false);
	const [idpGroups, setIdpGroups] = useState([]);
	const [availableIdpGroups, setAvailableIdpGroups] = useState([]);
	const [roleIdpGroups, setRoleIdpGroups] = useState([]);
	const [selectedIdPGroups, setSelectedIdpGroups] = useState([]);
	const [deleteRoleDialogOpen, setDeleteRoleDialogOpen] = useState(false);
	const [editRoleDialogOpen, setEditRoleDialogOpen] = useState(false);
	const [roleNameInput, setRoleNameInput] = useState("");
	const [roleNameError, setRoleNameError] = useState("");

	const columns = [
		{
			field: "name",
			headerName: `${identityProviderName} Group`,
			disableExport: true,
			editable: true,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridNameCell item={params.row} />
			),
		},
		{
			field: "createdAt",
			headerName: "Date Mapped",
			disableExport: true,
			editable: true,
			flex: 1,
			valueGetter: (params) => new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridDateTimeCell value={params.value} />
			),
		},
		{
			field: "actions",
			disableExport: true,
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<CoAppDataGridActionsContainer>
					<Delete
						onClick={() => {
							removeIdPGroupFromRole(params.row.id);
						}}
					/>
				</CoAppDataGridActionsContainer>
			),
		},
	];


	const toggleDeleteRoleDialog = () => {
		setDeleteRoleDialogOpen(!deleteRoleDialogOpen);
	};

	const deleteRole = () => {
		axios.delete(apiRoutes.deleteRole + "/" + roleId, {
			headers: authHeader
		}).then((res) => {
			if (res.status === 200) {
				updateNavigation(true);
				dispatch(setMessage(messages.ROLE_DELETION_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				navigate(pages.roleManagement);
			} else {
				console.log(res);
				dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			}
			toggleDeleteRoleDialog();
		}).catch((err) => {
			console.log(err);
			dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
			toggleDeleteRoleDialog();
		});
	};

	const toggleEditRoleDialog = () => {
		setEditRoleDialogOpen(!editRoleDialogOpen);
		setRoleNameInput("");
		setRoleNameError("");
	};

	const handleRoleNameChange = (e) => {
		setRoleNameInput(e.target.value);
		setRoleNameError("");
	};

	const handleRoleNameChangeSubmit = (e) => {
		if (!roleNameInput) return;

		if (roleNameInput === state.name) {
			toggleEditRoleDialog();
			return;
		}

		axios.put(apiRoutes.updateRole(roleId), { name: roleNameInput }, { headers: authHeader })
			.then(() => {
				toggleEditRoleDialog();
				updateNavigation();
				dispatch(setMessage(messages.ROLE_NAME_UPDATED_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setRoleNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Role"));
					return;
				}
				toggleEditRoleDialog();
				dispatch(setMessage(messages.ROLE_NAME_UPDATE_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const updateNavigation = (isDeleteEvent = false) => {
		const oldNavState = [...navState];
		if (!isDeleteEvent) {
			let copyGroupChildren = [...oldNavState[8].children].slice(1); //update nav state to reflect group name update
			copyGroupChildren.unshift({
				id: Math.random(),
				name: roleNameInput,
				children: [],
				isGroupingLabel: true
			});

			oldNavState[8] = {
				...oldNavState[8],
				children: copyGroupChildren,
				isChangeEvent: true,
				isStillSelected: true,
				selectedResource: "Add/Remove IdP Groups",
				isNameChangeEvent: true
			};
		} else {
			oldNavState[8] = {
				...oldNavState[8],
				children: [],
				isStillSelected: true,
				selectedResource: ""
			};
		}
		dispatch(setNavState(oldNavState));
	};

	const addIdPGroupsToRole = () => {
		axios.put(apiRoutes.updateRoleIdpGroups(roleId), { idpgroups: selectedIdPGroups }, { headers: authHeader })
			.then(() => {
				init();
				toggleAddNewDialog();
				dispatch(setMessage(messages.IDP_GROUPS_ADDITION_SUCCESS_MSG(identityProviderName)));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.IDP_GROUPS_ADDITION_ERROR_MSG(identityProviderName)));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));

			});
	};

	const addIdPGroupsToSelectedIdpGroups = (value) => {
		//value is an array of idpGroup names, we need to convert them to idpGroup objects to display the group name properly
		value = value.map((idpGroup) => {
			let tempArr = [...roleIdpGroups, ...availableIdpGroups];
			let foundIdpGroup = tempArr.find((row) => row.name === idpGroup);
			return foundIdpGroup;
		});
		setSelectedIdpGroups(value);
	};

	const init = () => {
		axios.get(apiRoutes.getRoleIdpGroups(roleId), { headers: authHeader })
			.then((response) => {
				setRoleIdpGroups(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const initAvailableIdpGroups = () => {
		let available = [];
		if (idpGroups && idpGroups.length > 0) {
			idpGroups.forEach((idpGroup) => {
				if (!roleIdpGroups.some((roleIdpGroup) => roleIdpGroup.name === idpGroup.externalGroupName)) {
					available.push({ externalGroupId: idpGroup.externalGroupId, name: idpGroup.externalGroupName });
				}
			});
		}
		setAvailableIdpGroups(available);
	};

	const initIdpGroups = () => {
		axios.get(apiRoutes.getExternalGroups(currentUser.organizationId), { headers: authHeader })
			.then((response) => {
				setIdpGroups(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const removeIdPGroupFromRole = (idpGroupId) => {
		axios.delete(apiRoutes.removeIdpGroupFromRole(roleId, idpGroupId), { headers: authHeader })
			.then(() => {
				init();
				dispatch(setMessage(messages.GROUP_REMOVAL_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessage(messages.GROUP_REMOVAL_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const toggleAddNewDialog = () => {
		setAddIdPGroupsIsToggled(!addIdPGroupsIsToggled);

		if (!addIdPGroupsIsToggled) {
			initAvailableIdpGroups();
			setAddIdPGroupsIsToggled(true);
		} else {
			setAddIdPGroupsIsToggled(false);
			setAvailableIdpGroups([]);
			setSelectedIdpGroups([]);
		}
	};

	useEffect(() => {
		init();
		initIdpGroups();
	}, [state.name]);

	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} ${identityProviderName} Group Management`}
				subResourceType={`${identityProviderName} Group`}
				addOnClickHandler={toggleAddNewDialog}
				resourceType="Role"
				addIsPresent={true}
				editIsPresent={true}
				editOnClickHandler={toggleEditRoleDialog}
				deleteIsPresent={true}
				deleteOnClickHandler={toggleDeleteRoleDialog}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={roleIdpGroups}
				allowSelection={true}
				pinnedColumns={["actions", "__check__"]}
				targetResource={`${identityProviderName} groups`}
				parentResource="role"
				parentResourceName={state.name}
				bulkDestructiveText="Remove"
			/>
			<CoAppAddItemsToListModal
				addButtonTitle="Save"
				inputLabel={`${identityProviderName} Group(s)`}
				addItemsIsToggled={addIdPGroupsIsToggled}
				addItemsToCollection={addIdPGroupsToRole}
				addItemsToNewArray={addIdPGroupsToSelectedIdpGroups}
				dialogTitle={`Add ${identityProviderName} Group(s) to ${state.name} role`}
				items={availableIdpGroups}
				itemsToAdd={selectedIdPGroups}
				toggleDialog={toggleAddNewDialog}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteRoleDialogOpen}
				dialogTitle={`Delete ${state.name} role?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "role")}
				confirmClickHandler={deleteRole}
				cancelClickHandler={toggleDeleteRoleDialog}
				actionText="Delete"
			/>
			<CoAppEditNameModal
				dialogOpen={editRoleDialogOpen}
				dialogTitle="Edit role name"
				changeHandler={handleRoleNameChange}
				placeholderText={state.name}
				confirmClickHandler={handleRoleNameChangeSubmit}
				cancelClickHandler={toggleEditRoleDialog}
				actionText="Save"
				editNameError={roleNameError}
				setEditNameError={setRoleNameError}
			/>
		</>
	);
}