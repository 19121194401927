import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import SwaggerUI from "swagger-ui-react";

import apiRoutes from "../../constants/api-routes";
import useAuthHeader from "../../helpers/useAuthHeader";

import "swagger-ui-react/swagger-ui.css";

export default function Swagger() {
	let authHeader = useAuthHeader();

	const [swaggerSpec, setSwaggerSpec] = useState({});

	const getSwaggerSpec = () => {
		axios.get(apiRoutes.getSwagger, {
			headers: authHeader
		}).then((res) => {
			setSwaggerSpec(res.data);
		}).catch((err) => {
			console.log(err);
		});
	};

	useEffect(() => {
		getSwaggerSpec();
	}, []);

	return (
		<React.Fragment>
			<SwaggerUI spec={swaggerSpec} />
		</React.Fragment>
	);
}
