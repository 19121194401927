import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../redux/alertSlice";
import { selectOrganizationSsoAndScim } from "../../../../redux/userSlice";
import CoAppIconTextButton from "../../../global/components/inputs/coapp-icon-text-button";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../global/components/modals/coapp-edit-name-modal";
import { CoAppMainContainer } from "../../../global/styled/global.styled";
import SettingsMenuBack from "../../settings-menu-back";
import { MainMenuContainer, MainMenuItem, SettingMenuContainer, SettingsDivider } from "../../styled/settings.styled";

import RolePermissions from "./role-permissions";
import RoleSSO from "./role-sso";
import RoleUsers from "./role-users";

export default function Role() {
	const authHeader = useAuthHeader();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id } = useParams();

	const [editRoleNameToggled, setEditRoleNameToggled] = useState(false);
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const [role, setRole] = useState({});
	const [roleNameInput, setRoleNameInput] = useState("");
	const [rolePermissions, setRolePermissions] = useState([]);
	const [roleUpdateSeed, setRoleUpdateSeed] = useState(0);
	const [roleNameError, setRoleNameError] = useState("");
	const [selectedMenuItem, setSelectedMenuItem] = useState(null);
	const [roleDeleteDialogOpen, setRoleDeleteDialogOpen] = useState(false);

	const menuItems = [
		{ key: "users", label: "USERS", value: pages.roleUsers },
		{ key: "permissions", label: "PERMISSIONS", value: pages.rolePermissions },
		organizationSsoAndScim ? { key: "sso", label: "SSO/SCIM CONFIGURATION", value: pages.roleSSO } : null
	].filter(item => item !== null);

	const deleteRole = () => {
		axios.delete(apiRoutes.deleteRole + "/" + role.id, {
			headers: authHeader
		}).then((res) => {
			if (res.status === 200) {
				dispatch(setMessage(messages.ROLE_DELETION_SUCCESS_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
				navigate(pages.settings + "?from=roles");
			} else {
				console.log(res);
				dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			}
			toggleDeleteRoleDialog();
		}).catch((err) => {
			console.log(err);
			dispatch(setMessage(messages.ROLE_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(messageLevels.ERROR));
			toggleDeleteRoleDialog();
		});
	};

	const handleMenuItemClick = (menuItem) => {
		setSelectedMenuItem(menuItem);
	};

	const handleCloseEditNameModal = () => {
		setEditRoleNameToggled(false);
		setRoleNameInput("");
	};

	const handleRoleNameChange = (e) => {
		setRoleNameInput(e.target.value);
		setRoleNameError("");
	};

	const handleRoleNameChangeSubmit = (e) => {
		if (!roleNameInput) return;

		if (roleNameInput === role.name) {
			handleCloseEditNameModal();
			return;
		}

		axios.put(apiRoutes.updateRole(id), { name: roleNameInput }, { headers: authHeader })
			.then(() => {
				handleCloseEditNameModal();
				setRoleUpdateSeed(Math.random());
				dispatch(setMessage(messages.ROLE_NAME_UPDATED_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.SUCCESS));
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setRoleNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Role"));
					return;
				}
				handleCloseEditNameModal();
				dispatch(setMessage(messages.ROLE_NAME_UPDATE_ERROR_MSG));
				dispatch(setOpen(true));
				dispatch(setLevel(messageLevels.ERROR));
			});
	};

	const initRole = () => {
		if (id) {
			axios.get(apiRoutes.getRoleById(id), { headers: authHeader })
				.then((response) => {
					setRole(response.data);
					setRolePermissions(response.data.permissions);
					setSelectedMenuItem(pages.roleUsers);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const toggleDeleteRoleDialog = () => {
		setRoleDeleteDialogOpen(!roleDeleteDialogOpen);
	};

	useEffect(() => {
		initRole();
	}, [roleUpdateSeed]);

	if (role) {
		return (
			<>
				<CoAppMainContainer sx={{ p: 2 }}>
					<SettingsMenuBack buttonText="All Roles" navigateTo={pages.settings + "?from=roles"} />
				</CoAppMainContainer>
				<CoAppMainContainer sx={{ p: 2 }}>
					<SettingMenuContainer>
						<MainMenuContainer>
							{role.name}
							{menuItems.map((menuItem) => (
								menuItem && menuItem.value &&
								<MainMenuItem
									key={menuItem.key}
									className={selectedMenuItem === menuItem.value ? "active" : ""}
									onClick={() => handleMenuItemClick(menuItem.value)}
								>
									{menuItem.label}
								</MainMenuItem>
							))}
						</MainMenuContainer>
						<CoAppIconTextButton icon={<Delete fontSize="small" />} text="Delete role" onClick={toggleDeleteRoleDialog} />
						<CoAppIconTextButton icon={<Edit fontSize="small" />} text="Edit role name" onClick={() => setEditRoleNameToggled(true)} />
						<SettingsDivider />
					</SettingMenuContainer>
				</CoAppMainContainer>

				<CoAppMainContainer sx={{ p: 2 }}>
					{selectedMenuItem === pages.roleUsers && <RoleUsers role={role} />}
					{selectedMenuItem === pages.rolePermissions && <RolePermissions permissions={rolePermissions} role={role} />}
					{selectedMenuItem === pages.roleSSO && <RoleSSO role={role} />}
				</CoAppMainContainer>
				<CoAppEditNameModal
					dialogOpen={editRoleNameToggled}
					dialogTitle="Edit role name"
					changeHandler={handleRoleNameChange}
					placeholderText={role.name}
					confirmClickHandler={handleRoleNameChangeSubmit}
					cancelClickHandler={handleCloseEditNameModal}
					actionText="Save"
					editNameError={roleNameError}
					setEditNameError={setRoleNameError}
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={roleDeleteDialogOpen}
					dialogTitle={`Delete ${role.name} role?`}
					dialogMessage={messages.DELETION_CONFIRMATION_MSG(role.name, "role")}
					confirmClickHandler={deleteRole}
					cancelClickHandler={toggleDeleteRoleDialog}
					actionText="Delete"
				/>
			</>
		);
	} else {
		<></>;
	}
}