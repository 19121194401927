import { Grid, StepConnector, stepConnectorClasses, styled, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

export const WizardContainer = styled("div")(({ theme }) => ({
	padding: "50px 0 0 50px",
	height: "840px",
	width: "1565px",
	[theme.breakpoints.up(1800, 1900)]: {
		width: "1445px",
		height: "840px"
	},
	[theme.breakpoints.between(1600, 1850)]: {
		width: "1355px",
		height: "880px"
	},
	[theme.breakpoints.between(1500, 1600)]: {
		width: "1155px",
		height: "705px"
	},
	[theme.breakpoints.between(1000, 1400)]: {
		width: "1160px",
		height: "600px"
	},
	position: "relative"
}));

export const WizardHeaderTag = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#E3E6EC" : "#E3E6EC",
	...theme.typography.body2,
	padding: theme.spacing(0.5),
	textAlign: "left",
	fontSize: "0.70rem",
	fontWeight: "700",
	color: "#7482A0",
	float: "left",
	marginRight: "5px",
	borderRadius: "5px",
	alignItems: "center",
	display: "flex"
}));

export const RuleImage = styled("img")(({ theme }) => ({
	backgroundColor: "#FFFFFF",
	border: "solid 1px #DCDCDC",
	borderRadius: theme.spacing(0.75),
	boxShadow: "0 1px 3px grey",
	maxWidth: "600px",
	padding: "25px"
}));

export const WizardStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
	display: "flex",
	height: 40,
	padding: "10px",
	alignItems: "center",
	borderRadius: "50%",
	zIndex: 2,
	"& .WizardStepIcon-completedIcon": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: ownerState.active ? "#FFFFFF" : `${theme.palette.info.main}`,
		border: `12px solid ${theme.palette.info.main}`
	},
	"& .WizardStepIcon-circle": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "#FFFFFF",
		border: `10px solid ${theme.palette.info.main}`
	},
	"& .WizardStepIcon-circle-active-incomplete": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "#FFFFFF",
		border: `11px solid ${theme.palette.info.main}`,
		opacity: "0.5"
	},
	"& .WizardStepIcon-circle-inactive-incomplete": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "grey",
		border: "10px solid #eaeaf0"
	}
}));

export const WizardStepConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 28
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: `${theme.palette.info.main}`
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: `${theme.palette.info.main}`
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
		borderTopWidth: 4,
		marginLeft: "-7.5px",
		marginRight: "-10px"
	},
}));

export const WizardSavedProgressIndicator = styled("div")(({ theme }) => ({
	paddingLeft: "50px",
	fontSize: "10px",
	fontWeight: "500",
}));

export const WizardStepperContainer = styled("div")(({ theme }) => ({
	[theme.breakpoints.between(1500, 1600)]: {
		marginLeft: "150px"
	},
	marginLeft: "200px",
	justifyContent: "center",
	alignItems: "center",
	display: "flex",
	flexDirection: "row",
	position: "absolute",
	width: "75%",
	bottom: 10
}));

export const WizardLocationGrid = styled(Grid)(({ theme }) => ({
	overflowY: "scroll",
	height: "500px",
	[theme.breakpoints.between(1500, 1600)]: {
		height: "400px"
	}
}));

/**
 * STEP 1
 */
export const WizardStep1CardStructure = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(5),
	textAlign: "left",
	color: theme.palette.text.secondary,
	boxShadow: "0 1px 3px grey",
	borderRadius: "5px",
	width: "40%",
	margin: "10px auto"
}));

/**
 * STEPS 2 and 3
 */
export const WizardLocationsSearchField = styled(TextField)(({ theme }) => ({
	...theme.typography.body2,
	backgroundColor: "#FFFFFF",
	borderRadius: "20px",
	color: theme.palette.text.secondary,
	display: "inherit",
	fontSize: "2rem",
	fontWeight: "700",
	margin: "auto",
	padding: theme.spacing(1),
	textAlign: "center",
	width: "50%"
}));

/**
 * Step 4
 */
export const WizardOperatorItem = styled(Grid)((props) => ({
	alignContent: "center",
	border: "2px solid #DB7A74",
	borderRadius: "5px",
	color: `${props.selected ? "#2FBC70" : "black"}`,
	display: "flex",
	flexDirection: "column",
	fontSize: "0.75rem",
	justifyContent: "center",
	padding: "2px",
	textAlign: "center",
	"&:hover": {
		backgroundColor: "#F8F9FA",
		cursor: "move", /* fallback if grab cursor is unsupported */
		cursor: "grab",
		cursor: "-moz-grab",
		cursor: "-webkit-grab"
	},
	"&:active": {
		cursor: "grabbing",
		cursor: "-moz-grabbing",
		cursor: "-webkit-grabbing"
	}
}));

export const WizardOperatorGrid = styled(Grid)(({ theme }) => ({
	display: "grid",
	gridAutoRows: "auto",
	gridGap: "0.25rem",
	border: "1px solid #A9A9A9",
	padding: "5px",
	borderRadius: "5px",
	[theme.breakpoints.up("md")]: {
		gridTemplateColumns: "repeat(4, 1fr)",
		height: "150px",
		overflowY: "scroll"
	},
	[theme.breakpoints.up("xl")]: {
		gridTemplateColumns: "repeat(5, 1fr)",
		height: "200px",
	},

}));

export const FormulaHelpText = styled(Typography)(({ theme }) => ({
	fontStyle: "italic",
	fontSize: "0.65rem"
}));

/**
 * Step 5
 */
export const RuleSummary = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "left",
	color: theme.palette.text.secondary,
	boxShadow: "0 1px 3px grey",
	borderRadius: "5px",
	position: "relative",
	width: "80%",
	margin: "10px auto"
}));

export const RuleFormulaSummary = styled("div")(({ theme }) => ({
	backgroundColor: "#F8F9FA",
	...theme.typography.body2,
	color: "#111111",
	display: "flex",
	flexWrap: "wrap",
	justifyContent: "flex-start",
	margin: "5px",
	padding: "15px",
}));

export const RuleFormulaSummaryItem = styled(Typography)(() => ({
	padding: "5px",
	fontWeight: "400"
}));