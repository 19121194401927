import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Dialog } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import messages from "../../constants/messages";
import pages from "../../constants/pages";
import useAuthHeader from "../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../redux/alertSlice";
import {
	selectActive, selectDescription, selectEditedVersionOfRule, selectFormula,
	selectGroups, selectName, selectReactions, selectRuleLocation, selectTags, selectTriggers, setActive,
	setDescription, setEditedVersionOfRule, setFormula, setGroups,
	setItemsInFormula, setName, setReactions, setRuleLocation,
	setTags, setTriggers
} from "../../redux/ruleWizardSlice";

import WizardStepper from "./stepper/wizard-stepper";
import WizardStep1 from "./steps/wizard-step-1";
import WizardStep2 from "./steps/wizard-step-2";
import WizardStep3 from "./steps/wizard-step-3";
import WizardStep4 from "./steps/wizard-step-4";
import WizardStep5 from "./steps/wizard-step-5";
import { WizardContainer } from "./styled/rule-wizard.styled";
import RuleWizardHeader from "./rule-wizard-header";

function getSteps() {
	return [
		{ "num": 1, "desc": "Name, Description, Tags" },
		{ "num": 2, "desc": "Define Location and Reactions" },
		{ "num": 3, "desc": "Define Triggers" },
		{ "num": 4, "desc": "Define the Formula" },
		{ "num": 5, "desc": "Confirm and Assign Groups" }
	];
}

export default function StepperForm() {
	const { id } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	let authHeader = useAuthHeader();

	const [activeStep, setActiveStep] = useState(0);
	const steps = getSteps();

	/**
	 * Rule Metadata
	 */
	const active = useSelector(selectActive);
	const description = useSelector(selectDescription);
	const editedVersionOfRule = useSelector(selectEditedVersionOfRule);
	const formula = useSelector(selectFormula);
	const groups = useSelector(selectGroups);
	const name = useSelector(selectName);
	const reactions = useSelector(selectReactions);
	const tags = useSelector(selectTags);
	const triggers = useSelector(selectTriggers);
	const ruleLocation = useSelector(selectRuleLocation);

	const [stepOneErrors, setStepOneErrors] = useState({});
	const [wizardFormError, setWizardFormError] = useState("");
	const [formulaError, setFormulaError] = useState("");

	const [stepDataHasChanged, setStepDataHasChanged] = useState(false);

	/**
	 * State for handling save as you go functionality during creation flow.
	 */
	const [isSavingProgress, setIsSavingProgress] = useState({ isSaving: true, lastSaved: null });
	const [draftRuleId, setDraftRuleId] = useState(null);

	/**
	 * State for handling completion status when editing.
	 */
	const [stepsCompleted, setStepsCompleted] = useState({});

	useEffect(() => {
		if (id) {
			initEditingRule(false);
		}

		const handleEsc = (event) => {
			if (event.key === "Escape") {
				handleExitEditing();
			}
		};

		window.addEventListener("keydown", handleEsc);

		return () => {
			window.removeEventListener("keydown", handleEsc);
		};
	}, []);

	const checkRuleNameUniqueness = async (valName) => {
		let errors = {};
		let data;
		if (valName === "") {
			errors = { nameError: true, nameErrorMessage: messages.FIELD_IS_REQUIRED_ERROR_MSG };
			return errors;
		}
		await axios.get(apiRoutes.getRuleByName, {
			headers: authHeader,
			params: { name: valName }
		}).then((res) => {
			data = res.data;
			if (res.data) {
				errors = { nameError: true, nameErrorMessage: messages.RULE_NAME_UNIQUE_ERROR_MSG };
			}
		}).catch(err => {
			console.log(err);
			return null;
		});
		return [data, errors];
	};

	/**
	 * Wizard Step 1 Form Rule
	 * 1. Name is required
	 * 2. Description is required
	 * 3. Name must be unique
	 * TODO: *All* error detection should be here
	 */
	const doesStepOneHaveErrors = async () => {
		let errors = {};
		let hasErrors = false;
		setStepOneErrors(errors);

		if (name === "") {
			errors = { nameError: true, nameErrorMessage: messages.FIELD_IS_REQUIRED_ERROR_MSG };
			setStepOneErrors(errors);
			hasErrors = true;
		}

		if (description === "") {
			errors = { ...errors, descriptionError: true, descriptionErrorMessage: messages.FIELD_IS_REQUIRED_ERROR_MSG };
			setStepOneErrors(errors);
			hasErrors = true;
		}

		try {
			let ruleNameRes = await checkRuleNameUniqueness(name);
			if (ruleNameRes[0]) {
				if (id || draftRuleId) {
					if (name !== editedVersionOfRule.name) {
						if (draftRuleId) {
							if (ruleNameRes[0].id === parseInt(draftRuleId)) {
								errors = { ...errors };
							} else {
								errors = { ...errors, ...ruleNameRes[1] };
								hasErrors = true;
							}
						} else {
							errors = { ...errors, ...ruleNameRes[1] };
							hasErrors = true;
						}
					}
				} else {
					errors = { ...errors, ...ruleNameRes[1] };
					hasErrors = true;
				}
			}
		} catch (e) {
			console.log(e);
		}

		if (!errors["nameError"]) {
			setStepOneErrors({});
		}

		setStepOneErrors(errors);

		return hasErrors;
	};

	const getStepContent = (step) => {
		switch (step) {
			case 0:
				return (<>
					<WizardStep1 errors={stepOneErrors} stepOneErrorChangeHandler={setStepOneErrors} name={name} description={description} tags={tags}
						handleSaveChanges={handleSaveChanges} handleStepDataChange={setStepDataHasChanged} editedId={id} />
				</>);
			case 1:
				return (<>
					<WizardStep2 handleStepDataChange={setStepDataHasChanged} editedId={id} handleSaveChanges={handleSaveChanges} />
				</>);
			case 2:
				return (<>
					<WizardStep3 handleStepDataChange={setStepDataHasChanged} editedId={id} handleSaveChanges={handleSaveChanges} />
				</>);
			case 3:
				return (<>
					<WizardStep4 handleStepDataChange={setStepDataHasChanged} editedId={id} formulaError={formulaError} validateFormula={validateFormula} />
				</>);
			case 4:
				return (<>
					<WizardStep5 handleStepDataChange={setStepDataHasChanged} editedId={id} />
				</>);
			default:
				return "Unknown step";
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleExitEditing = async () => {
		if (name !== "" && description !== "") {
			handleSaveChanges();
		}

		navigate(pages.home);
		resetRuleRedux();
		setStepDataHasChanged(false);
	};

	/**
	* Step Progression
	*/
	const handleMoveUpOneStep = () => {
		setActiveStep((activeStep) => activeStep + 1);
	};

	const handleNext = async () => {
		switch (activeStep) {
			case 0:
				let hasErrors = await doesStepOneHaveErrors();
				if (!hasErrors) {
					setWizardFormError("");
					handleSaveChanges();
					handleMoveUpOneStep();
				}
				break;
			case 1:
				if (ruleLocation === "") {
					setWizardFormError(messages.RULE_LOCATION_REQUIRED_ERROR_MSG);
				} else if (reactions.length === 0 && ruleLocation !== "") {
					setWizardFormError(messages.RULE_REACTION_REQUIRED_ERROR_MSG);
				} else {
					handleSaveChanges();
					handleMoveUpOneStep();
					setWizardFormError("");
				}
				break;
			case 2:
				if (triggers.length === 0) {
					setWizardFormError(messages.RULE_TRIGGER_REQUIRED_ERROR_MSG);
				} else {
					handleSaveChanges();
					handleMoveUpOneStep();
					setWizardFormError("");
				}
				break;
			case 3:
				if (formula.formula.length !== 0 && formulaError === "") {
					handleSaveChanges();
					handleMoveUpOneStep();
					setWizardFormError("");
				} else {
					setWizardFormError(formulaError !== "" ? messages.RULE_INVALID_FORMULA_ERROR : messages.RULE_FORMULA_REQUIRED_ERROR_MSG);
				}
				break;
			default:
				console.log("default case");
				break;
		}
	};

	const handleRuleAlert = (level, message) => {
		dispatch(setLevel(level));
		dispatch(setMessage(message));
		dispatch(setOpen(true));
	};

	/**
	 * Save rule after every step.
	 * If this is a new rule, create new rule, otherwise update the existing one
	 * Draft = true if any of the steps are incomplete.
	*/
	const handleSaveChanges = async (updatedTriggers = triggers, updatedTags = tags) => {
		let ruleJSON = {
			active: active,
			description: description,
			formula: formula,
			groups: groups,
			isDraft: false,
			name: name,
			reactions: reactions,
			stepUpdated: activeStep,
			tags: updatedTags ? updatedTags : tags,
			triggers: updatedTriggers !== null ? updatedTriggers : triggers,
			ruleLocation: ruleLocation,
		};

		let ruleId = id ? id : draftRuleId;

		if (ruleId) {
			axios.put(apiRoutes.updateRule + "/" + ruleId, ruleJSON, { headers: authHeader }
			).then(() => {
				handleStepsCompletedUpdate(ruleJSON);
				setIsSavingProgress({ isSaving: true, lastSaved: new Date() });
				handleRuleAlert("success", "Rule successfully updated");
			}).catch((err) => {
				console.log(err);
				handleRuleAlert("error", "Rule could not be updated");
				return null;
			});
		} else {
			axios.post(apiRoutes.createRule, ruleJSON, {
				headers: authHeader
			}).then(res => {
				setDraftRuleId(res.data.id);
				handleStepsCompletedUpdate(ruleJSON, true);
				setIsSavingProgress({ isSaving: true, lastSaved: new Date() });
				initEditingRule(res.data.id);
				handleRuleAlert("success", "Rule successfully created");
			}).catch(err => {
				console.log(err);
				handleRuleAlert("error", "Rule could not be created");
				return null;
			});
		}
	};

	const handleStepButtonClick = (step) => {
		if (name !== "" && description !== "") {
			handleSaveChanges();
		}
		setActiveStep(step - 1);
	};

	/**
	 * Next and/or Finish Button is pressed.
	 * Save Progress at each step.
	 * 
	 * 1. Check for errors
	 * 2. Mark as Draft/Non-Draft
	 * 3. Save Progress
	 */
	const handleSubmit = () => {
		switch (activeStep) {
			case 0:
				doesStepOneHaveErrors();
				handleNext();
				break;
			case 1:
				handleNext();
				break;
			case 2:
				handleNext();
				break;
			case 3:
				handleNext();
				break;
			case 4:
				handleSaveChanges();
				resetRuleRedux();
				navigate(pages.home);
				break;
			default:
				break;
		}
	};

	/**
	 * Handles updating the completion status of the individual steps to aid control clicks on actual stepper.
	 */
	const handleStepsCompletedUpdate = (valData) => {
		if (!valData.rulereactions) {
			valData.rulereactions = valData.reactions;
		}

		if (!valData.ruletriggers) {
			valData.ruletriggers = valData.triggers;
		}

		const newStepsCompleted = {
			0: valData.name && valData.description ? true : false,
			1: valData.ruleLocation && valData.rulereactions.length > 0 ? true : false,
			2: valData.ruletriggers && valData.ruletriggers.length > 0 ? true : false,
			3: (valData.formula && valData.formula.formula && valData.formula.formula.length) > 0 ? true : false
		};

		newStepsCompleted[4] = newStepsCompleted[0] && newStepsCompleted[1] && newStepsCompleted[2] && newStepsCompleted[3] ? true : false;
		setStepsCompleted(newStepsCompleted);
	};

	/**
	 * Retrieve Rule Metadata for editing.
	 * Additional step to retrieve Triggers that are not in the formula.
	 */
	const initEditingRule = (newRuleId = null) => {
		// TODO: moderately confusing ternary statement
		// TODO: maintain rule id in a var for edit and new so we don"t have to compare id and newRuleId
		let ruleId = id !== undefined ? id : newRuleId;
		axios.get(apiRoutes.getRule + "/" + ruleId, {
			headers: authHeader
		}).then(res => {
			let valData = res.data;
			dispatch(setActive(valData.active));
			dispatch(setDescription(valData.description));
			dispatch(setEditedVersionOfRule(valData));
			dispatch(setFormula(valData.formula));
			dispatch(setGroups(valData.groups));
			dispatch(setItemsInFormula(valData.formula.formula));
			dispatch(setName(valData.name));
			dispatch(setReactions(valData.rulereactions));
			dispatch(setTags(valData.tags));
			dispatch(setTriggers(valData.ruletriggers));
			dispatch(setRuleLocation(valData.ruleLocation));
			handleStepsCompletedUpdate(valData);
		}).catch(err => {
			console.log(err);
		});
	};

	const resetRuleRedux = () => {
		dispatch(setActive(false));
		dispatch(setName(""));
		dispatch(setDescription(""));
		dispatch(setGroups([]));
		dispatch(setTags([]));
		dispatch(setRuleLocation(""));
		dispatch(setReactions([]));
		dispatch(setTriggers([]));
		dispatch(setFormula({}));
		dispatch(setItemsInFormula([]));
		dispatch(setEditedVersionOfRule({}));
	};

	/**
	 * Basic formula rule.
	 * Note: We are only validating the formulas parenthesis count ATM.
	 */
	const validateFormula = () => {
		const openings = formula.formula.filter(item => item.triggerName === "(");
		const closings = formula.formula.filter(item => item.triggerName === ")");
		if (openings.length !== closings.length) {
			setFormulaError(messages.RULE_PARENTHESES_ERROR(openings, closings));
		} else {
			setFormulaError("");
		}
	};

	return (
		<Fragment>
			<WizardContainer>
				<form noValidate autoComplete="off">
					<div>
						{[0, 4].includes(activeStep) ? null : <RuleWizardHeader />}
						{getStepContent(activeStep)}
					</div>
				</form>

				<WizardStepper activeStep={activeStep} setActiveStep={setActiveStep} stepsCompleted={stepsCompleted} handleStepButtonClick={handleStepButtonClick}
					handleSubmit={handleSubmit} handleBack={handleBack} handleExitEditing={handleExitEditing} isSavingProgress={isSavingProgress}
					editedVersionOfRule={editedVersionOfRule} wizardFormError={wizardFormError} setWizardFormError={setWizardFormError}
					steps={steps} />
			</WizardContainer>

			<Dialog onClose={() => setWizardFormError("")} open={wizardFormError !== ""}>
				<Alert severity="error">{wizardFormError}</Alert>
			</Dialog>
		</Fragment>
	);
}