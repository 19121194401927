import { Typography } from "@mui/material";

import prettifyDate from "../../../../helpers/prettifyDate";

export default function CoAppDataGridDateTimeCell(props) {
	const { value, isTrashed = false } = props;
	return (
		<div>
			<Typography variant="body2" color={isTrashed ? "text.disabled" : "text.primary"}>
				{value && prettifyDate(value)}
			</Typography>
		</div>
	);
}