import { Typography } from "@mui/material";

import { CoAppNameCell } from "../../styled/global.styled";

export default function CoAppDataGridNameCell(props) {
	const { item, isUser = false } = props;
	if (isUser) {
		return (
			<CoAppNameCell>
				{
					item.firstname && item.lastname ? (
						<Typography variant="body2" color="text.primary">{item.firstname} {item.lastname}</Typography>
					)
						:
						null
				}
				<Typography variant="body2" color="text.secondary">{item.email}</Typography>
			</CoAppNameCell>
		);
	} else {
		return (
			<div>
				<Typography variant="body2" color="text.primary">{item.name}</Typography>
			</div>
		);
	}
}
