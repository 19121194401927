import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import pages from "../../constants/pages";
import { selectPermissions, selectRoles } from "../../redux/userSlice";
import { CoAppMainContainer } from "../global/styled/global.styled";

import GroupManagement from "./groups/group-management";
import PluginManagement from "./plugins/plugin-management";
import RoleManagement from "./roles/role-management";
import UserManagement from "./users/user-management";
import WorkstationManagement from "./workstations/workstation-management";
import OrganizationManagement from "./organization";
import SettingsMenu from "./settings-menu";

export default function Settings() {
	const location = useLocation();
	const currentUserRole = useSelector(selectRoles);
	const userPermissions = useSelector(selectPermissions);
	const [selectedMenuItem, setSelectedMenuItem] = useState(null);

	const handleMenuItemClick = (menuItem) => {
		setSelectedMenuItem(menuItem);
	};

	const handleSettingsInit = () => {
		let from = location.search.split("from=")[1];
		from = from ? from : null;
		switch (from) {
			case "organization":
				if (userPermissions.includes("MANAGE_SSOSCIM") || userPermissions.includes("MANAGE_ORG_FORCELOGIN") || userPermissions.includes("MANAGE_ORG_KILLSWITCH"))
					setSelectedMenuItem(pages.organizationManagement);
				else
					handleDefaultMenuItemSelection();
				break;
			case "roles":
				if (userPermissions.includes("MANAGE_USERS_AND_GROUPS"))
					setSelectedMenuItem(pages.roleManagement);
				else
					handleDefaultMenuItemSelection();
				setSelectedMenuItem(pages.roleManagement);
				break;
			case "user":
				if (userPermissions.includes("MANAGE_USERS_AND_GROUPS"))
					setSelectedMenuItem(pages.userManagement);
				else
					handleDefaultMenuItemSelection();
				break;
			case "groups":
				if (userPermissions.includes("MANAGE_USERS_AND_GROUPS"))
					setSelectedMenuItem(pages.groupManagement);
				else
					handleDefaultMenuItemSelection();
				break;
			case "workstations":
				if (userPermissions.includes("MANAGE_WORKSTATIONS"))
					setSelectedMenuItem(pages.workstationManagement);
				else
					handleDefaultMenuItemSelection();
				break;
			case "plugins":
				if (userPermissions.includes("MANAGE_PLUGINS"))
					setSelectedMenuItem(pages.pluginManagement);
				else
					handleDefaultMenuItemSelection();
				break;
			default:
				handleDefaultMenuItemSelection();
				break;
		}
	};

	const handleDefaultMenuItemSelection = () => {
		if (userPermissions.includes("MANAGE_USERS_AND_GROUPS")) {
			setSelectedMenuItem(pages.userManagement);
		} else if (userPermissions.includes("MANAGE_SSOSCIM") || userPermissions.includes("MANAGE_ORG_FORCELOGIN") || userPermissions.includes("MANAGE_ORG_KILLSWITCH")) {
			setSelectedMenuItem(pages.organizationManagement);
		} else if (userPermissions.includes("MANAGE_WORKSTATIONS")) {
			setSelectedMenuItem(pages.workstationManagement);
		} else if (userPermissions.includes("MANAGE_PLUGINS")) {
			setSelectedMenuItem(pages.pluginManagement);
		}
	};

	const init = () => {
		handleSettingsInit();
	};

	useEffect(() => {
		init();
	}, []);
	return (
		<CoAppMainContainer sx={{ p: 2 }}>
			<SettingsMenu onMenuItemClick={handleMenuItemClick} activeItem={selectedMenuItem} currentUserRole={currentUserRole} />
			{selectedMenuItem === pages.organizationManagement && <OrganizationManagement />}
			{selectedMenuItem === pages.userManagement && <UserManagement />}
			{selectedMenuItem === pages.roleManagement && <RoleManagement />}
			{selectedMenuItem === pages.groupManagement && <GroupManagement />}
			{selectedMenuItem === pages.workstationManagement && <WorkstationManagement />}
			{selectedMenuItem === pages.pluginManagement && <PluginManagement />}
		</CoAppMainContainer>
	);
}