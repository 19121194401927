import { forwardRef, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Typography } from "@mui/material";
import { TreeView } from "@mui/x-tree-view";

import { selectNavState, setNavState } from "../../redux/navigationSlice";

import { CoAppNavigationTreeItemRoot } from "./styled/coapp-nav-tree.styled";
import CustomContent from "./coapp-nav-tree-item-custom-content";

/**
 * Custom memo function to handle rerendering of the tree. This saves us from rerendering the tree when we don't need to, especially on user clicks
 * Returning false means we will rerender the tree
 * Returning true means we will not rerender the tree
 * see https://react.dev/reference/react/memo for more info on the memo HOC
 */
const shouldRerender = (prevProps, nextProps) => {
	let shouldNotRerender = true;

	prevProps.treeData.forEach((node, index) => {
		if (node.children.length !== nextProps.treeData[index].children.length) {
			shouldNotRerender = false;
		}
		if (node.children.length > 0) {
			node.children.forEach(child => {
				if (child.name === "Users") { //make sure we rerender when user views a user from groups or roles
					shouldNotRerender = false;
				}
			});
		}
	});
	return shouldNotRerender;
};

const CoAppSettingsNavTree = memo(function CoAppSettingsNavTree(props) {
	const dispatch = useDispatch();
	const navState = useSelector(selectNavState);
	const [expanded, setExpanded] = useState([]);
	const [selected, setSelected] = useState({ id: 0 });

	const StyledTreeItem = (props) => {
		return <CoAppNavigationTreeItemRoot ContentComponent={forwardRef(CustomContent)} {...props} />;
	};

	const renderTree = (item) => {
		if (item.isTitle) {
			return (
				<div key={Math.random()} style={{ marginLeft: "40px", marginTop: "30px", marginBottom: "13px" }}>
					<Typography variant="body3" color="text.secondary" >{item.name}</Typography>
				</div>
			);
		} else if (item.isClickable || item.isGroupingLabel) {
			return (
				<StyledTreeItem
					data-testid={`tag_${item.name}`}
					key={item.id}
					nodeId={item.id.toString()}
					label={item.name}
					disabled={item.isGroupingLabel} //disables first item when a specific sub item is selected, allows us to style the first item differently
					ContentProps={{
						onClick: () => handleNodeClick(item)
					}}
				>
					{Array.isArray(item.children) ? item.children.map((node) => renderTree(node)) : null}
				</StyledTreeItem>
			);
		} else if (item.name === "Divider") {
			return (<Divider key={Math.random()} sx={{ color: "#0000001F", marginTop: "20px" }} />);
		}
	};

	/**
	 * Handles updating the expanded state of the nave tree when a node is clicked.
	 * Note: Special case to identitfy when the trash/library nodes are clicked, as they have different behavior than the other nav nodes
	 */
	const handleExpansionOnNodeClick = (item) => {
		if (!["Library", "Trash"].includes(item.name)) {
			let tempExpanded = [...expanded];
			tempExpanded = tempExpanded.filter((nodeId) => !["3", "4"].includes(nodeId)); //handle closing the trash node when a user clicks on a different node
			setExpanded([...tempExpanded, item.id.toString()]);
		} else {
			let tempExpanded = [...expanded];
			tempExpanded = tempExpanded.filter((nodeId) => ["3", "4"].includes(nodeId));
			setExpanded([...tempExpanded]);
		}
	};

	/**
	 * Fired when a node in our tree is clicked.
	 * Handles the selection of the node that recieved the click event, redirection to the respective page, and updating the navigation state.
	 */
	const handleNodeClick = (item) => {
		if (item.isUserNavigationItem) return; //dont do anything when the user nav item is clicked
		handleExpansionOnNodeClick(item);
		let copyNavState = [...navState];
		let temp = copyNavState.flatMap((node) => node.children).filter(child => child.name !== "Trash");
		let foundItem = temp.length > 0 ? temp[0] : null;
		props.handleRedirect(item.page, { state: foundItem });
		copyNavState = handleNavigationUpdateOnClick(item);
		setSelected(item);
		dispatch(setNavState(copyNavState));
	};

	/**
	 * Handles updating the navigation state when a node is clicked. Essentially handles the closing of the tree nodes when a user clicks on a node.
	 * Note: If keepOpen flag is true, it means a user has clicked into a specific group, role, plugin, or user to manage a sub item of the resource
	 */
	const handleNavigationUpdateOnClick = (item) => {
		let copyNavState = [...navState];
		copyNavState = copyNavState.map((node) => {
			let tempNode = { ...node };
			if (node.children.length > 0 && !item.keepOpen) {
				if (node.name !== "Library") { // we dont need to remove the library nodes children
					tempNode.children = [];
				}
				tempNode.isStillSelected = false;
			} else {
				let hasSubChildrenIndex = tempNode.children.findIndex(child => child.name === "Users");
				if (hasSubChildrenIndex !== -1) {
					let tempChildren = tempNode.children.map((child, index) => {
						let tempChild = { ...child };
						if (index === hasSubChildrenIndex) {
							tempChild.children = [];
						}
						return tempChild;
					});
					tempNode.selectedResource = item.name;
					tempNode.children = tempChildren;
				}
				tempNode.isParentChangeEvent = false;
			}
			return tempNode;
		});
		return copyNavState;
	};

	const handleExpansion = (event, nodeId) => {
		setExpanded(prev => [...prev, nodeId]);
	};

	/**
	 * Handles the expansion and selection of the tree nodes when a parent item is updated in the navigation state
	 */
	const processParentChangeEvent = (node) => {
		setExpanded((prev) => [...prev, node.id.toString()]);
		if (node.isStillSelected && node.selectedResource) {
			const selectedResource = node.children.filter(child => child.name === node.selectedResource)[0];
			props.handleRedirect(selectedResource.page, { state: node.children[0] });
			setSelected(selectedResource);
		} else {
			let nodeToSelectIndex = node.children.length > 1 ? 1 : 0; //handle identifying the first item of a resources sub items
			setSelected(node.children[nodeToSelectIndex]);
			props.handleRedirect(node.children[nodeToSelectIndex].page, { state: node.children[0] });
		}
	};

	/**
	 * Handles the expansion and selection of the tree nodes when the deepest child (user) of the tree is updated in the navigation state
	 */
	const processDeepestChildChangeEvent = (node) => {
		let usersNode = node.children.filter(child => child.name === "Users")[0]; //handles selecting users node when a user is selected from roles or groups
		if (usersNode && usersNode.children.length > 0) {
			setExpanded((prev) => [...prev, usersNode.id.toString()]);
			setSelected(usersNode.children[0]);
		}
	};

	/**
	 * Handles the expansion and selection of the tree nodes when a resource name is updated in the navigation state
	 * Note: This is used to update the header state of the respective datagrid and the cells visible in the datagrid
	 */
	const processResourceNameChangeEvent = (node) => {
		let nodeToSelect = node.children.filter(child => child.name === node.selectedResource)[0];
		setSelected(nodeToSelect);
		props.handleRedirect(nodeToSelect.page, { state: node.children[0] });
	};

	const handleExpansionAndSelectionNavEvents = () => {
		props.treeData.forEach((node) => {
			if (node.children.length >= 1) {
				if (node.isParentChangeEvent) {
					processParentChangeEvent(node);
				} else if (node.isDeepestChildChangeEvent) {
					processDeepestChildChangeEvent(node);
				} else if (node.isNameChangeEvent) {
					processResourceNameChangeEvent(node);
				}
			} else if (node.isStillSelected) {
				setSelected(node);
			}
		});
	};

	useEffect(() => {
		handleExpansionAndSelectionNavEvents();
	}, [props.treeData]);

	return (
		<TreeView
			expanded={expanded}
			selected={selected.id.toString()}
			onNodeSelect={handleExpansion}
		>
			{props.treeData.map((node) => renderTree(node))}
		</TreeView >
	);
}, shouldRerender);

export default CoAppSettingsNavTree;