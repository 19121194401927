import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	active: false,
	description: "",
	editedVersionOfRule: {},
	formula: { formula: [], name: "" },
	groups: [],
	itemsInFormula: [],
	name: "",
	reactions: [],
	tags: [],
	triggers: [],
	ruleLocation: "",
};

export const ruleWizardSlice = createSlice({
	name: "ruleWizard",
	initialState,
	reducers: {
		setActive: (state, action) => {
			state.active = action.payload;
		},
		setName: (state, action) => {
			state.name = action.payload;
		},
		setDescription: (state, action) => {
			state.description = action.payload;
		},
		setGroups: (state, action) => {
			state.groups = action.payload;
		},
		setTags: (state, action) => {
			state.tags = action.payload;
		},
		setRuleLocation: (state, action) => {
			state.ruleLocation = action.payload;
		},
		setReactions: (state, action) => {
			state.reactions = action.payload;
		},
		setTriggers: (state, action) => {
			state.triggers = action.payload;
		},
		setFormula: (state, action) => {
			state.formula = action.payload;
		},
		setItemsInFormula: (state, action) => {
			state.itemsInFormula = action.payload;
		},
		setEditedVersionOfRule: (state, action) => {
			state.editedVersionOfRule = action.payload;
		}
	}
});

export const selectActive = (state) => state.ruleWizard.active;
export const selectDescription = (state) => state.ruleWizard.description;
export const selectDraftVersionOfRule = (state) => state.ruleWizard;
export const selectEditedVersionOfRule = (state) => state.ruleWizard.editedVersionOfRule;
export const selectFormula = (state) => state.ruleWizard.formula;
export const selectGroups = (state) => state.ruleWizard.groups;
export const selectItemsInFormula = (state) => state.ruleWizard.itemsInFormula;
export const selectName = (state) => state.ruleWizard.name;
export const selectReactions = (state) => state.ruleWizard.reactions;
export const selectTags = (state) => state.ruleWizard.tags;
export const selectTriggers = (state) => state.ruleWizard.triggers;
export const selectRuleLocation = (state) => state.ruleWizard.ruleLocation;

export const {
	setActive,
	setName,
	setDescription,
	setGroups,
	setTags,
	setRuleLocation,
	setReactions,
	setTriggers,
	setRuleFilters,
	setFormula,
	setItemsInFormula,
	setEditedVersionOfRule
} = ruleWizardSlice.actions;

export default ruleWizardSlice.reducer;