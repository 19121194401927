import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	navState: [
		{ id: 1, name: "RULES", children: [], page: "", isClickable: false, isTitle: true },
		{ id: 2, name: "Build Rule", children: [], page: "/rules/new", isClickable: true },
		{
			id: 3,
			name: "Library",
			children: [
				{
					id: 4,
					name: "Trash",
					children: [],
					page: "/rules/trash",
					isClickable: true,
					keepOpen: true
				}
			],
			page: "/rules/library",
			isClickable: true,
			keepOpen: true
		},
		{ id: 5, name: "Divider", children: [], page: "", isClickable: false },
		{ id: 6, name: "APP SETTINGS", children: [], page: "", isClickable: false, isTitle: true },
		{ id: 7, name: "Organization", children: [], page: "/settings/organization", isClickable: true },
		{ id: 8, name: "Users", children: [], page: "/settings/users", isClickable: true },
		{ id: 9, name: "Groups", children: [], page: "/settings/groups", isClickable: true },
		{ id: 10, name: "Roles", children: [], page: "/settings/roles", isClickable: true },
		{ id: 11, name: "Workstations", children: [], page: "/settings/workstations", isClickable: true },
		{ id: 12, name: "Plugins", children: [], page: "/settings/plugins", isClickable: true }
	],
	groupChildren: [
		{ id: 13, name: "Rules", page: "", children: [] },
		{ id: 14, name: "Users", page: "", children: [] },
		{ id: 15, name: "Plugins", page: "", children: [] },
		{ id: 16, name: "Add/Remove IdP Groups", page: "", children: [] }
	],
	roleChildren: [
		{ id: 17, name: "Users", page: "", children: [] },
		{ id: 18, name: "Permissions", page: "", children: [] },
		{ id: 19, name: "Add/Remove IdP Groups", page: "", children: [] }
	],
	pluginChildren: [
		{ id: 20, name: "Groups", page: "", children: [] }
	]
};

export const navigationSlice = createSlice({
	name: "navigation",
	initialState,
	reducers: {
		setNavState: (state, action) => { state.navState = action.payload; }
	}
});

export const selectNavState = (state) => state.navigation.navState;
export const selectGroupChildren = (state) => state.navigation.groupChildren;
export const selectRoleChildren = (state) => state.navigation.roleChildren;
export const selectPluginChildren = (state) => state.navigation.pluginChildren;

export const {
	setNavState
} = navigationSlice.actions;

export default navigationSlice.reducer;