import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add, Delete, Link, LinkOff, Search } from "@mui/icons-material";
import { Checkbox, checkboxClasses, FormControl, InputAdornment, ListSubheader, MenuItem } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../../constants/api-routes";
import messageLevels from "../../../../../constants/message-levels";
import messages from "../../../../../constants/messages";
import useAuthHeader from "../../../../../helpers/useAuthHeader";
import { setLevel, setMessage, setOpen } from "../../../../../redux/alertSlice";
import { selectMappedRoles, setCoappRoles, setMappedRoles, setRawExternalGroups } from "../../../../../redux/scimWizardSlice";
import { selectUser } from "../../../../../redux/userSlice";
import CoAppIconTextButton from "../../../../global/components/inputs/coapp-icon-text-button";
import CoAppTextField from "../../../../global/components/inputs/coapp-textfield";
import CoAppDestructiveConfirmationModal from "../../../../global/components/modals/coapp-destructive-confirmation-modal";
import { CoAppActionButton, CoAppTextButton } from "../../../../global/styled/global.styled";
import {
	ScimWizardGroupConnector,
	ScimWizardInputLabel,
	ScimWizardMultiSelectWithSearch,
	ScimWizardStack, ScimWizardStackItem, ScimWizardStackItemContent,
	ScimWizardStackSearchField,
	ScimWizardStackTextItemContent,
	ScimWizardStepContainer
} from "../styled/scim-wizard.styled";

const containsText = (text, searchText) =>
	text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function Step3(props) {

	const authHeader = useAuthHeader();
	const currentUser = useSelector(selectUser);
	const mappedRoles = useSelector(selectMappedRoles);
	const dispatch = useDispatch();

	const [rolesLoaded, setRolesLoaded] = useState(false);
	const [idpGroupsLoaded, setIdpGroupsLoaded] = useState(false);
	const [roles, setRoles] = useState([]);
	const [idpGroups, setIdpGroups] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [isAddingRole, setIsAddingRole] = useState(false);
	const [newRoleName, setNewRoleName] = useState("");
	const [roleListSeed, setRoleListSeed] = useState(null);
	const [confirmationDialogContent, setConfirmationDialogContent] = useState({ dialogOpen: false });

	const initRoles = () => {
		axios.get(apiRoutes.getRoles, {
			headers: authHeader
		})
			.then((res) => {
				setRoles(res.data);
				dispatch(setCoappRoles(res.data));
				let tempObj = {};
				for (let r in res.data) {
					tempObj[res.data[r].name] = [];
				}
				dispatch(setMappedRoles(Object.keys(mappedRoles).length > 0 ? mappedRoles : tempObj));
				props.setTitleCount(res.data.length);
				setRolesLoaded(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const initIdpGroups = () => {
		axios.get(apiRoutes.getExternalGroups(currentUser.organizationId), {
			headers: authHeader
		})
			.then((res) => {
				setIdpGroups(res.data);
				setIdpGroupsLoaded(true);
				dispatch(setRawExternalGroups(res.data));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDropdownSelectionChange = (e, coappRoleName) => {
		let temp = { ...mappedRoles };
		temp[`${coappRoleName}`] = e.target.value;
		dispatch(setMappedRoles(temp));
	};

	const handleCreateRoleOpen = () => {
		setIsAddingRole(true);
	};

	const handleRoleCreation = () => {
		let roleJSON = {
			name: newRoleName
		};
		axios.post(apiRoutes.createRole, roleJSON, {
			headers: authHeader
		})
			.then(() => {
				let temp = { ...mappedRoles };
				temp[`${newRoleName}`] = [];
				dispatch(setMappedRoles(temp));
				handleRoleActionAlert("success");
			})
			.catch(err => {
				console.error(err);
				handleRoleActionAlert("error");
			});
	};

	const deleteRole = (roleName, roleId) => {
		axios.delete(apiRoutes.deleteRole + "/" + roleId, {
			headers: authHeader
		}).then(() => {
			let tempMappedRoles = { ...mappedRoles };
			delete tempMappedRoles[`${roleName}`];
			dispatch(setMappedRoles(tempMappedRoles));
			handleRoleActionAlert("success", "deletion");
			setConfirmationDialogContent({ dialogOpen: false });
		}).catch((err) => {
			handleRoleActionAlert("error", "deletion");
			console.log(err);
		});
	};

	const handleRoleDeletion = (roleName, roleId) => {
		let dialogContent = {
			dialogOpen: true,
			confirmationMessage: messages.DELETION_CONFIRMATION_MSG(roleName, "role"),
			confirmationTitle: `Delete "${roleName}" role?`,
			confirmClickHandler: () => {
				let tempRoles = [...roles];
				tempRoles = tempRoles.filter((role) => role.name !== roleName);
				setRoles(tempRoles);
				deleteRole(roleName, roleId);
			},
			cancelClickHandler: () => {
				setConfirmationDialogContent({ dialogOpen: false });
			}
		};
		setConfirmationDialogContent(dialogContent);
	};

	const handleRoleActionAlert = (level, eventType = "creation") => {
		setIsAddingRole(false);
		setRoleListSeed(Math.random());
		dispatch(setLevel(level));
		if (eventType === "creation") {
			dispatch(setMessage(level === "success" ? messages.ROLE_CREATED_SUCCESS_MSG : messages.ROLE_CREATION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(level === "success" ? messageLevels.SUCCESS : messageLevels.ERROR));
		} else {
			dispatch(setMessage(level === "success" ? messages.ROLE_DELETION_SUCCESS_MSG : messages.ROLE_DELETION_ERROR_MSG));
			dispatch(setOpen(true));
			dispatch(setLevel(level === "success" ? messageLevels.SUCCESS : messageLevels.ERROR));
		}
		dispatch(setOpen(true));
	};

	useEffect(() => {
		initRoles();
		initIdpGroups();
	}, [roleListSeed]);

	if (!rolesLoaded && !idpGroupsLoaded) {
		return (
			<div>
				Loading...
			</div>
		);
	} else {
		return (
			<ScimWizardStepContainer>
				<ScimWizardStack>
					<ScimWizardStackItem key={"stack-header"} itemtype={"header"}>
						<ScimWizardStackItemContent>CoApp Roles</ScimWizardStackItemContent>
						<ScimWizardStackItemContent itemtype={"header"}>IdP Groups</ScimWizardStackItemContent>
					</ScimWizardStackItem>
					{
						roles.map((role) => {
							return (
								<ScimWizardStackItem key={role.id}>
									<ScimWizardStackTextItemContent title={role.name}>{role.name}</ScimWizardStackTextItemContent>
									{
										mappedRoles[`${role.name}`].length === 0
											?
											<ScimWizardGroupConnector isconnected={false} title="Not Mapped"><LinkOff fontSize="medium" /></ScimWizardGroupConnector>
											:
											<ScimWizardGroupConnector isconnected={true} title="Mapped"><Link fontSize="medium" /></ScimWizardGroupConnector>

									}
									<ScimWizardStackItemContent>
										<FormControl size="small">
											{
												mappedRoles[`${role.name}`].length === 0
													?
													<ScimWizardInputLabel id="roles">Select Idp Groups</ScimWizardInputLabel>
													:
													null
											}
											<ScimWizardMultiSelectWithSearch
												value={mappedRoles[`${role.name}`]}
												label="Select Roles"
												labelId="roles"
												MenuProps={{ autoFocus: false }}
												multiple
												renderValue={(selected) => selected.join(", ")}
												onChange={(e) => handleDropdownSelectionChange(e, role.name)}
												onClose={() => setSearchText("")}
											>
												<ListSubheader>
													<ScimWizardStackSearchField
														size="small"
														autoFocus
														placeholder="Find Role"
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<Search />
																</InputAdornment>
															)
														}}
														onChange={(e) => setSearchText(e.target.value)}
														onKeyDown={(e) => {
															e.stopPropagation();
														}}
													/>
												</ListSubheader>
												{
													idpGroups.filter((option) => containsText(option.externalGroupName, searchText)).map((option, index) => (
														<MenuItem key={index} value={option.externalGroupName}>
															<Checkbox checked={mappedRoles[`${role.name}`].indexOf(option.externalGroupName) > -1} sx={{
																[`&.${checkboxClasses.checked}`]: {
																	color: "#2FBD70",
																}
															}} />
															{option.externalGroupName}
														</MenuItem>
													))
												}
											</ScimWizardMultiSelectWithSearch>
										</FormControl>
									</ScimWizardStackItemContent>
									<CoAppIconTextButton
										onClick={() => handleRoleDeletion(role.name, role.id)}
										icon={<Delete fontSize="small" />}
										text="Delete"
									/>
								</ScimWizardStackItem>
							);
						})
					}
					<ScimWizardStackItem key={"stack-footer"} itemtype={"footer"}>
						{
							isAddingRole ?
								<ScimWizardStackItemContent sx={{ marginBottom: "2px" }}>
									<CoAppTextField
										size="small"
										sx={{ height: "18px", marginRight: "15px", marginTop: "0px" }}
										label="Role Name"
										onChange={(e) => setNewRoleName(e.target.value)}
									/>
									<CoAppActionButton sx={{ height: "30px", marginTop: "5px" }} size="small" onClick={handleRoleCreation}>Create role</CoAppActionButton>
									<CoAppTextButton sx={{ height: "30px", marginTop: "5px" }} size="small" onClick={() => setIsAddingRole(false)}>Cancel</CoAppTextButton>
								</ScimWizardStackItemContent>
								:
								<CoAppIconTextButton icon={<Add fontSize="small" />} text="Add new role" onClick={handleCreateRoleOpen} />
						}
					</ScimWizardStackItem>
				</ScimWizardStack>
				<CoAppDestructiveConfirmationModal
					dialogOpen={confirmationDialogContent.dialogOpen}
					dialogTitle={confirmationDialogContent.confirmationTitle}
					dialogMessage={confirmationDialogContent.confirmationMessage}
					confirmClickHandler={confirmationDialogContent.confirmClickHandler}
					cancelClickHandler={confirmationDialogContent.cancelClickHandler}
					actionText="Delete"
				/>
			</ScimWizardStepContainer>
		);
	}
}